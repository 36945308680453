import { PlaceholdersCollection } from "../configuration/collections";
import { TEAMS_ITEM_REFERENCE_TYPE } from "../../../commons/constants";
import type { IUdsClient } from "../../../clients/UdsClient";
import type { Work } from "../../../types/Work";
import type {
  FilteredActivePlaceholders,
  Placeholder,
} from "../../../types/GroupsAndTeams";

export class TeamsPlaceholdersProvider {
  constructor(private udsClient: IUdsClient) {}

  private filterActivePlaceholders = (
    panels: Record<string, any>[],
    teamsPlaceholders: Placeholder[],
  ): FilteredActivePlaceholders => {
    /**
     * Function to filter out inactive placeholders on deleted sides, i.e. backside.
     */
    const filteredPlaceholders: FilteredActivePlaceholders = {
      activePlaceholders: [],
      containsNonTeamsEntities: false,
    };
    const activePlaceholdersIDs = new Set();

    panels.forEach((panel) => {
      if (panel.itemReferences) {
        panel.itemReferences.forEach((itemRef: Record<string, any>) => {
          if (itemRef.type === TEAMS_ITEM_REFERENCE_TYPE)
            activePlaceholdersIDs.add(itemRef.id);
          // Tables & QR Codes contain non-TEAMS_ITEM_REFERENCE_TYPE values -- if this type isn't teams,
          // it means there's non-teams custom objects in the document
          else filteredPlaceholders.containsNonTeamsEntities = true;
        });
      }

      // Images, Shapes, and TextAreas will only be present in the panel if there are instances of
      // those elements in the document. If present, then there are non-teams elements
      if (panel.images || panel.shapes || panel.textAreas)
        filteredPlaceholders.containsNonTeamsEntities = true;
    });

    filteredPlaceholders.activePlaceholders = teamsPlaceholders.filter(
      (placeholder) => activePlaceholdersIDs.has(placeholder.key),
    );

    return filteredPlaceholders;
  };

  async getPlaceholders(work: Work): Promise<PlaceholdersCollection> {
    // For backwards compatibility with YSD custom-sportswear studio designs that store placeholder
    // data on the work entity (sportswear products always allowe non-personalized items, regardless
    // of what is on the document)
    if (work.design?.metadata?.groupsAndTeamsData) {
      const groupsAndTeamsData = JSON.parse(
        work.design.metadata.groupsAndTeamsData,
      );
      return new PlaceholdersCollection(groupsAndTeamsData.placeholders, true);
    }

    const udsDocument = await this.udsClient.getDocumentByUrl(
      work.design.designUrl,
    );

    if (!udsDocument?.metadata?.teamsPlaceholders)
      throw new Error(`Placeholder data missing for workId ${work.workId}.`);

    const { activePlaceholders, containsNonTeamsEntities } =
      this.filterActivePlaceholders(
        udsDocument.document.panels,
        udsDocument.metadata.teamsPlaceholders,
      );
    return new PlaceholdersCollection(
      activePlaceholders,
      containsNonTeamsEntities,
    );
  }
}
