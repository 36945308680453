import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalDialogHeader,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  Box,
  Hidden,
  GridContainer,
  Row,
  Column,
  useScreenClass,
  ModalDialogFooter,
  Card,
} from "@vp/swan";
import ReviewDesignAcknowledgement from "./ReviewDesignAcknowledgement";
import { ReviewButtons } from "./buttons/ReviewButtons";
import { GroupsAndTeamsContext } from "../context/GroupsAndTeamsContext";
import PreviewPreloader from "../shared-preview/PreviewPreloader";
import ApprovalCheckbox from "./ApprovalCheckbox";
import { useBffData } from "../../../hooks/queries";
import { useTracking } from "../../root/contexts/TrackingContext";
import withTracking from "../../root/withTracking";
import AlertCollection from "../../root/AlertCollection";
import PreviewBlock from "../shared-preview/PreviewBlock";
import { TrackingLabel } from "../../../types/TrackingConfiguration";

const ReviewPageWrapper: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const screenClass = useScreenClass();
  const { isUpdatingTeam, groupsAndTeamsMembers } = useContext(
    GroupsAndTeamsContext,
  );
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { isTrackingReady, trackProductViewed } = useTracking();

  const [isProductViewedTracked, setIsProductViewedTracked] = useState(false);

  const isXsScreen = screenClass === "xs";

  useEffect(() => {
    if (isProductViewedTracked) return;

    if (isTrackingReady && bffDataIsFetched && !isUpdatingTeam) {
      const { mpvId, productName, productKey, productVersion } = bffData!;
      trackProductViewed(
        TrackingLabel.TEAM_DETAILS_REVIEW_PAGE,
        mpvId,
        productName,
        productKey,
        productVersion,
      );
      setIsProductViewedTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingTeam, bffDataIsFetched]);

  return (
    <ModalDialogContent aria-label={t("review-wizard.label")} fullBleed>
      <ModalDialogCloseButton
        className="review-close-button"
        accessibleText={t("close")}
      />
      <Hidden sm md lg xl>
        <ModalDialogHeader paddingX={{ xs: 5, sm: 6 }} mt={7} mb={3} ml={0}>
          {t("review-wizard.label")}
        </ModalDialogHeader>
      </Hidden>
      <ModalDialogBody mx={{ xs: 0, md: 3 }} mb={0} className="review-body">
        <GridContainer>
          <Row className="review-row">
            <Column
              span={9}
              spanXs={12}
              spanSm={8}
              paddingX={{ xs: 5, sm: 4 }}
              className="review-preview-section"
            >
              <AlertCollection />
              {isUpdatingTeam && <PreviewPreloader />}
              {!isUpdatingTeam && (
                <Box pt={{ sm: 7 }}>
                  {groupsAndTeamsMembers.map((member, i) => {
                    return (
                      <PreviewBlock
                        key={i}
                        groupsAndTeamsMember={member}
                        groupsAndTeamsEntryNumber={i}
                        totalTeamMembers={groupsAndTeamsMembers.length}
                        showEditDesignLink={false}
                        isReviewPage={true}
                      />
                    );
                  })}
                </Box>
              )}
            </Column>
            {!isXsScreen && (
              <Column
                span={3}
                spanSm={4}
                className="review-and-acknowledge"
                padding={4}
              >
                <ReviewDesignAcknowledgement />
                <Card px={4} pt={0}>
                  <ReviewButtons />
                </Card>
              </Column>
            )}
          </Row>
        </GridContainer>
      </ModalDialogBody>
      {isXsScreen && (
        <ModalDialogFooter className="review-footer" paddingX={6} pinned>
          <ApprovalCheckbox />
          <ReviewButtons />
        </ModalDialogFooter>
      )}
    </ModalDialogContent>
  );
};

const ReviewPage = withTracking(
  ReviewPageWrapper,
  {
    pageStage: "Configure",
    pageSection: "Configure - Teams Review",
    pageName: "Configure - Teams Review",
    pageDept: "PPAG",
  },
  true,
);

export default ReviewPage;
