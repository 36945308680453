import { Resource } from "i18next";

import enUS from "./en-us.json";
import enGB from "./en-gb.json";
import esES from "./es-es.json";
import nlNL from "./nl-nl.json";
import itIT from "./it-it.json";
import svSE from "./sv-se.json";
import daDK from "./da-dk.json";
import frFR from "./fr-fr.json";
import ptPT from "./pt-pt.json";
import deDE from "./de-de.json";
import esUS from "./es-us.json";
import enIN from "./en-in.json";

export const resources: Resource = {
  "en-us": enUS,
  "en-gb": enGB,
  "es-es": esES,
  "nl-nl": nlNL,
  "it-it": itIT,
  "sv-se": svSE,
  "da-dk": daDK,
  "fr-fr": frFR,
  "pt-pt": ptPT,
  "de-de": deDE,
  "es-us": esUS,
  "en-in": enIN,
};
