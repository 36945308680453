import React, { useContext, useEffect, useState } from "react";
import { Button, Link } from "@vp/swan";
import { useAppState } from "../../../root/contexts/AppContext";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { useIdentityContext } from "../../../root/contexts/IdentityContext";
import { useBffData } from "../../../../hooks/queries";
import { onEditDesignClick } from "../../../../utils/EditDesignHelpers";

type BackToEditDesignLinkProps = {
  skin: "primary" | "secondary" | "unstyled";
  renderAs: "link" | "button";
  linkText?: string;
};

const BackToEditDesignLink: React.FC<BackToEditDesignLinkProps> = (props) => {
  const { isCareAgent, editSource } = useAppState();
  const { data: bffData } = useBffData();
  const { work } = bffData!;

  const { linkText, skin, renderAs } = props;
  const { isUpdatingTeam } = useContext(GroupsAndTeamsContext);
  const { auth } = useIdentityContext();

  const [studioUrl, setStudioUrl] = useState<string>("");

  useEffect(() => {
    let hasResolvedStudioUrl = false;
    const getStudioUrl = async () => {
      const url = await onEditDesignClick(work, auth, isCareAgent, editSource);

      if (!hasResolvedStudioUrl) {
        setStudioUrl(url);
      }
    };

    if (auth && work) {
      getStudioUrl();
    }

    return () => {
      hasResolvedStudioUrl = true;
    };
  }, [auth, editSource, isCareAgent, work]);

  if (renderAs === "link")
    return (
      <Link href={studioUrl} skin="cta-left">
        {linkText}
      </Link>
    );

  return (
    <Button
      className="review-edit-design-button"
      skin={skin}
      disabled={isUpdatingTeam}
      render={(props) => (
        <Link to={studioUrl} className={props.className}>
          {props.children}
        </Link>
      )}
    >
      {linkText}
    </Button>
  );
};

export default BackToEditDesignLink;
