import React, { ReactNode, useContext, useEffect, useState } from "react";

import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";

interface GroupFormContextInterface {
  formValidity: Record<string, boolean>;
  setValidity: any;
  clearValidity: any;
  removeEntry: any;
  memberFormsToValidate: string[];
  setMemberFormsToValidate: (memberIds: string[]) => void;
  areFormFieldsInitialized: boolean;
  setAreFormFieldsInitialized: (areInitialized: boolean) => void;
}

export const GroupFormContext = React.createContext(
  {} as GroupFormContextInterface,
);

const GroupFormContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [formValidity, setFormValidity] = useState(
    {} as Record<string, boolean>,
  );
  const { setIsValidGroupInput, groupsAndTeamsMembers } = useContext(
    GroupsAndTeamsContext,
  );
  const [memberFormsToValidate, setMemberFormsToValidate] = useState<string[]>(
    [],
  );
  const [areFormFieldsInitialized, setAreFormFieldsInitialized] =
    useState<boolean>(false);

  useEffect(() => {
    const isFormValid = Object.keys(formValidity).every(
      (memberId) => formValidity[memberId],
    );
    setIsValidGroupInput(isFormValid && groupsAndTeamsMembers.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValidity]);

  const setValidity = (key: string, validityData: boolean) => {
    setFormValidity((prevValidity) => ({
      ...prevValidity,
      [key]: validityData,
    }));
  };

  const removeEntry = (key: string) => {
    setFormValidity((prevValidity) => {
      delete prevValidity[key];
      return { ...prevValidity };
    });
  };

  const clearValidity = () => {
    setFormValidity({});
  };

  return (
    <GroupFormContext.Provider
      value={{
        formValidity,
        setValidity,
        clearValidity,
        removeEntry,
        memberFormsToValidate,
        setMemberFormsToValidate,
        areFormFieldsInitialized,
        setAreFormFieldsInitialized,
      }}
    >
      {children}
    </GroupFormContext.Provider>
  );
};

export default GroupFormContextProvider;
