import React, { ReactNode, useState } from "react";
import { SizeChartProps } from "@vp/lat-react-component-library";
import { getCulture } from "../../../../utils/LocalizationHelpers";
import { useBffData, useSizeChart } from "../../../../hooks/queries";

interface ISizeChartContext {
  sizeChart: SizeChartProps | undefined;
  isSizeChartLoaded: boolean;
  handleSizeChartClick: () => void;
  handleSizeChartClosed: () => void;
  isSizeChartModalOpen: boolean;
  setIsSizeChartModalOpen: (isOpen: boolean) => void;
}

export const SizeChartContext = React.createContext({} as ISizeChartContext);

export type SizeChartContextProps = {
  children: ReactNode;
};

const SizeChartContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const culture = getCulture();

  const { data: bffData } = useBffData();
  const { productKey, isSizedGood } = bffData!;
  const { data: sizeChart, isSuccess: isSizeChartLoaded } = useSizeChart(
    culture,
    productKey,
    isSizedGood,
  );

  const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false);

  const handleSizeChartClick = () => {
    setIsSizeChartModalOpen(true);
  };

  const handleSizeChartClosed = () => {
    setIsSizeChartModalOpen(false);
  };

  return (
    <SizeChartContext.Provider
      value={{
        sizeChart,
        isSizeChartLoaded,
        handleSizeChartClick,
        handleSizeChartClosed,
        isSizeChartModalOpen,
        setIsSizeChartModalOpen,
      }}
    >
      {children}
    </SizeChartContext.Provider>
  );
};

export default SizeChartContextProvider;
