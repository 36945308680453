import React, { useContext } from "react";
import { QuantityTextInputField, QuantityDropdownField } from "./quantity";
import { FieldDefinition } from "../../../../../types/GroupsAndTeams";
import {
  GroupsAndTeamsContext,
  QUANTITY_KEY,
} from "../../../context/GroupsAndTeamsContext";

interface IQuantityField {
  autoFocus: boolean;
  initialEntryData: Record<string, string>;
  fieldDefinition: FieldDefinition;
  disabled: boolean;
  onValueChange: (value: string, key: string, isValid: boolean) => void;
  updateTeammateValidity: (key: string, validityData: boolean) => void;
  onFocus?: () => void;
  entryId: string;
  validateQuantityInput: boolean;
  isSizedGood: boolean;
  sizeName?: string;
  totalStock: number;
  isLowStock: boolean;
  curSizeQuantity: number;
  showsFormLabel: boolean;
  handleSizeDropdownError: () => void;
}
export const QuantityField: React.FC<IQuantityField> = ({
  autoFocus,
  initialEntryData,
  fieldDefinition,
  disabled,
  onValueChange,
  updateTeammateValidity,
  onFocus,
  entryId,
  validateQuantityInput,
  isSizedGood,
  sizeName,
  totalStock,
  isLowStock,
  curSizeQuantity,
  showsFormLabel,
  handleSizeDropdownError,
}) => {
  const { quantitySelectionListValues } = useContext(GroupsAndTeamsContext);

  return quantitySelectionListValues.length > 0 ? (
    <QuantityDropdownField
      key={`${QUANTITY_KEY}-dropdown`}
      autoFocus={false}
      fieldDefinition={fieldDefinition}
      presetValue={initialEntryData[QUANTITY_KEY]}
      disabled={disabled}
      onDropdownChange={onValueChange}
      triggerValidation={validateQuantityInput}
      entryId={entryId}
      updateTeammateValidity={updateTeammateValidity}
      isSizedGood={isSizedGood}
      sizeName={sizeName}
      totalStock={totalStock}
      isLowStock={isLowStock}
      dropdownValues={quantitySelectionListValues}
      showsFormLabel={showsFormLabel}
      handleSizeDropdownError={handleSizeDropdownError}
    />
  ) : (
    <QuantityTextInputField
      key={`${QUANTITY_KEY}-textInput`}
      autoFocus={autoFocus}
      fieldDefinition={fieldDefinition}
      defaultValue={initialEntryData[QUANTITY_KEY]}
      disabled={disabled}
      onFocus={onFocus}
      onQuantityChange={onValueChange}
      triggerValidation={validateQuantityInput}
      entryId={entryId}
      updateTeammateValidity={updateTeammateValidity}
      isSizedGood={isSizedGood}
      sizeName={sizeName}
      totalStock={totalStock}
      isLowStock={isLowStock}
      curSizeQuantity={curSizeQuantity}
      showsFormLabel={showsFormLabel}
      handleSizeDropdownError={handleSizeDropdownError}
    />
  );
};
