export default async function fetchResources(tenant, locale) {
  try {
    const res = await fetch(
      `https://tracking.cdn.vpsvc.com/consent-manager/resources/${tenant}/${locale}/resources.json`.toLowerCase(),
    );

    if (!res.ok) {
      console.log(
        `Failed to fetch consent manager resources for locale: ${locale}, tenant: ${tenant} => HTTP ${res.status} ${res.statusText}`,
      );
      return undefined;
    }

    return res.json();
  } catch (e) {
    console.log(
      `Failed to fetch consent manager resources for locale: ${locale}, tenant: ${tenant}`,
    );
    return undefined;
  }
}
