import { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import { MerchantAvailabilityRequest } from "../clients/MerchantAvailabilityClient";
import { createMerchantAvailabilityClient } from "../clients";
import type { Quantity } from "../types/Inventory";

export const getDeliveryDate = async (
  quantityData: Quantity,
  merchandisingSelections: Record<string, any>,
  productKey: string,
  productVersion: number,
  isSizedGood: boolean,
  country: string,
  authHeader: string,
) => {
  let deliveryDateRequest: MerchantAvailabilityRequest;

  if (isSizedGood) {
    deliveryDateRequest = getSizedGoodInventoryRequest(
      quantityData as DynamicSizedQuantities,
    );
  } else {
    deliveryDateRequest = getHardGoodInventoryRequest(
      quantityData as number,
      merchandisingSelections,
    );
  }

  // Get delivery date for product
  const merchantAvailabilityClient =
    createMerchantAvailabilityClient(authHeader);
  const { Size, ...selectedOptions } = merchandisingSelections;
  const deliveryDate = await merchantAvailabilityClient.getDeliveryDate(
    productKey,
    productVersion,
    country,
    selectedOptions,
    isSizedGood,
    deliveryDateRequest,
  );

  return deliveryDate;
};

const getSizedGoodInventoryRequest = (
  quantityData: DynamicSizedQuantities,
): MerchantAvailabilityRequest => {
  return {
    facetQuantities: Object.keys(quantityData).map((size) => ({
      quantity: quantityData[size],
      value: size,
    })),
  };
};

const getHardGoodInventoryRequest = (
  quantityData: number,
  merchandisingSelections: Record<string, any>,
): MerchantAvailabilityRequest => {
  return {
    facetQuantities: [
      {
        quantity: quantityData,
        value: merchandisingSelections["Substrate Color"],
      },
    ],
  };
};

export const cleanQuantitiesForSizedGoods = (
  quantityData: Quantity,
  isSizedGood: boolean,
) => {
  if (!isSizedGood) {
    return quantityData;
  }

  let cleanedQuantity = {};
  let sizedGoodQuantityData = quantityData as DynamicSizedQuantities; // required to resolve type errors
  Object.keys(quantityData).forEach((quantityKey: string) => {
    if (quantityKey && sizedGoodQuantityData[quantityKey] > 0) {
      cleanedQuantity = {
        ...cleanedQuantity,
        [quantityKey]: sizedGoodQuantityData[quantityKey],
      };
    }
  });

  return cleanedQuantity;
};
