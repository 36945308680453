import { Purpose } from "./GroupingService";

export type ExperimentVariationKey = "Control" | "Enabled";
export type ExperimentVariations = Record<ExperimentVariationKey, string>;

export type Experiment = "groups_and_teams_review_page_zoom";

type ExperimentData = {
  experimentKey: Experiment;
  variations: ExperimentVariations;
  purposeToLimitBy?: Purpose; // Represents what Grouping Service purpose (if any) should be use to limit access to this AB test
};

export const ActiveExperiments: Array<ExperimentData> = [
  {
    experimentKey: "groups_and_teams_review_page_zoom",
    variations: { Control: "control", Enabled: "test" },
  },
];
