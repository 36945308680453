import React, { PureComponent } from "react";
import { GridContainer, Row, Column } from "@vp/swan";
import l10n from "../../../localization/localization";
import PreferenceCategory from "../preference-category";
import ConfirmationDialog from "./confirmation-dialog";
import { Mode } from "../../../constants";

const dataSaleCategoryName = "dataSale";

export default class Preference extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmationDialogVisible: false,
    };
  }

  render() {
    const { mode, dataSale } = this.props;
    const { confirmationDialogVisible } = this.state;

    const position = this.getPosition(mode);

    const category = {
      name: dataSaleCategoryName,
      enabled: !dataSale,
      readonly: false,
    };

    return (
      <>
        <GridContainer marginBottom="5">
          <Row marginBottom="3">
            <Column {...position} align="left">
              {l10n.t("preferenceDataSale.content")}
            </Column>
          </Row>
          <PreferenceCategory
            key={category.name}
            category={category}
            handleChange={this.handleDataSaleChange}
            mode={mode}
          />
        </GridContainer>
        <ConfirmationDialog
          isOpen={confirmationDialogVisible}
          onCancel={this.handleCancelOptIn}
          onConfirm={this.handleConfirmOptIn}
        />
      </>
    );
  }

  getPosition(mode) {
    if (mode === Mode.Embedded) {
      return {
        span: 12,
      };
    }

    return {
      offsetLg: 3,
      spanLg: 6,
      offset: 1,
      span: 10,
      spanXs: 12,
    };
  }

  handleDataSaleChange = (_, doNotSell) => {
    const { onChange } = this.props;

    if (!doNotSell) {
      this.setState({ confirmationDialogVisible: true });
    } else {
      onChange(dataSaleCategoryName, false);
    }
  };

  handleCancelOptIn = () => {
    this.setState({ confirmationDialogVisible: false });
  };

  handleConfirmOptIn = () => {
    const { onChange } = this.props;

    onChange(dataSaleCategoryName, true);
    this.setState({ confirmationDialogVisible: false });
  };
}
