import Axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import { getTenantConfig } from "../utils/tenantHelpers";

export default class BaseAxiosClient {
  protected axios: AxiosInstance;
  protected tenantConfig: any;
  protected baseUrl: string;

  constructor(baseUrl: string, authHeader?: string) {
    const axios = Axios.create({ baseURL: baseUrl });

    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (window.newrelic) {
          window.newrelic.noticeError(err);
        } else {
          console.error("Unable to log error to new relic");
        }
        throw err;
      },
    );

    // Network errors and 5xx errors will be retried up to three times
    axiosRetry(axios);

    if (authHeader) {
      axios.defaults.headers.common["Authorization"] = authHeader;
    }

    this.axios = axios;

    this.tenantConfig = getTenantConfig();

    this.baseUrl = baseUrl;
  }
}
