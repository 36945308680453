import React, { ReactNode, useEffect, useState } from "react";
import type { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import { useBffData } from "../../../../hooks/queries";

interface IQuantityContext {
  teamsQuantity: number | DynamicSizedQuantities;
  totalQuantity: number;
  isValidQuantity: boolean;
  isAboveMOQ: boolean;
  setIsValidQuantity: (value: boolean) => void;
  updateQuantity: (quantity: number | DynamicSizedQuantities) => void;
}

export const QuantityContext = React.createContext({} as IQuantityContext);

const QuantityContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { work, isSizedGood, minimumOrderQuantity } = bffData!;
  const [teamsQuantity, setTeamsQuantity] = useState<
    number | DynamicSizedQuantities
  >(isSizedGood ? {} : 0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [isValidQuantity, setIsValidQuantity] = useState(false);
  const [isAboveMOQ, setIsAboveMOQ] = useState(false);

  const getQuantityFromWork = (): number | DynamicSizedQuantities => {
    if (isSizedGood) {
      return (
        work.resources.qty ? JSON.parse(work.resources.qty) : {}
      ) as DynamicSizedQuantities;
    } else {
      return work.resources.qty ? parseInt(work.resources.qty) || 0 : 0;
    }
  };

  const getTotalQuantity = (
    quantity: number | DynamicSizedQuantities,
  ): number => {
    let totalQuantity;
    if (isSizedGood) {
      totalQuantity = Object.values(quantity as DynamicSizedQuantities).reduce(
        (sum: number, next: number) => {
          return sum + next;
        },
        0,
      );
    } else {
      totalQuantity = quantity as number;
    }

    return totalQuantity;
  };

  useEffect(() => {
    if (
      bffDataIsFetched &&
      work.workId &&
      work.resources?.groupsAndTeamsMembers
    ) {
      const initialQuantity = getQuantityFromWork();
      setTeamsQuantity(initialQuantity);
      setTotalQuantity(getTotalQuantity(initialQuantity));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bffDataIsFetched]);

  const updateQuantity = (quantity: number | DynamicSizedQuantities) => {
    if (isSizedGood) {
      const sizedGoodQuantities = quantity as DynamicSizedQuantities;
      const sizeSum = Object.values(sizedGoodQuantities).reduce((val, cur) => {
        if (cur) {
          return val + cur;
        }
        return val;
      }, 0);

      setIsAboveMOQ(sizeSum >= minimumOrderQuantity);
      setIsValidQuantity(sizeSum >= minimumOrderQuantity);
      setTeamsQuantity(sizedGoodQuantities);
      setTotalQuantity(sizeSum);
    } else {
      const hardGoodQuantity = quantity as number;
      setIsAboveMOQ(hardGoodQuantity >= minimumOrderQuantity);
      setIsValidQuantity(hardGoodQuantity >= minimumOrderQuantity);
      setTeamsQuantity(hardGoodQuantity);
      setTotalQuantity(hardGoodQuantity);
    }
  };

  return (
    <QuantityContext.Provider
      value={{
        teamsQuantity,
        totalQuantity,
        isValidQuantity,
        isAboveMOQ,
        setIsValidQuantity,
        updateQuantity,
      }}
    >
      {children}
    </QuantityContext.Provider>
  );
};

export default QuantityContextProvider;
