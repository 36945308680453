import {
  trackConsentCaptured,
  trackDataSaleUpdate,
  identify,
} from "./tracking";
import cookies from "js-cookie";
import topDomain from "@segment/top-domain";

import { STRICTLY_NECESSARY } from "../consent-manager/categories";
import { FacebookPixel } from "../constants";
import { inferDataSalePreferenceModification } from "./data-sale-service";
import global from "../global";

const COOKIE_KEY = "tracking-preferences";
const COOKIE_EXPIRES = 365;

const INITIAL_GDPR_PREFERENCES = {
  advertising: false,
  functional: true,
  sessionReplay: false,
  dataSale: true,
};

const INITIAL_CCPA_PREFERENCES = {
  advertising: true,
  functional: true,
  sessionReplay: true,
  dataSale: true,
};

const MINIMAL_GDPR_PREFERENCES = {
  advertising: false,
  functional: true,
  sessionReplay: false,
};

const MINIMAL_CCPA_PREFERENCES = {
  dataSale: true,
};

let defaultPreferences;

function evaluatePreferences({
  isConsentRequired,
  destinations,
  cookieDomain,
  ccpa,
  categoriesDefault,
}) {
  let cookiePreferences = module.loadPreferences();

  const { initialPreferences, minimalPreferences } = getInitialPreferences(
    isConsentRequired,
    categoriesDefault,
  );

  defaultPreferences = initialPreferences;
  cookiePreferences = module.upgradeCookie(cookieDomain, cookiePreferences);

  if (ccpa) {
    cookiePreferences = handleCcpaPreferences(
      destinations,
      cookieDomain,
      cookiePreferences,
    );
  }

  let {
    destinationPreferences,
    customPreferences,
    hasConsent: cookieHasConsent,
    version,
  } = cookiePreferences;

  let hasConsent = !!(destinationPreferences && customPreferences);

  if (hasConsent) {
    // silently add new destinations
    ({ destinationPreferences } = mapCustomPreferences({
      destinations,
      preferences: { ...initialPreferences, ...customPreferences },
    }));
  }

  if ((isConsentRequired && !hasConsent && !destinationPreferences) || ccpa) {
    // enable strictly necessary and functional preferences by default for countries where consent is required (GDPR) or for us (CCPA)
    ({ destinationPreferences } = getDefaultPreferences(
      destinations,
      initialPreferences,
      customPreferences,
    ));
  }

  if (cookieHasConsent === false) {
    hasConsent = false;
  }

  setCategoryPreferences(defaultPreferences, customPreferences, hasConsent);

  return {
    hasConsent,
    destinationPreferences,
    customPreferences: { ...minimalPreferences, ...customPreferences },
    version,
    initialPreferences,
  };
}

function getInitialPreferences(isConsentRequired, categoriesDefault) {
  const initialPreferences = isConsentRequired
    ? INITIAL_GDPR_PREFERENCES
    : INITIAL_CCPA_PREFERENCES;

  const minimalPreferences = isConsentRequired
    ? MINIMAL_GDPR_PREFERENCES
    : MINIMAL_CCPA_PREFERENCES;

  return {
    initialPreferences: applyDefaultPreferences(
      initialPreferences,
      categoriesDefault,
    ),
    minimalPreferences: applyDefaultPreferences(
      minimalPreferences,
      categoriesDefault,
    ),
  };
}

function applyDefaultPreferences(preferences, categoriesDefault) {
  if (!categoriesDefault || !preferences) {
    return preferences;
  }

  const resultPreferences = {
    ...preferences,
  };

  Object.keys(categoriesDefault)
    .filter((categoryName) => resultPreferences.hasOwnProperty(categoryName))
    .forEach(
      (categoryName) =>
        (resultPreferences[categoryName] = categoriesDefault[categoryName]),
    );

  return resultPreferences;
}

function handleCcpaPreferences(destinations, cookieDomain, cookiePreferences) {
  let {
    destinationPreferences,
    customPreferences,
    hasConsent,
    version,
    lastModified,
  } = cookiePreferences;
  let cookieHasConsent = hasConsent;

  var result = inferDataSalePreferenceModification(
    customPreferences,
    lastModified,
  );

  if (result.cookiePreferencesShouldBeModified) {
    cookieHasConsent = hasConsent ?? false;
    ({ version, customPreferences, lastModified } = result);
    ({ destinationPreferences } = mapCustomPreferences({
      destinations,
      preferences: customPreferences,
    }));

    writeCookie({
      cookieDomain,
      version,
      customPreferences,
      destinationPreferences,
      hasConsent: cookieHasConsent,
      lastModified,
    });
  }

  if (result.profilePreferencesShouldBeModified) {
    identify(result.customPreferences);
    trackDataSaleUpdate(result.customPreferences.dataSale, true);
  }

  return {
    destinationPreferences,
    customPreferences,
    hasConsent: cookieHasConsent,
    version,
    lastModified,
  };
}

function upgradeCookie(cookieDomain, cookiePreferences) {
  if (
    typeof cookiePreferences.version === "undefined" ||
    cookiePreferences.version === 4
  ) {
    return cookiePreferences;
  }

  const isOldCcpaCookie = (dp) =>
    dp && dp["All"] === true && dp[FacebookPixel] === false;

  if (
    cookiePreferences.version === 1 &&
    !cookiePreferences.customPreferences &&
    isOldCcpaCookie(cookiePreferences.destinationPreferences)
  ) {
    const upgradedPreferences = {
      hasConsent: false,
      customPreferences: { dataSale: false },
      version: 4,
      lastModified: cookiePreferences.lastModified,
    };

    writeCookie({
      cookieDomain,
      ...upgradedPreferences,
    });

    return upgradedPreferences;
  }

  if (cookiePreferences.customPreferences) {
    return {
      hasConsent: cookiePreferences.hasConsent,
      customPreferences: {
        advertising: false,
        sessionReplay: false,
        ...cookiePreferences.customPreferences,
      },
      destinationPreferences: cookiePreferences.destinationPreferences,
      version: cookiePreferences.version,
    };
  }

  return cookiePreferences;
}

function loadPreferences() {
  const cookieValue = cookies.get(COOKIE_KEY);

  if (!cookieValue) {
    return {};
  }

  const preferences = JSON.parse(cookieValue);

  if (!preferences) {
    return {};
  }

  return {
    destinationPreferences: preferences.destinations,
    customPreferences: mapCustomPreferencesFromCookie(preferences),
    version: preferences.version,
    hasConsent: preferences.consent ?? !!preferences.custom,
    lastModified: preferences.lastModified,
  };
}

function savePreferences({
  destinationPreferences,
  customPreferences,
  cookieDomain,
  version,
  acceptAll,
  trackingProperties,
  hasConsent,
}) {
  const mergedDestinationPreferences = mergePreferences(destinationPreferences);

  if (trackingProperties) {
    setCategoryPreferences(defaultPreferences, customPreferences, hasConsent);
    trackConsentCaptured(customPreferences, trackingProperties);
  }

  writeCookie({
    cookieDomain,
    version,
    destinationPreferences: mergedDestinationPreferences,
    customPreferences,
    hasConsent,
    lastModified: Date.now(),
  });
}

function writeCookie({
  cookieDomain,
  version,
  destinationPreferences,
  customPreferences,
  hasConsent,
  lastModified,
}) {
  const domain = cookieDomain || topDomain(window.location.href);
  const value = {
    version,
    destinations: destinationPreferences,
    custom: mapCustomPreferencesToCookie(customPreferences),
    consent: hasConsent,
    lastModified,
  };

  cookies.set(COOKIE_KEY, JSON.stringify(value), {
    expires: COOKIE_EXPIRES,
    domain,
    sameSite: "None",
    secure: true,
  });
}

function removePreferences({ cookieDomain }) {
  cookies.remove(COOKIE_KEY, {
    domain: cookieDomain || topDomain(window.location.href),
  });
}

function mapCustomPreferences({ destinations, preferences }) {
  const destinationPreferences = {};
  const customPreferences = {};

  // Default unset preferences to true (for implicit consent)
  for (const preferenceName of Object.keys(preferences)) {
    const value = preferences[preferenceName];
    if (typeof value === "boolean") {
      customPreferences[preferenceName] = value;
    } else {
      customPreferences[preferenceName] = true;
    }
  }

  for (const destination of destinations) {
    handleDestinationPreferences({
      destination,
      destinationPreferences,
      customPreferences,
    });
  }

  return { destinationPreferences };
}

function handleDestinationPreferences({
  destination,
  destinationPreferences,
  customPreferences,
}) {
  if (
    STRICTLY_NECESSARY.find(
      (d) => d.toUpperCase() === destination.id.toUpperCase(),
    )
  ) {
    destinationPreferences[destination.id] = true;
  } else if (
    customPreferences.dataSale === false &&
    destination.dataSale === true
  ) {
    destinationPreferences[destination.id] = false;
  } else if (destination.category === "Strictly Necessary") {
    destinationPreferences[destination.id] = true;
  } else if (destination.category === "Advertising") {
    destinationPreferences[destination.id] = customPreferences.advertising;
  } else if (destination.category === "Session Replay") {
    destinationPreferences[destination.id] = customPreferences.sessionReplay;
  } else {
    destinationPreferences[destination.id] = customPreferences.functional;
  }
}

function mapCustomPreferencesFromCookie(preferences) {
  if (!preferences.custom) {
    return undefined;
  }

  const customPreferences = {};

  if (typeof preferences.custom.advertising !== "undefined") {
    customPreferences.advertising = preferences.custom.advertising;
  }

  if (typeof preferences.custom.functional !== "undefined") {
    customPreferences.functional = preferences.custom.functional;
  }

  if (typeof preferences.custom.sessionreplay !== "undefined") {
    customPreferences.sessionReplay = preferences.custom.sessionreplay;
  }

  if (typeof preferences.custom.datasale !== "undefined") {
    customPreferences.dataSale = preferences.custom.datasale;
  }

  return customPreferences;
}

function mapCustomPreferencesToCookie(customPreferences) {
  if (!customPreferences) {
    return undefined;
  }

  const custom = {};

  if (typeof customPreferences.advertising !== "undefined") {
    custom.advertising = customPreferences.advertising;
  }

  if (typeof customPreferences.functional !== "undefined") {
    custom.functional = customPreferences.functional;
  }

  if (typeof customPreferences.sessionReplay !== "undefined") {
    custom.sessionreplay = customPreferences.sessionReplay;
  }

  if (typeof customPreferences.dataSale !== "undefined") {
    custom.datasale = customPreferences.dataSale;
  }

  return custom;
}

function mergePreferences(newDestinationPreferences) {
  const storedPreferences = module.loadPreferences();

  if (
    storedPreferences &&
    storedPreferences.destinationPreferences &&
    newDestinationPreferences
  ) {
    for (const destinationName of Object.keys(newDestinationPreferences)) {
      storedPreferences.destinationPreferences[destinationName] =
        newDestinationPreferences[destinationName];
    }
    return storedPreferences.destinationPreferences;
  }

  return newDestinationPreferences;
}

function getDefaultPreferences(
  destinations,
  defaultPreferences,
  currentPreferences,
) {
  return module.mapCustomPreferences({
    destinations,
    preferences: {
      ...defaultPreferences,
      ...currentPreferences,
    },
  });
}

function setCategoryPreferences(
  defaultCategoryPreferences,
  customPreferences,
  hasConsent,
) {
  const categoryPreferences = {
    ...defaultCategoryPreferences,
    ...customPreferences,
  };
  if (categoryPreferences.dataSale !== undefined) {
    if (categoryPreferences.dataSale === false) {
      categoryPreferences.advertising = false;
    }
    delete categoryPreferences.dataSale;
  }
  categoryPreferences.strictlyNecessary = true;
  global.setTrackingPreferences({ hasConsent, categoryPreferences });
}

const module = {
  evaluatePreferences,
  loadPreferences,
  savePreferences,
  removePreferences,
  mapCustomPreferences,
  upgradeCookie,
  setCategoryPreferences,
};

export default module;
