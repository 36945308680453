import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useMediaPredicate } from "react-media-hook";

export default function MultilineText(props) {
  const { mobileText, text } = props;

  const extraSmall = useMediaPredicate("only screen and (max-width: 767px)");

  return (
    <>
      {(extraSmall && mobileText ? mobileText : text).map((text, index) => (
        <Fragment key={`text${index}`}>
          {index !== 0 && <br />}
          {text}
        </Fragment>
      ))}
    </>
  );
}

MultilineText.propTypes = {
  mobileText: PropTypes.array,
  text: PropTypes.array.isRequired,
};
