import {
  SaveStrategy,
  YsdSaveStrategy,
  VistaSaveStrategy,
} from "../components/groupsAndTeams/configuration/saveStrategies";
import { GroupsAndTeamsMember } from "../types/GroupsAndTeams";
import { Work } from "../types/Work";

export async function generateGroupsAndTeamsDocuments(
  work: Work,
  groupsAndTeamsMembers: GroupsAndTeamsMember[],
  auth: any,
  isYsdDesign: boolean,
  productKey: string,
  productVersion: number,
  culture: string,
  clearValidity: () => void,
  setValidity: (key: string, validityData: boolean) => void,
  updateGroupsAndTeamsMembers: (value: GroupsAndTeamsMember[]) => void,
) {
  const saveStrategy: SaveStrategy = isYsdDesign
    ? new YsdSaveStrategy()
    : new VistaSaveStrategy(productKey, productVersion, culture);

  const savedMembers = await saveStrategy.saveMembers(
    auth.getAuthorizationHeader(),
    work,
    groupsAndTeamsMembers,
  );

  // update the form validity to reference new group member ids
  clearValidity();
  savedMembers.forEach((member: GroupsAndTeamsMember) => {
    // initially all members are valid (validity was required to generate documents)
    setValidity(member.id, true);
  });

  updateGroupsAndTeamsMembers(savedMembers);
  return savedMembers;
}
