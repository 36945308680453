import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@vp/swan";

interface InventoryMessageProps {
  availableStock: number;
}

export const InventoryMessage: React.FC<InventoryMessageProps> = ({
  availableStock,
}) => {
  const { t } = useTranslation("translation");
  const inventoryMessage = t("groups-and-teams.quantity-available").replace(
    "{0}",
    `${availableStock}`,
  );

  return (
    <Typography textColor="error" fontSkin="body-small">
      {inventoryMessage}
    </Typography>
  );
};
