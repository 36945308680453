import { GroupsAndTeamsMember } from "../types/GroupsAndTeams";
import { InventoryData } from "../types/Inventory";
import { SizeData } from "../types/TeamDetailsConfig";

export const scrollToFirstInvalidEntry = (
  groupsAndTeamsMembers: GroupsAndTeamsMember[],
  formValidity: Record<string, boolean>,
  setMemberFormsToValidate: (memberIds: string[]) => void,
): void => {
  const memberIds = groupsAndTeamsMembers.map((member) => member.id);
  setMemberFormsToValidate(memberIds);

  const firstInvalidEntryId = memberIds.find(
    (memberId) => !formValidity[memberId],
  );
  const firstInvalidEntry = firstInvalidEntryId
    ? document.getElementById(firstInvalidEntryId)
    : undefined;
  if (firstInvalidEntry) {
    firstInvalidEntry.focus({ preventScroll: true });
    firstInvalidEntry.scrollIntoView({
      block: "end",
      inline: "end",
      behavior: "smooth",
    });
  }
};

export const validateQuantityValue = (
  quantityDropdownOptions: string[],
  validationPattern: string,
  quantity: string,
  remainingStock: number,
): boolean => {
  const withinStockLimit = parseInt(quantity) <= remainingStock;

  // Dropdown-style quantity validation
  if (quantityDropdownOptions.length > 0)
    return quantityDropdownOptions.includes(quantity) && withinStockLimit;
  // TextField-style quantity validation
  else
    return !!quantity.match(new RegExp(validationPattern)) && withinStockLimit;
};

export const findSizeValueInAvailableSizes = (
  availableSizes: SizeData[],
  value: string,
) => {
  return availableSizes.find(
    (size) => size.key.toLowerCase() === value.toLowerCase(),
  );
};

export const validateSizeValue = (
  sizes: SizeData[],
  inventory: Record<string, InventoryData>,
  selectedSize: string,
) => {
  const mappedSelectionToSize = findSizeValueInAvailableSizes(
    sizes,
    selectedSize,
  );
  return (
    (mappedSelectionToSize &&
      inventory[mappedSelectionToSize.key] &&
      inventory[mappedSelectionToSize.key].numAvailable > 0) ||
    false
  );
};

export const validatePlaceholderValue = (
  placeholderValue: string,
  validationPattern: string,
) => {
  return !!placeholderValue.match(new RegExp(validationPattern));
};

export const getRemainingStock = (
  otherMembers: GroupsAndTeamsMember[],
  totalStock: number,
) => {
  const otherTeamStockTotal = otherMembers.reduce(
    (total, member) => (total += member.qty),
    0,
  );
  return Math.max(totalStock - otherTeamStockTotal, 0);
};

export const getSameSizeGroupsAndTeamsMembers = (
  groupsAndTeamsMembers: GroupsAndTeamsMember[],
  entryId: string,
  specificSize?: string,
) => {
  if (specificSize) {
    return groupsAndTeamsMembers.filter(
      (member) =>
        member.id !== entryId &&
        member.selectedOptions["Size"] === specificSize,
    );
  }
  return groupsAndTeamsMembers.filter((member) => member.id !== entryId);
};
