import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import l10n from "../../../localization/localization";
import Dialog from "../../dialog";
import Preference from "./preference";
import { Mode } from "../../../constants";

export default class PreferenceDialog extends PureComponent {
  static displayName = "PreferenceDialog";

  static propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    dataSale: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.eventContainerRef = React.createRef();
  }

  render() {
    const { id, onCancel, dataSale = null, onChange } = this.props;

    const eventContainerRef = this.eventContainerRef;

    const headerPosition = {
      offsetLg: 3,
      offset: 1,
      span: 11,
      spanLg: 9,
    };

    const buttons = <></>;

    return (
      <Dialog
        id={id}
        isOpen={this.props.isOpen}
        title={l10n.t("preferenceDataSale.title")}
        buttons={buttons}
        onClose={onCancel}
        headerPosition={headerPosition}
        eventContainerRef={eventContainerRef}
      >
        <Preference
          dataSale={dataSale}
          onChange={onChange}
          mode={Mode.Dialog}
        />
      </Dialog>
    );
  }
}
