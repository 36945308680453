import React from "react";
import PropTypes from "prop-types";
import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogHeader,
  ModalDialogCloseButton,
  GridContainer,
  Row,
  Column,
  H3,
} from "@vp/swan";
import l10n from "../localization/localization";

import styles from "../styles/cookie-message-style.scss";

export default function Dialog(props) {
  const {
    id,
    isOpen,
    onClose,
    title,
    children,
    buttons,
    headerPosition,
    eventContainerRef,
  } = props;

  return (
    <ModalDialog id={id} isOpen={isOpen} onRequestDismiss={onClose} takeOver>
      <ModalDialogContent
        aria-labelledby="consent-dialog-title"
        ref={eventContainerRef}
      >
        <ModalDialogCloseButton
          visuallyHiddenLabel={l10n.t("preferenceDialogV5.dialog.close")}
        />
        <ModalDialogHeader id="consent-dialog-title">
          <GridContainer>
            <Row>
              <Column {...headerPosition}>
                <H3 className={styles.cookieSettingsTitle}>{title}</H3>
              </Column>
            </Row>
          </GridContainer>
        </ModalDialogHeader>
        <ModalDialogBody>{children}</ModalDialogBody>
        <ModalDialogButtons className={styles.cookieSettingsButtons}>
          {buttons}
        </ModalDialogButtons>
      </ModalDialogContent>
    </ModalDialog>
  );
}

Dialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node.isRequired,
  headerPosition: PropTypes.object.isRequired,
  eventContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

Dialog.displayName = "Dialog";
