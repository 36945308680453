import React from "react";
import { useAlertState } from "./contexts/AlertContext";
import {
  AlertBox,
  AlertBoxDismissButton,
  Box,
  Responsive,
  StyleSpace,
} from "@vp/swan";

interface IAlertCollectionProps {
  paddingX?: Responsive<StyleSpace>;
}

const AlertCollection: React.FC<IAlertCollectionProps> = ({ paddingX = 0 }) => {
  const {
    error,
    warning,
    success,
    pricingError,
    setError,
    setWarning,
    setSuccess,
  } = useAlertState();

  return (
    <Box className="alert-collection" paddingX={paddingX} marginY={6}>
      {error && (
        <AlertBox
          skin="error"
          onRequestDismiss={() => setError("")}
          marginX="auto"
          style={{ width: "100%" }} // temporary fix, remove when width issues with the AlertBox are fixed in a future SWAN version
        >
          {error}
          <AlertBoxDismissButton accessibleText="Dismiss alert" />
        </AlertBox>
      )}
      {warning && (
        <AlertBox
          skin="warning"
          onRequestDismiss={() => setWarning("")}
          marginX="auto"
          style={{ width: "100%" }} // temporary fix, remove when width issues with the AlertBox are fixed in a future SWAN version
        >
          {warning}
          <AlertBoxDismissButton accessibleText="Dismiss alert" />
        </AlertBox>
      )}
      {success && (
        <AlertBox
          skin="success"
          onRequestDismiss={() => setSuccess("")}
          marginX="auto"
          style={{ width: "100%" }} // temporary fix, remove when width issues with the AlertBox are fixed in a future SWAN version
        >
          {success}
          <AlertBoxDismissButton accessibleText="Dismiss alert" />
        </AlertBox>
      )}
      {pricingError && (
        <AlertBox
          skin="error"
          marginX="auto"
          style={{ width: "100%" }} // temporary fix, remove when width issues with the AlertBox are fixed in a future SWAN version
        >
          {pricingError}
        </AlertBox>
      )}
    </Box>
  );
};

export default AlertCollection;
