import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "@vp/swan";
import { useAppState } from "../../../root/contexts/AppContext";
import { useTracking } from "../../../root/contexts/TrackingContext";
import { useAlertState } from "../../../root/contexts/AlertContext";
import { createCartManagerClient } from "../../../../clients";
import { useIdentityContext } from "../../../root/contexts/IdentityContext";

type AddToCartButtonProps = {
  disabled?: boolean;
  saveWorkBeforeAddToCart?: () => Promise<void>;
  runTracking: () => void;
};

const AddToCartButton: React.FC<AddToCartButtonProps> = (props) => {
  const { disabled = false, saveWorkBeforeAddToCart, runTracking } = props;
  const { workId, isCareAgent, editSource, culture, nextStepUrl } =
    useAppState();
  const { auth } = useIdentityContext();

  const { isTrackingReady } = useTracking();
  const { setError, setSuccess } = useAlertState();
  const { t } = useTranslation("translation");
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);

  async function onAddToCartClick() {
    setIsUpdatingCart(true);

    try {
      if (saveWorkBeforeAddToCart) {
        // TODO : we should handle the case where some works save and some do not.
        // should we just ask user to try all again?
        await saveWorkBeforeAddToCart();
      }
      const cartClient = createCartManagerClient(auth.getAuthorizationHeader());
      await cartClient.upsertCart(workId, culture);
    } catch (err) {
      setIsUpdatingCart(false);
      setError(t("alert-messages.unable-to-add-to-cart"));
      return;
    }

    if (isTrackingReady) {
      runTracking();
    } else {
      // Should we be logging that we tried tracking an event but
      // the library wasn't available for us to do so?
    }

    // When adding to cart as a CARE agent we want to display a success message
    // and to also stop navigation since we don't want to take them to their cart
    if (isCareAgent) {
      setIsUpdatingCart(false);
      setSuccess(t("alert-messages.customer-cart-updated"));
      return;
    }

    const nextStepPath = nextStepUrl.replace("{workId}", workId);

    window.location.href = nextStepPath;
  }

  return (
    <Button
      className="review-add-to-cart-button"
      skin="primary"
      disabled={isUpdatingCart || disabled}
      onClick={onAddToCartClick}
      mr="between-actions"
      width="standard"
    >
      {isUpdatingCart && <Spinner accessibleText={t("loading-label")} />}
      {editSource ? t("update-cart") : t("add-to-cart")}
    </Button>
  );
};

export default AddToCartButton;
