import React, { PureComponent } from "react";
import Preference from "./preference";
import { Mode } from "../../../constants";

export default class PreferenceEmbedded extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { preferences, handleCategoryChange } = this.props;

    return (
      <Preference
        dataSale={preferences.dataSale}
        onChange={handleCategoryChange}
        mode={Mode.Embedded}
      />
    );
  }
}
