import { useQuery } from "react-query";
import { getCountry } from "../../utils/LocalizationHelpers";
import { useBffData } from ".";
import { createProductIntermediaryClient } from "../../clients";
import { STALE_TIME_10_MINUTES } from "./constants";

export default function useProductIntermediary() {
  const country = getCountry();
  const { data: bffData } = useBffData();
  const { productKey, productVersion, lowestPriceAttributes } = bffData!;

  return useQuery<Array<string>>(
    ["product-quantities", productKey, productVersion, country],
    async () => {
      const { Size, ...selectedOptions } = lowestPriceAttributes;
      const productIntermediaryClient = createProductIntermediaryClient();
      const quantity = await productIntermediaryClient.getAvailableQuantities(
        productKey,
        productVersion,
        country,
        selectedOptions,
      );

      return quantity ?? [];
    },
    {
      enabled: !!productKey && !!productVersion && !!country,
      staleTime: STALE_TIME_10_MINUTES,
    },
  );
}
