import { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import { QUANTITY_KEY } from "../components/groupsAndTeams/context/GroupsAndTeamsContext";
import type { GroupsAndTeamsMember } from "../types/GroupsAndTeams";
import type { Work } from "../types/Work";

export const buildWorkWithTeamMembers = (
  groupsAndTeamsMembers: GroupsAndTeamsMember[],
  isSizedGood: boolean,
  currentWork: Work,
): Work => {
  const updatedWork = { ...currentWork };
  let updatedTotalQuantity = 0;
  const updatedSizedQuantity = {} as DynamicSizedQuantities;
  const newGroupsAndTeamsMembers = groupsAndTeamsMembers.map(
    (member: GroupsAndTeamsMember) => {
      return {
        ...member,
        placeholderValues: getUnmergedPlaceholderValues(
          member.placeholderValues,
        ),
      };
    },
  );
  updatedWork.resources.groupsAndTeamsMembers = JSON.stringify({
    values: newGroupsAndTeamsMembers,
  });

  newGroupsAndTeamsMembers.forEach((member: GroupsAndTeamsMember) => {
    updatedTotalQuantity += member.qty;
    if (isSizedGood) {
      updatedSizedQuantity[member.selectedOptions.Size] =
        (updatedSizedQuantity[member.selectedOptions.Size] ?? 0) +
        member[QUANTITY_KEY];
    }
  });

  updatedWork.merchandising.quantity = updatedTotalQuantity;
  updatedWork.resources.qty = isSizedGood
    ? JSON.stringify(updatedSizedQuantity)
    : JSON.stringify(updatedTotalQuantity);

  return updatedWork;
};

export const getMergedPlaceholderValues = (
  placeholderValues: Record<string, string>,
): Record<string, string> => {
  const valueKeyPairs = {} as Record<string, string[]>;
  for (const [key, value] of Object.entries(placeholderValues)) {
    if (valueKeyPairs[value]) {
      valueKeyPairs[value].push(key);
    } else {
      valueKeyPairs[value] = [key];
    }
  }

  const keyValuePairs = {} as Record<string, string>;
  for (const [value, keyArray] of Object.entries(valueKeyPairs)) {
    keyValuePairs[keyArray.sort().join("/")] = value;
  }

  return keyValuePairs;
};

export const getUnmergedPlaceholderValues = (
  placeholderValues: Record<string, string>,
): Record<string, string> => {
  const unmergedPlaceholderValues = {} as Record<string, string>;
  for (const [keysString, value] of Object.entries(placeholderValues)) {
    const keys = keysString.split("/");
    keys.forEach((key) => {
      unmergedPlaceholderValues[key] = value;
    });
  }
  return unmergedPlaceholderValues;
};
