import { IQuotePrice } from "@vp/lat-react-component-library";
import { BaseGroupsAndTeamsMember } from "../types/GroupsAndTeams";
import { buildWebsitePricingQuoteRequest } from "../utils/WebsitePricingHelpers";
import BaseAxiosClient from "./BaseAxiosClient";

export interface IWebsitePricingClient {
  getPriceQuote: (
    productKey: string,
    productVersion: number,
    market: string,
    merchantId: string,
    couponCode: string | undefined,
    customerGroups: string[],
    groupsAndTeamsMembersForPricing: BaseGroupsAndTeamsMember[],
    lowestPriceAttributes: Record<string, string>,
    minimumOrderQuantity?: number,
  ) => Promise<IQuotePrice>;
}
export class WebsitePricingClient
  extends BaseAxiosClient
  implements IWebsitePricingClient
{
  constructor(authHeader: string) {
    super(
      process.env.REACT_APP_WEBSITE_PRICING_SERVICE_ENDPOINT as string,
      authHeader,
    );
  }

  async getPriceQuote(
    productKey: string,
    productVersion: number,
    market: string,
    merchantId: string,
    couponCode: string | undefined,
    customerGroups: string[],
    groupsAndTeamsMembersForPricing: BaseGroupsAndTeamsMember[],
    lowestPriceAttributes: Record<string, string>,
    minimumOrderQuantity?: number,
  ): Promise<IQuotePrice> {
    try {
      let response = await this.axios.post(
        `/quote`,
        buildWebsitePricingQuoteRequest(
          productKey,
          productVersion,
          market,
          merchantId,
          couponCode,
          customerGroups,
          groupsAndTeamsMembersForPricing,
          lowestPriceAttributes,
          minimumOrderQuantity,
        ),
        {
          params: {
            requestor: this.tenantConfig.requestor,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(
        `Unable to retrieve pricing quote for ${productKey} version ${productVersion} in market ${market}`,
      );
      return {} as IQuotePrice;
    }
  }
}

const createWebsitePricingClient = (
  authHeader: string,
): IWebsitePricingClient => {
  return new WebsitePricingClient(authHeader);
};

export default createWebsitePricingClient;
