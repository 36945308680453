import React, { useState, useEffect, useMemo, ReactNode } from "react";
import { getCulture, getCountry } from "../../../../utils/LocalizationHelpers";
import { parseQueryParameters } from "./Parameters/parseParameters";
import { useBffData } from "../../../../hooks/queries";

interface AppContextInterface {
  workId: string;
  culture: string;
  country: string;
  editSource?: string;
  isCareAgent: boolean;
  nextStepUrl: string;
  debug: boolean;
}

export const AppContext = React.createContext<AppContextInterface>(
  {} as AppContextInterface,
);

const AppContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [nextStepUrl, setNextStepUrl] = useState<string>("/c");

  // workEntityIds is the initial IDs when the page is first loaded.
  const { workEntityId, isCareAgent, editSource, debug } = parseQueryParameters(
    window.location.search.substring(1),
  );

  const culture = getCulture();
  const country = getCountry();

  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();

  useEffect(() => {
    if (!bffDataIsFetched || !bffData) {
      return;
    }
    setNextStepUrl(bffData.nextStepUrl);
    try {
      window.newrelic.setCustomAttribute("productKey", bffData.productKey);
    } catch (ex) {
      console.warn("Failed to set custom attributes for NewRelic.");
    }
  }, [bffData, bffDataIsFetched]);

  const contextObject = useMemo(
    () => ({
      workId: workEntityId,
      culture,
      country,
      editSource,
      isCareAgent,
      nextStepUrl,
      debug,
    }),
    [
      workEntityId,
      culture,
      country,
      editSource,
      isCareAgent,
      nextStepUrl,
      debug,
    ],
  );

  return (
    <AppContext.Provider value={contextObject}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
