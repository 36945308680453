import { Mode } from "../constants";

export default function conditionallyLoadAnalytics(data) {
  if (window.analytics && typeof window.analytics.load === "function") {
    conditionallyLoadAnalyticsInternal(data);
  } else if (window.analytics && window.analytics.initialized) {
    conditionallyLoadAnalyticsInternal(data);
  } else {
    window.addEventListener("trackingReady", () =>
      conditionallyLoadAnalyticsInternal(data),
    );
  }
}

function conditionallyLoadAnalyticsInternal({
  writeKey,
  destinations,
  destinationPreferences,
  suppressReload,
  originalPreferences,
  newPreferences,
  mode,
  initialization,
}) {
  const integrations = { All: false };

  if (!destinationPreferences) {
    // Load a.js normally when there's no preferences
    if (!window.analytics.initialized) {
      window.analytics.load(writeKey);
    }
    return;
  }

  const all = Boolean(destinationPreferences.All);

  for (const destination of destinations) {
    integrations[destination.id] =
      destinationPreferences[destination.id] !== undefined
        ? Boolean(destinationPreferences[destination.id])
        : all;
  }

  // Reload the page if the trackers have already been initialised so that
  // the user's new preferences can take affect
  if (window.analytics.initialized) {
    if (
      shouldReload(
        suppressReload,
        originalPreferences,
        newPreferences,
        mode,
        initialization,
      )
    ) {
      reload();
    }
    return;
  }

  // The analytics.load handle the case when everything is disabled.
  window.analytics.load(writeKey, { integrations });
}

function reload() {
  setTimeout(() => {
    window.location.reload();
  }, 500);
}

export function shouldReload(
  suppressReload,
  originalPreferences,
  newPreferences,
  mode,
  initialization,
) {
  if (mode === Mode.Embedded || initialization === true) {
    return false;
  }

  if (!originalPreferences || !newPreferences) {
    return true;
  }

  // no change
  if (
    originalPreferences.functional === newPreferences.functional &&
    originalPreferences.advertising === newPreferences.advertising &&
    originalPreferences.sessionReplay === newPreferences.sessionReplay
  ) {
    return false;
  }

  // functional disabled
  if (originalPreferences.functional && !newPreferences.functional) {
    return true;
  }

  // advertising disabled
  if (originalPreferences.advertising && !newPreferences.advertising) {
    return true;
  }

  // sessionReplay disabled
  if (originalPreferences.sessionReplay && !newPreferences.sessionReplay) {
    return true;
  }

  return !suppressReload;
}
