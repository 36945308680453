import BaseAxiosClient from "./BaseAxiosClient";

interface IEntityCollectionParseResponse {
  entities: Record<string, any>[];
}

export interface IEntityCollectionClient {
  parseFile: (file: File) => Promise<IEntityCollectionParseResponse>;
}

class EntityCollectionClient
  extends BaseAxiosClient
  implements IEntityCollectionClient
{
  constructor(authHeader: string) {
    super(
      process.env.REACT_APP_ENTITY_COLLECTION_SERVICE_ENDPOINT as string,
      authHeader,
    );
  }

  async parseFile(file: File): Promise<IEntityCollectionParseResponse> {
    try {
      const response = await this.axios.post<IEntityCollectionParseResponse>(
        `parse-collection`,
        await file.arrayBuffer(),
        {
          params: {
            requestor: this.tenantConfig.requestor,
          },
          headers: {
            "Content-Type": "application/vnd.ms-excel",
          },
        },
      );
      return response.data;
    } catch (err) {
      console.error("Entity Collection file parsing failed.");
      throw err;
    }
  }
}

const createEntityCollectionClient = (
  authHeader: string,
): EntityCollectionClient => {
  return new EntityCollectionClient(authHeader);
};

export default createEntityCollectionClient;
