import { Callout } from "@vp/swan";

interface IHintCallout {
  hintTextMouse: string; // This variable name is from the ReactImageMagnify component and cannot be renamed
}

export const HintCallout: React.FC<IHintCallout> = ({ hintTextMouse }) => {
  return (
    <Callout className="zoomable-image-callout" variant="inverse">
      {hintTextMouse}
    </Callout>
  );
};
