import BaseAxiosClient from "./BaseAxiosClient";

export interface ICartManagerClient {
  upsertCart: (workId: string, culture: string) => Promise<void>;
}

class CartManagerClient extends BaseAxiosClient implements ICartManagerClient {
  constructor(authHeader: string) {
    super(
      process.env.REACT_APP_LAT_CART_MANAGER_ENDPOINT as string,
      authHeader,
    );
  }

  async upsertCart(workId: string, culture: string): Promise<void> {
    try {
      await this.axios.post(`upsert/${workId}`, "", {
        params: {
          locale: culture,
          tenant: this.tenantConfig.cartManager.tenant,
          requestor: this.tenantConfig.requestor,
        },
      });
    } catch (err) {
      console.error("Upserting customer cart failed.");
      throw err;
    }
  }
}

const createCartManagerClient = (authHeader: string): ICartManagerClient => {
  return new CartManagerClient(authHeader);
};

export default createCartManagerClient;
