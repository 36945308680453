import "./global";
export { default as ConsentManagerBuilder } from "./consent-manager-builder";
export { default as ConsentManager } from "./consent-manager";
export { default as ConsentManagerContainer } from "./consent-manager-container";
export { Mode } from "./constants";
export { openDialog as openConsentManager } from "./consent-manager/container";

export function doNotTrack() {
  if (typeof window === "undefined") return;

  let doNotTrackValue =
    navigator.doNotTrack || window.doNotTrack || navigator.msDoNotTrack;

  // Normalise Firefox < 32
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
  if (doNotTrackValue === "yes") {
    doNotTrackValue = "1";
  } else if (doNotTrackValue === "no") {
    doNotTrackValue = "0";
  }

  if (doNotTrackValue === "1") {
    return true;
  }
  if (doNotTrackValue === "0") {
    return false;
  }
  return null;
}
