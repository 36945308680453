import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import type { DynamicSizedQuantities } from "@vp/lat-react-component-library";

import { TeamsPlaceholdersProvider } from "./TeamsPlaceholdersProvider";
import {
  useBffData,
  useMerchantAvailability,
  useProductIntermediary,
} from "../../../hooks/queries";
import { createUdsClient, createWorkEntityClient } from "../../../clients";
import { useQuantity } from "../../root/contexts/QuantityContext";
import { useIdentityContext } from "../../root/contexts/IdentityContext";
import {
  buildWorkWithTeamMembers,
  getMergedPlaceholderValues,
} from "../../../utils/GroupsAndTeamsHelpers";
import type {
  BaseGroupsAndTeamsMember,
  GroupsAndTeamsMember,
  Placeholder,
} from "../../../types/GroupsAndTeams";
import type { SizeData } from "../../../types/TeamDetailsConfig";
import { useAlertState } from "../../root/contexts/AlertContext";
import { useAppState } from "../../root/contexts/AppContext";

export const SIZE_KEY = "size";
export const QUANTITY_KEY = "qty";

interface SaveGroupsAndTeamsArgs {
  throwErrorOnFail?: boolean;
  showSuccessToast?: boolean;
  showErrorToast?: boolean;
  showDeleteMessaging?: boolean;
  groupsAndTeamsMembersToSave?: GroupsAndTeamsMember[];
}
interface GroupsAndTeamsContextInterface {
  isValidGroupInput: boolean;
  setIsValidGroupInput: (value: boolean) => void;
  isReviewPanelOpen: boolean;
  setIsReviewPanelOpen: (value: boolean) => void;
  isPreviewModalOpen: boolean;
  setIsPreviewModalOpen: (value: boolean) => void;
  isUpdatingTeam: boolean;
  setIsUpdatingTeam: (value: boolean) => void;
  groupsAndTeamsMembers: GroupsAndTeamsMember[];
  updateGroupsAndTeamsMembers: (value: GroupsAndTeamsMember[]) => void;
  validGroupsAndTeamsMembersForPricing: BaseGroupsAndTeamsMember[];
  getBlankTeamMember: () => GroupsAndTeamsMember;
  getNonPersonalizedTeamMember: () => GroupsAndTeamsMember;
  currentFocusEntryId: string;
  setCurrentFocusEntryId: (value: string) => void;
  areTeamMembersInitialized: boolean;
  teamsPlaceholders: Placeholder[];
  availableSizes: SizeData[];
  quantitySelectionListValues: string[];
  isYsdDesign: boolean;
  saveGroupsAndTeamsConfig: (args?: SaveGroupsAndTeamsArgs) => Promise<void>;
  isNonPersonalizedAllowed: boolean;
}

export const GroupsAndTeamsContext = React.createContext(
  {} as GroupsAndTeamsContextInterface,
);

const GroupsAndTeamsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [areTeamMembersInitialized, setAreTeamMembersInitialized] =
    useState(false);
  const { updateQuantity } = useQuantity();
  const [currentFocusEntryId, setCurrentFocusEntryId] = useState("");
  const [isValidGroupInput, setIsValidGroupInput] = useState(false);
  const [isReviewPanelOpen, setIsReviewPanelOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isUpdatingTeam, setIsUpdatingTeam] = useState(false);
  const [isNonPersonalizedAllowed, setIsNonPersonalizedAllowed] =
    useState(false);
  const [groupsAndTeamsMembers, setGroupsAndTeamsMembers] = useState(
    [] as GroupsAndTeamsMember[],
  );
  const [teamsPlaceholders, setTeamsPlaceholders] = useState<Placeholder[]>([]);
  const [availableSizes, setAvailableSizes] = useState([] as SizeData[]);
  const [quantitySelectionListValues, setQuantitySelectionListValues] =
    useState([] as string[]);
  const [
    validGroupsAndTeamsMembersForPricing,
    setValidGroupsAndTeamsMembersForPricing,
  ] = useState([] as BaseGroupsAndTeamsMember[]);

  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { data: inventory, isFetched: inventoryIsFetched } =
    useMerchantAvailability();
  const { data: quantity, isFetched: quantityIsFetched } =
    useProductIntermediary();
  const { work, sizes, isSizedGood } = bffData!;
  const { auth } = useIdentityContext();
  const { isCareAgent } = useAppState();
  const { setSuccess, setError } = useAlertState();
  const { t } = useTranslation("translation");

  const isLoading =
    !bffDataIsFetched || !inventoryIsFetched || !quantityIsFetched;

  useEffect(() => {
    const initializeGroupsAndTeamsMembers = async () => {
      let groupMembers = getGroupMembers();

      const teamsPlaceholdersProvider = new TeamsPlaceholdersProvider(
        createUdsClient(auth.getAuthorizationHeader()),
      );

      const placeholdersCollection =
        await teamsPlaceholdersProvider.getPlaceholders(work);

      const mergedPlaceholders = placeholdersCollection.getMergedPlaceholders();

      const validTeamMembersForPricing: BaseGroupsAndTeamsMember[] = [];
      if (groupMembers.length > 0) {
        const updatedSizedQuantity = {} as DynamicSizedQuantities;
        let updatedTotalQuantity = 0;
        groupMembers.forEach((member: GroupsAndTeamsMember) => {
          let updatedPlaceholderValues: Record<string, string> = {};
          mergedPlaceholders.forEach((placeholder: Placeholder) => {
            updatedPlaceholderValues[placeholder.key] = member
              .placeholderValues[placeholder.key]
              ? member.placeholderValues[placeholder.key]
              : "";
          });
          member.placeholderValues = updatedPlaceholderValues;
          member.shouldShowWarningForEmptyFields = false;

          // Set quantities on work based on team members
          if (member[QUANTITY_KEY]) {
            updatedTotalQuantity += member[QUANTITY_KEY];
            if (isSizedGood) {
              updatedSizedQuantity[member.selectedOptions.Size] =
                updatedSizedQuantity[member.selectedOptions.Size]
                  ? updatedSizedQuantity[member.selectedOptions.Size] +
                    member[QUANTITY_KEY]
                  : member[QUANTITY_KEY];
            }
          }

          if (member.qty !== 0 && member.qty !== undefined) {
            // only add team members that will not break the pricing call
            validTeamMembersForPricing.push({
              id: member.id,
              selectedOptions: member.selectedOptions,
              qty: member.qty,
            });
          }
        });

        const resourcesQty = isSizedGood
          ? updatedSizedQuantity
          : updatedTotalQuantity;

        updateQuantity(resourcesQty);
      } else {
        groupMembers = [getBlankTeamMember(mergedPlaceholders, false)];
      }

      setIsNonPersonalizedAllowed(
        placeholdersCollection.isNonPersonalizedAllowed,
      );
      setGroupsAndTeamsMembers(groupMembers);
      setValidGroupsAndTeamsMembersForPricing(validTeamMembersForPricing);
      setAreTeamMembersInitialized(true);
      setTeamsPlaceholders(mergedPlaceholders);
    };

    const initializeAvailableSizes = () => {
      const newAvailableSizes = inventory
        ? sizes.filter((size) => size.key in inventory)
        : [];
      setAvailableSizes(newAvailableSizes);
    };

    const initializeQuantitySelectionListValues = () => {
      const newQuantitySelectionListValues = quantity ? quantity : [];
      setQuantitySelectionListValues(newQuantitySelectionListValues);
    };

    if (!isLoading) {
      initializeGroupsAndTeamsMembers();
      initializeAvailableSizes();
      initializeQuantitySelectionListValues();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const getGroupMembers = () => {
    if (work.resources?.groupsAndTeamsMembers) {
      const groupMembers = JSON.parse(
        work.resources.groupsAndTeamsMembers,
      ).values;
      const groupMembersWithMergedValues = groupMembers.map((member: any) => {
        return {
          ...member,
          placeholderValues: getMergedPlaceholderValues(
            member.placeholderValues,
          ),
        };
      });
      return groupMembersWithMergedValues;
    }
    return [];
  };

  const getBlankTeamMember = (
    placeholders: Placeholder[],
    isNonPersonalized: boolean,
  ) => {
    let blankPlaceholderValues: Record<string, string> = {};
    placeholders.forEach((placeholder: Placeholder) => {
      blankPlaceholderValues[placeholder.key] = "";
    });

    return {
      id: `${uuidv4()}_temp`,
      placeholderValues: blankPlaceholderValues,
      qty: 0,
      selectedOptions: {},
      isNonPersonalized,
      shouldShowWarningForEmptyFields: false,
    } as GroupsAndTeamsMember;
  };

  const isYsdDesign = !!work.design.metadata?.ysdDesignData;

  const saveGroupsAndTeamsConfig = async (args?: SaveGroupsAndTeamsArgs) => {
    const throwErrorOnFail = args && args.throwErrorOnFail;
    const showSuccessToast = args && args.showSuccessToast;
    const showErrorToast = args && args.showErrorToast;
    const showDeleteMessaging = args && args.showDeleteMessaging;
    const groupsAndTeamsMembersToSave =
      (args && args.groupsAndTeamsMembersToSave) ?? groupsAndTeamsMembers;

    let errorMessage;
    if (showDeleteMessaging) {
      errorMessage = isYsdDesign
        ? t("alert-messages.unable-to-remove-team-member")
        : t("alert-messages.unable-to-remove-person");
    } else {
      errorMessage = isYsdDesign
        ? t("alert-messages.unable-to-save-team")
        : t("alert-messages.unable-to-save-group-details");
    }

    try {
      const updatedWork = buildWorkWithTeamMembers(
        groupsAndTeamsMembersToSave,
        isSizedGood,
        work,
      );
      const workEntityClient = createWorkEntityClient(
        auth.getAuthorizationHeader(),
      );

      await workEntityClient.saveWork(updatedWork, isCareAgent, work.ownerId);

      if (showSuccessToast) setSuccess(t("alert-messages.team-saved"));
    } catch (err) {
      if (showErrorToast) {
        setError(errorMessage);
      }
      if (throwErrorOnFail) throw err;
    }
  };

  const updateGroupsAndTeamsMembers = (
    groupsAndTeamsMembers: GroupsAndTeamsMember[],
  ) => {
    const validTeamMembersForPricing: BaseGroupsAndTeamsMember[] = [];
    groupsAndTeamsMembers.forEach((member: GroupsAndTeamsMember) => {
      if (member.qty !== 0 && member.qty !== undefined) {
        // only add team members that will not break the pricing call
        validTeamMembersForPricing.push({
          id: member.id,
          selectedOptions: member.selectedOptions,
          qty: member.qty,
        });
      }
    });

    setGroupsAndTeamsMembers(groupsAndTeamsMembers);
    setValidGroupsAndTeamsMembersForPricing(validTeamMembersForPricing);
  };

  return (
    <GroupsAndTeamsContext.Provider
      value={{
        isValidGroupInput,
        setIsValidGroupInput,
        isReviewPanelOpen,
        setIsReviewPanelOpen,
        isPreviewModalOpen,
        setIsPreviewModalOpen,
        isUpdatingTeam,
        setIsUpdatingTeam,
        groupsAndTeamsMembers,
        updateGroupsAndTeamsMembers,
        validGroupsAndTeamsMembersForPricing,
        getBlankTeamMember: () => getBlankTeamMember(teamsPlaceholders, false),
        getNonPersonalizedTeamMember: () =>
          getBlankTeamMember(teamsPlaceholders, true),
        currentFocusEntryId,
        setCurrentFocusEntryId,
        areTeamMembersInitialized,
        teamsPlaceholders,
        availableSizes,
        quantitySelectionListValues,
        isYsdDesign,
        saveGroupsAndTeamsConfig,
        isNonPersonalizedAllowed,
      }}
    >
      {children}
    </GroupsAndTeamsContext.Provider>
  );
};

export default GroupsAndTeamsContextProvider;
