const React = require("react");
const ReactDOM = require("react-dom");

const {
  ConsentManager,
  openConsentManager,
  doNotTrack,
  ConsentManagerContainer,
  Mode,
} = require(".");

const {
  isConsentRequired,
} = require("./consent-manager-builder/consent-required");

function initConsentManager(props) {
  if (typeof window === "undefined") return;

  if (!props.container) {
    throw new Error("ConsentManager: container is required");
  }

  if (!props.writeKey) {
    throw new Error("ConsentManager: writeKey is required");
  }

  if (typeof props.otherWriteKeys === "string") {
    props.otherWriteKeys = props.otherWriteKeys.split(",");
  }

  if (!props.locale) {
    throw new Error("ConsentManager: locale is required");
  }

  if (!props.tenant) {
    throw new Error("ConsentManager: tenant is required");
  }

  const container = document.getElementById(props.container);

  if (!container) {
    throw new Error("ConsentManager: container not found");
  }

  ReactDOM.render(<ConsentManager {...props} />, container);
}

module.exports = {
  openConsentManager,
  doNotTrack,
  initConsentManager,
  ConsentManagerContainer: ConsentManagerContainer,
  isConsentRequired,
  Mode,
};
