import { useQuery } from "react-query";

import { createSizeChartClient } from "../../clients/";
import { STALE_TIME_INFINITY } from "./constants";

const getSizeChart = async (locale: string, productKey: string) => {
  const sizeChartClient = createSizeChartClient();
  const sizeChart = await sizeChartClient.getSizeChart(locale, productKey);
  return sizeChart;
};

export default function useSizeChart(
  locale: string,
  productKey: string,
  isSizedGood: boolean,
) {
  return useQuery(
    ["size-chart", locale, productKey],
    async () => await getSizeChart(locale, productKey),
    {
      enabled: !!locale && !!productKey && isSizedGood,
      staleTime: STALE_TIME_INFINITY,
    },
  );
}
