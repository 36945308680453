import React, { useEffect, useState } from "react";
import { DeliveryCalculatorLink } from "@vp/lat-react-component-library";
import { SkeletonText, SkeletonTextLine } from "@vp/swan";
import { useAppState } from "../../../root/contexts/AppContext";
import { Quantity } from "../../../../types/Inventory";
import { useDeliveryDate } from "../../../../hooks/queries";
import useDebounce from "../../../../hooks/useDebounce";

type DeliveryCalculatorProps = {
  iconColor?: "standard" | "secondary";
  teamsQuantity: Quantity;
  productKey: string;
  productVersion: number;
  isSizedGood: boolean;
  merchandisingSelections: Record<string, string>;
};

export const DeliveryCalculator: React.FC<DeliveryCalculatorProps> = ({
  iconColor = "standard",
  teamsQuantity,
  productKey,
  productVersion,
  isSizedGood,
  merchandisingSelections,
}: DeliveryCalculatorProps) => {
  const { country } = useAppState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const debouncedInputQuantity = useDebounce(teamsQuantity, 500);
  const {
    data: deliveryDate,
    isLoading: isLoadingDeliveryDate,
    isError: isErrorDeliveryDate,
  } = useDeliveryDate(
    debouncedInputQuantity as Quantity,
    merchandisingSelections,
    productKey,
    productVersion,
    isSizedGood,
    country,
  );

  const defaultDeliveryText = "";

  useEffect(() => {
    if (isLoadingDeliveryDate || debouncedInputQuantity !== teamsQuantity) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [teamsQuantity, isLoadingDeliveryDate, debouncedInputQuantity]);

  if (isErrorDeliveryDate) return null;

  return (
    <div aria-live="polite" aria-busy={isLoading}>
      {isLoading && (
        <SkeletonText>
          <SkeletonTextLine></SkeletonTextLine>
        </SkeletonText>
      )}
      {!isLoading && deliveryDate && (
        <DeliveryCalculatorLink
          iconColor={iconColor}
          defaultText={defaultDeliveryText}
          deliveryDate={deliveryDate}
        />
      )}
    </div>
  );
};
