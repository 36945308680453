import {
  createTeamsDocumentGenerationClient,
  createPurcsClient,
} from "../../../../clients";
import { getUnmergedPlaceholderValues } from "../../../../utils/GroupsAndTeamsHelpers";
import type { CreateDocumentsResult } from "../../../../clients/TeamsDocumentGenerationClient";
import type { SaveStrategy, SaveResult } from "./SaveStrategy";
import type { GroupsAndTeamsMember } from "../../../../types/GroupsAndTeams";
import type { Work } from "../../../../types/Work";

export default class VistaSaveStrategy implements SaveStrategy {
  constructor(
    protected productKey: string,
    protected productVersion: number,
    protected culture: string,
  ) {}

  async saveMembers(
    authHeader: string,
    work: Work,
    teamMembers: GroupsAndTeamsMember[],
  ): Promise<SaveResult> {
    const groupMemberPlaceholderData = teamMembers.map(
      (member: GroupsAndTeamsMember) => {
        return {
          placeholderValues: getUnmergedPlaceholderValues(
            member.placeholderValues,
          ),
        };
      },
    );

    const teamsDocumentGenerationClient =
      createTeamsDocumentGenerationClient(authHeader);
    const purcsClient = createPurcsClient();
    const createDocumentsResults =
      await teamsDocumentGenerationClient.createDocuments(
        work.design.designUrl,
        groupMemberPlaceholderData,
      );

    const groupMemberDataWithDocuments = await Promise.all(
      createDocumentsResults.map(
        async (result: CreateDocumentsResult, i: number) => {
          const matchingGroupData = teamMembers[i];
          let memberId = matchingGroupData.id;

          /*
           * Use the QSP-generated guid as the member id for now. This is to maintain
           * parity with how the custom sportswear service generates unique ids
           * for each team member when generating documents.
           */
          if (memberId.includes("_temp")) {
            memberId = memberId.replace("_temp", "");
          }

          const selectedOptions = {
            ...work.merchandising.merchandisingSelections,
            ...matchingGroupData.selectedOptions,
          };

          const previewUrls = await purcsClient.getPreviewUrls(
            this.culture,
            this.productKey,
            this.productVersion,
            result.previewInstructionsUrl,
            selectedOptions,
          );

          return {
            id: memberId,
            qty: matchingGroupData.qty,
            selectedOptions: selectedOptions,
            placeholderValues: matchingGroupData.placeholderValues,
            documentUrl: result.documentUrl,
            previewUrl: previewUrls[0],
            previewUrls,
            previewInstructionsUri: result.previewInstructionsUrl,
            isNonPersonalized: teamMembers[i].isNonPersonalized,
            shouldShowWarningForEmptyFields: false,
          };
        },
      ),
    );

    return groupMemberDataWithDocuments;
  }
}
