import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Box } from "@vp/swan";
import { ReviewContext } from "./context/ReviewContext";

const ApprovalCheckbox: React.FC<{}> = () => {
  const { isApproved, setIsApproved } = useContext(ReviewContext);
  const { t } = useTranslation("translation");

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    setIsApproved(input.checked);
  };

  return (
    <Box className="approval-checkbox" mb="between-subsections">
      <label htmlFor="approval-checkbox">
        <Checkbox
          aria-required="true"
          style={{ verticalAlign: "text-bottom" }}
          value={"approved"}
          checked={isApproved}
          onChange={onInputChange}
        />{" "}
        {t("review-wizard.approval-checkbox-label")}
      </label>
    </Box>
  );
};

export default ApprovalCheckbox;
