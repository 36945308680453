import React from "react";
import { Helmet } from "react-helmet";

import { BookendsMetaTag, BOOKEND_TYPES } from "@vp/esi-bookends";
import { BookendType } from "@vp/esi-bookends/build/esm/constants";

import useTenantConfig from "./hooks/useTenantConfig";
import { getCulture } from "./utils/LocalizationHelpers";

export type ESIBookendsMetaTagProps = {
  headerType: BookendType;
  footerType?: BookendType;
};

export const ESIBookendsMetaTag: React.FC<ESIBookendsMetaTagProps> = ({
  headerType,
  footerType = BOOKEND_TYPES.SLIM,
}) => {
  const { tenantConfig } = useTenantConfig();
  return (
    <>
      {tenantConfig && (
        <BookendsMetaTag
          renderWith={Helmet}
          locale={getCulture()}
          tenant={tenantConfig.bookends.tenant}
          headerType={headerType}
          footerType={footerType}
        />
      )}
    </>
  );
};
