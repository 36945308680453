import { isEuMember } from "is-eu-member";

export function isConsentRequired(locale) {
  let country = parseLocale(locale).country;

  if (country) {
    country = country.toLowerCase();
    return (
      isEuMember(country) ||
      country === "ch" ||
      country === "no" ||
      country === "gb"
    );
  }

  return false;
}

function parseLocale(locale) {
  if (!locale) {
    return {};
  }

  const langCountry = locale.split("-");

  return {
    language: langCountry[0],
    country:
      langCountry.length > 1 ? langCountry[langCountry.length - 1] : undefined,
  };
}
