import React from "react";
import { Box, Spinner } from "@vp/swan";
import { useTranslation } from "react-i18next";

const PreviewPreloader: React.FC<{}> = () => {
  const { t } = useTranslation("translation");

  return (
    <Box textAlign="center">
      <Spinner
        accessibleText={t("groups-and-teams.loading-custom-previews")}
        size="super"
        mt={7}
        showText
      />
    </Box>
  );
};

export default PreviewPreloader;
