import { useQuery } from "react-query";
import { getCountry } from "../../utils/LocalizationHelpers";
import { IQuotePrice } from "@vp/lat-react-component-library";
import { STALE_TIME_10_MINUTES } from "./constants";
import useBffData from "./useBffData";
import useTenantConfig from "../useTenantConfig";
import { usePricingContext } from "../../components/root/contexts/PricingContext";
import { useIdentityContext } from "../../components/root/contexts/IdentityContext";
import { createWebsitePricingClient } from "../../clients";

export default function useStartingAtPrice() {
  const country = getCountry();
  const { data: bffData } = useBffData();
  const {
    productKey,
    productVersion,
    lowestPriceAttributes,
    minimumOrderQuantity,
  } = bffData!;
  const { pricingContextModule } = usePricingContext();
  const { tenantConfig } = useTenantConfig();
  const { auth } = useIdentityContext();

  return useQuery<IQuotePrice>(
    [
      "starting-at-price",
      productKey,
      productVersion,
      country,
      lowestPriceAttributes,
      minimumOrderQuantity,
    ],
    async () => {
      const websitePricingClient = createWebsitePricingClient(
        auth.getAuthorizationHeader(),
      );
      const startingAtPrice = await websitePricingClient.getPriceQuote(
        productKey,
        productVersion,
        pricingContextModule.market ?? country,
        pricingContextModule.merchantId ?? tenantConfig.pricing.tenant,
        pricingContextModule.couponCode,
        pricingContextModule.customerGroups,
        [],
        lowestPriceAttributes,
        minimumOrderQuantity,
      );

      return startingAtPrice;
    },
    {
      enabled:
        !!productKey &&
        !!productVersion &&
        !!country &&
        !!lowestPriceAttributes,
      staleTime: STALE_TIME_10_MINUTES,
    },
  );
}
