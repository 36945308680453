import {
  Placeholder,
  PlaceholderByName,
} from "../../../../types/GroupsAndTeams";

export class PlaceholdersCollection {
  constructor(
    private placeholders: Placeholder[],
    public isNonPersonalizedAllowed: boolean,
  ) {}

  getMergedPlaceholders = () => {
    let placeholderByName: Record<string, PlaceholderByName> = {};
    this.placeholders.forEach((placeholder) => {
      const currName = placeholder.name;
      if (placeholderByName[currName]) {
        placeholderByName[currName].keys.push(placeholder.key);
        if (placeholder.decorationLocations) {
          placeholderByName[currName].decorationLocations.push(
            placeholder.decorationLocations as string,
          );
        }
      } else {
        placeholderByName[currName] = {
          keys: [placeholder.key],
          type: placeholder.type,
          decorationLocations: placeholder.decorationLocations
            ? [placeholder.decorationLocations as string]
            : [],
          validations: placeholder.validations,
        };
      }
    });

    let mergedNamePlaceholders: Placeholder[] = [];
    let mergedNumberPlaceholders: Placeholder[] = [];

    for (const [name, placeholderInfo] of Object.entries(placeholderByName)) {
      const keysString = placeholderInfo.keys.sort().join("/");
      const mergedPlaceholder = {
        key: keysString,
        name: name,
        type: placeholderInfo.type,
        decorationLocations: placeholderInfo.decorationLocations,
        validations: placeholderInfo.validations,
      };

      if (placeholderInfo.type === "number") {
        mergedNumberPlaceholders.push(mergedPlaceholder);
      } else {
        mergedNamePlaceholders.push(mergedPlaceholder);
      }
    }

    return [...mergedNamePlaceholders, ...mergedNumberPlaceholders];
  };
}
