import { Box } from "@vp/swan";
import { Header, BOOKEND_TYPES } from "@vp/esi-bookends";

import { useTracking } from "../../../root/contexts/TrackingContext";
import { getCulture } from "../../../../utils/LocalizationHelpers";
import { getTenantConfig } from "../../../../utils/tenantHelpers";
import { ESIBookendsMetaTag } from "../../../../ESIBookendsMetaTag";

const TeamDetailsHeader: React.FC<{}> = () => {
  const tenantConfig = getTenantConfig();
  const culture = getCulture();
  const { isTrackingReady, getTrackingConfiguration } = useTracking();

  return (
    <Box className="teams-details-header">
      {isTrackingReady ? (
        <>
          <ESIBookendsMetaTag headerType={BOOKEND_TYPES.SLIM} />
          <Header
            locale={culture}
            tenant={tenantConfig.header.tenant}
            type={BOOKEND_TYPES.SLIM}
            hideSearch={true}
            hideVat={true}
            trackingConfiguration={getTrackingConfiguration()}
            useMiniCart={false}
          />
        </>
      ) : null}
    </Box>
  );
};

export default TeamDetailsHeader;
