import { getExperimentVariation } from "../helpers/ab";
import {
  initialize,
  fireImpression as abReaderFireImpression,
} from "@vp/ab-reader";
import reader from "./ctc-reader";
import { Variations } from "../constants";

let ExperimentKey = "cookie_consent_v2_de_fr";

const Experiments = [
  {
    key: "cookie_consent_v3_fr_it_nl",
    treatment: "consent_manager_v3_fr_it_nl_test",
  },
  {
    key: "cookie_consent_v4_uk_de",
    treatment: "consent_manager_v4_uk_de_test",
  },
  { key: "cookie_consent_v2_de_fr", treatment: "consent_manager_v3_fr_test" }, // only for fr, excluding de
];

export const initializeExperiment = () => {
  try {
    initialize();
  } catch (e) {
    console.error(
      `Initialization of the experimentation library failed. Details: ${e.message}`,
    );
  }
};

// all locales: if treatment is enabled and experiment is started, show respective variation from en,de or nl,it,fr experiment
// all locales except fr: if treatment is enabled and - experiment is not started, show variation2
// fr locale: if experiment is not started, show variation from defr experiment
export const getExperimentInfo = async ({ defaultVariation, resources }) => {
  defaultVariation = validateVariation(
    defaultVariation ?? resources?.variationV3_4,
  );

  if (defaultVariation) {
    return {
      isExperimentEnabled: false,
      variation: defaultVariation,
    };
  }

  let someTreatmentEnabled = false;
  for (let experiment of Experiments) {
    ExperimentKey = experiment.key;
    let isTreatmentEnabled = reader.isTreatmentEnabled(experiment.treatment);
    if (isTreatmentEnabled) {
      let variation = await getVariation();
      let isExperimentEnabled = !!variation;
      if (isExperimentEnabled) {
        return {
          isExperimentEnabled: true,
          variation,
        };
      } else if (someTreatmentEnabled) {
        /*
      fr,it,nl treatment and en,de treatment can not be enabled at the same time,
      this condition will only be true if locale is it,en,de or nl and none of the experiments are enabled.
      if locale is fr code will not reach here as defr experiment is enabled
      */
        return {
          isExperimentEnabled: false,
          variation: Variations.Variation2,
        };
      }
      someTreatmentEnabled = true;
    }
  }

  //if none of the treatments are enabled
  return {
    variation: Variations.Variation2,
    isExperimentEnabled: false,
  };
};

export const fireImpression = (variation) => {
  try {
    abReaderFireImpression(ExperimentKey, variation);
  } catch (e) {
    console.error(
      `Firing of an Impression of the experiment '${ExperimentKey}' failed. Details: ${e.message}`,
    );
  }
};

const getVariation = async () => {
  try {
    return await getExperimentVariation(ExperimentKey);
  } catch (e) {
    console.error(
      `Fetching the experiment '${ExperimentKey}' value is failed. Details: ${e.message}`,
    );
  }
};

const validateVariation = (rawVariation) => {
  const availableVariations = [
    Variations.Control,
    Variations.Variation1,
    Variations.Variation2,
    Variations.Variation3,
    Variations.Variation4,
    Variations.Variation5,
  ];

  if (availableVariations.includes(rawVariation)) {
    return rawVariation;
  }

  return undefined;
};
