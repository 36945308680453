import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Divider, H2, Li, P, Ul } from "@vp/swan";
import ApprovalCheckbox from "./ApprovalCheckbox";

const ReviewDesignAcknowledgement: React.FC<{}> = () => {
  const { t } = useTranslation("translation");

  return (
    <Card px={4} pb={0} mb="between-subsections">
      <H2 fontSkin="title-section" mb={5}>
        {t("review-wizard.label")}
      </H2>
      <P mt={0} mb={6}>
        {t("review-wizard.review-message-header")}
      </P>
      <Ul skin="checkmark" mb={6}>
        <Li>{t("review-wizard.review-message-1")}</Li>
        <Li>{t("review-wizard.review-message-2")}</Li>
        <Li>{t("review-wizard.review-message-3")}</Li>
        <Li>{t("review-wizard.review-message-4")}</Li>
      </Ul>
      <Divider my={6} />
      <ApprovalCheckbox />
    </Card>
  );
};

export default ReviewDesignAcknowledgement;
