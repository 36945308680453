export default function getUrl(url) {
  if (url.startsWith("http")) {
    return url;
  }

  const origin = getOrigin();
  if (origin && origin.indexOf("//gallery.") > -1) {
    return origin.replace("//gallery.", "//www.") + url;
  }

  return url;
}

function getOrigin() {
  if (window && window.location && window.location.origin) {
    return window.location.origin;
  }

  return undefined;
}
