import React from "react";
import PropTypes from "prop-types";
import { Column, GridContainer, Row, Button } from "@vp/swan";
import l10n from "../../localization/localization";
import styles from "../../styles/cookie-message-style.scss";
import MultilineText from "../multiline-text";

export default function PreferenceButtons({
  acceptAll,
  submitSelected,
  preferenceDialogKey,
  position,
}) {
  return (
    <GridContainer>
      <Row>
        <Column {...position} verticallyCenterContent>
          <Button
            skin="primary"
            className="styles.acceptButton"
            title={l10n.t(`${preferenceDialogKey}.acceptAll.fullText`)}
            aria-label={l10n.t(`${preferenceDialogKey}.acceptAll.fullText`)}
            onClick={acceptAll}
          >
            <MultilineText
              text={l10n.t(`${preferenceDialogKey}.acceptAll.text`, {
                returnObjects: true,
              })}
            />
          </Button>
          <Button
            skin="secondary"
            className={styles.textButton}
            title={l10n.t(`${preferenceDialogKey}.confirmSelection.fullText`)}
            aria-label={l10n.t(
              `${preferenceDialogKey}.confirmSelection.fullText`,
            )}
            onClick={submitSelected}
          >
            <MultilineText
              text={l10n.t(`${preferenceDialogKey}.confirmSelection.text`, {
                returnObjects: true,
              })}
            />
          </Button>
        </Column>
      </Row>
    </GridContainer>
  );
}

PreferenceButtons.propTypes = {
  acceptAll: PropTypes.func.isRequired,
  submitSelected: PropTypes.func.isRequired,
  preferenceDialogKey: PropTypes.string.isRequired,
  position: PropTypes.object.isRequired,
  isPrimarySkin: PropTypes.bool,
};
