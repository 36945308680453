import React from "react";
import { useTranslation } from "react-i18next";
import { Box, BoundedContent } from "@vp/swan";
import BackToEditDesignLink from "../../review/buttons/BackToEditDesignLink";
import ProceedToTeamReviewButton from "../buttons/ProceedToTeamReviewButton";
import { PriceAndDelivery } from "./PriceAndDelivery";

export const TeamDetailsFooter: React.FC<{}> = () => {
  const { t } = useTranslation("translation");

  return (
    <Box className="teams-footer">
      <BoundedContent>
        <Box className="teams-footer-edit">
          <BackToEditDesignLink
            linkText={t("groups-and-teams.back-to-edit-design")}
            renderAs="link"
            skin="secondary"
          />
        </Box>
        <Box className="teams-footer-price" paddingX={{ xs: 5, sm: 7, md: 5 }}>
          <PriceAndDelivery />
        </Box>
        <Box
          className="teams-footer-proceed"
          paddingX={{ xs: 5, sm: 7, md: 0 }}
        >
          <ProceedToTeamReviewButton useFullWidth={true} />
        </Box>
      </BoundedContent>
    </Box>
  );
};
