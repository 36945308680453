import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalDialogHeader,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  Box,
  ModalDialogNav,
  FlexBox,
  Button,
  Link,
  Icon,
  useScreenClass,
  Typography,
} from "@vp/swan";
import { GroupsAndTeamsContext } from "../context/GroupsAndTeamsContext";
import SizeChartContextProvider from "../shared-preview/sizeChart/SizeChartContext";
import AlertCollection from "../../root/AlertCollection";
import PreviewPreloader from "../shared-preview/PreviewPreloader";
import PreviewBlock from "../shared-preview/PreviewBlock";
import { useShowZoomableImage } from "../../../hooks/useShowZoomableImage";

const PreviewPage: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const { isUpdatingTeam, groupsAndTeamsMembers, setIsPreviewModalOpen } =
    useContext(GroupsAndTeamsContext);

  const isMobileView = useScreenClass() === "xs";

  const showZoomInstructions = useShowZoomableImage();

  return (
    <ModalDialogContent aria-label={t("preview-modal.aria-label")}>
      {!isMobileView && (
        <ModalDialogNav className="modal-navigation-wrapper">
          <FlexBox alignItems="center" p={4}>
            <Link
              fontSkin="body-standard"
              render={(p) => (
                <Button
                  skin="link"
                  className={p.className}
                  iconPosition="right"
                  onClick={() => setIsPreviewModalOpen(false)}
                >
                  {p.children}
                  <Icon alt={t("close")} iconType="close" />
                </Button>
              )}
            >
              {t("preview-modal.close-button-text")}
            </Link>
          </FlexBox>
        </ModalDialogNav>
      )}
      {isMobileView && (
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t("close")} />
        </ModalDialogNav>
      )}
      <ModalDialogHeader fontSkin="title-section">
        {t("preview-modal.header")}
      </ModalDialogHeader>
      <ModalDialogBody>
        <Box>
          {/* Ensure that instructions for zoom only show when the screen is the correct size */}
          {showZoomInstructions && (
            <Typography fontSkin="body-standard">
              {t("preview-modal.zoom-instructions")}
            </Typography>
          )}
          <AlertCollection />
          {isUpdatingTeam && <PreviewPreloader />}
          {!isUpdatingTeam && (
            <Box>
              {groupsAndTeamsMembers.map((member, i) => {
                return (
                  <SizeChartContextProvider key={i}>
                    <PreviewBlock
                      key={i}
                      groupsAndTeamsMember={member}
                      groupsAndTeamsEntryNumber={i}
                      totalTeamMembers={groupsAndTeamsMembers.length}
                      showEditDesignLink={true}
                      isReviewPage={false}
                    />
                  </SizeChartContextProvider>
                );
              })}
            </Box>
          )}
        </Box>
      </ModalDialogBody>
    </ModalDialogContent>
  );
};

export default PreviewPage;
