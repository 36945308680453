import { ProductIntermediaryResponse } from "../types/ProductIntermediary";
import BaseAxiosClient from "./BaseAxiosClient";

export interface IProductIntermediaryClient {
  getAvailableQuantities: (
    productId: string,
    productVersion: number,
    market: string,
    selectedOptions: Record<string, any>,
  ) => Promise<Array<string> | undefined>;
}

export class ProductIntermediaryClient
  extends BaseAxiosClient
  implements IProductIntermediaryClient
{
  constructor() {
    super(process.env.REACT_APP_PRODUCT_INTERMEDIARY_ENDPOINT as string);
  }

  extractQuantitySelectionListValues = (
    availableQuantities:
      | ProductIntermediaryResponse["availableQuantities"]
      | undefined,
  ) => {
    if (
      availableQuantities &&
      availableQuantities.list &&
      availableQuantities.list.length > 0
    )
      return availableQuantities.list.map((value) => value.toString());
    else return [];
  };

  async getAvailableQuantities(
    productKey: string,
    productVersion: number,
    market: string,
    selectedOptions: Record<string, any>,
  ): Promise<Array<string> | undefined> {
    try {
      const response = await this.axios.get<ProductIntermediaryResponse>(
        `products/${productKey}/${productVersion}/quantities/merchandising`,
        {
          params: {
            merchant: this.tenantConfig.productIntermediary.tenant,
            market,
            selectedOptions,
            requestor: this.tenantConfig.requestor,
          },
        },
      );
      const { availableQuantities } = response.data;
      return this.extractQuantitySelectionListValues(availableQuantities);
    } catch (err) {
      console.error(
        `unable to retrieve Product Intermediary quantities for ${productKey} with product version ${productVersion} in market ${market}`,
      );
      return undefined;
    }
  }
}

const createProductIntermediaryClient = (): IProductIntermediaryClient => {
  return new ProductIntermediaryClient();
};

export default createProductIntermediaryClient;
