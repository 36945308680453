import { getCurrentCulture, getCurrentCountry } from "@vp/culture-resolution";
import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import qs from "qs";
import merge from "lodash.merge";

import {
  isLocalHost,
  isProdBackendDomain,
  isSandboxBackendDomain,
} from "./EnvironmentHelpers";

const LOCALHOST_LANGUAGE_CODE = "en";
const LOCALHOST_COUNTRY_CODE = "US";

/**
 * Function to get the current culture
 *
 * @returns The culture parsed from query strings or based on @vp/culture-resolution.
 */
export const getCulture = (): string => {
  if (isLocalHost() || isProdBackendDomain() || isSandboxBackendDomain()) {
    const url = encodeURI(window.location.search.substring(1));
    const queryParams = qs.parse(url);

    if (queryParams.locale) {
      return queryParams.locale.toString();
    }

    return getCultureFromPathForBackend();
  }

  // culture-resolution will throw a ton of warnings on localhost
  return getCurrentCulture();
};

export const getCountry = (): string => {
  if (isLocalHost() || isProdBackendDomain() || isSandboxBackendDomain()) {
    const url = encodeURI(window.location.search.substring(1));
    const queryParams = qs.parse(url);

    if (queryParams.locale) {
      var groups = /^\w{2}-(?<country>\w{2})$/.exec(
        queryParams.locale as string,
      )?.groups;
      if (groups) {
        return groups.country;
      }
    }

    return LOCALHOST_COUNTRY_CODE;
  }

  return getCurrentCountry();
};

/**
 * Function to initialize the global i18next instance with given locale and corresponding resources.
 *
 * @param {Resource[]} translationResources Translation resource objects to initialize i18next.
 * @param {string} locale Locale to use for translation.
 * @returns i18next instance.
 */
export const initialize = (
  translationResources: Resource[],
  locale: string,
) => {
  const resources = merge({}, ...translationResources);
  return i18n.use(initReactI18next).init({
    //configuration options https://www.i18next.com/overview/configuration-options
    resources,
    lng: locale,
    fallbackLng: {
      "en-ie": ["en-gb", "en-us"],
      "en-gb": ["en-us"],
      "fr-be": ["fr-fr", "en-us"],
      "fr-ca": ["fr-fr", "en-us"],
      "fr-ch": ["fr-fr", "en-us"],
      "nl-be": ["nl-nl", "en-us"],
      "de-at": ["de-de", "en-us"],
      "de-ch": ["de-de", "en-us"],
      "it-ch": ["it-it", "en-us"],
      "en-au": ["en-gb", "en-us"],
      "en-ca": ["en-us"],
      "es-us": ["en-us"],
      "en-in": ["en-gb"],
      default: ["en-us"],
    },
    debug: isLocalHost(), //when run on localhost logs info level to console output
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lowerCaseLng: true, //locale will be fully lowercase eg. en-US --> en-us
  });
};

/**
 * Function to get the current culture from the path for backends and localhost.
 *
 * @returns The locale parsed from query strings.
 */
export const getCultureFromPathForBackend = (): string => {
  var pathname =
    (typeof window !== "undefined" || undefined) &&
    window.location &&
    window.location.pathname;
  if (!pathname) {
    return `${LOCALHOST_LANGUAGE_CODE}-${LOCALHOST_COUNTRY_CODE}`;
  }

  var cultureInPath = pathname.toLowerCase().split("/")[1];
  switch (cultureInPath) {
    case "fr":
    case "es":
      return `${cultureInPath}-${LOCALHOST_COUNTRY_CODE}`;
    default:
      return `${LOCALHOST_LANGUAGE_CODE}-${LOCALHOST_COUNTRY_CODE}`;
  }
};

/**
 * Function to get the language path segment based on the culture.
 *
 * @param {string} culture current culture for getting the language path segment.
 * @returns The path for multi-language locale.
 */
export const getPathFromCulture = (culture: string): string => {
  switch (culture.toLowerCase()) {
    case "fr-be":
    case "fr-ca":
    case "fr-ch":
      return "/fr";
    case "es-us":
      return "/es";
    case "it-ch":
      return "/it";
    default:
      return "";
  }
};
