import React from "react";
import { useTranslation } from "react-i18next";
import { FormError } from "@vp/swan";
import { ERROR_TYPE } from "../../../../../commons/constants";

interface ErrorMessageProps {
  type: ERROR_TYPE;
  lowStockMessage?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  type,
  lowStockMessage,
}) => {
  const { t } = useTranslation("translation");
  let errorMessage;
  switch (type) {
    case ERROR_TYPE.SIZE_UNAVAILABLE:
      errorMessage = "";
      break;
    case ERROR_TYPE.INSUFFICIENT_STOCK:
      if (lowStockMessage) errorMessage = lowStockMessage;
      break;
    case ERROR_TYPE.SIZE_UNSPECIFIED:
      errorMessage = t("groups-and-teams.size-unspecified");
      break;
    case ERROR_TYPE.QUANTITY_REQUIRED:
      errorMessage = t("groups-and-teams.quantity-required");
      break;
    case ERROR_TYPE.INVALID:
    default:
      errorMessage = t("groups-and-teams.field-invalid");
  }
  return <FormError mt={2}>{errorMessage}</FormError>;
};
