import React, { useState, useEffect, ReactNode } from "react";
import TrackingJS from "@vp/react-tracking";
import useTenantConfig from "../../../../hooks/useTenantConfig";
import { TrackingConfiguration } from "../../../../types/TrackingConfiguration";
import { getCulture } from "../../../../utils/LocalizationHelpers";

interface TrackingConfig {
  isTrackingReady: boolean;
  getTrackingConfiguration: () => TrackingConfiguration;
  setTrackingConfiguration: (config: TrackingConfiguration) => void;
}

const emptyTrackingConfig = {} as TrackingConfiguration;

export const TrackingContext = React.createContext({} as TrackingConfig);

const TrackingContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] =
    useState<TrackingConfiguration>(emptyTrackingConfig);
  const [isReactTrackingReady, setIsReactTrackingReady] = useState(false);
  const [isTrackingReady, setIsTrackingReady] = useState(false);
  const { tenantConfig } = useTenantConfig();

  useEffect(() => {
    const onTrackingReady = () => {
      window.removeEventListener("trackingReady", onTrackingReady);
      setIsReactTrackingReady(true);
    };

    window.addEventListener("trackingReady", onTrackingReady);
  }, []);

  useEffect(() => {
    // empty object comparison to be checked
    if (isReactTrackingReady && config !== emptyTrackingConfig) {
      setIsTrackingReady(true);
    }
  }, [isReactTrackingReady, config]);

  return (
    <TrackingContext.Provider
      value={{
        isTrackingReady,
        getTrackingConfiguration: () => {
          return config;
        },
        setTrackingConfiguration: (config: TrackingConfiguration) => {
          setConfig(config);
        },
      }}
    >
      {!isReactTrackingReady && tenantConfig && (
        <TrackingJS
          tenant={tenantConfig.tracking.tenant}
          culture={getCulture()}
          environment={process.env.REACT_APP_TRACKING_ENV as string}
          integrations={{ All: true }}
          suppressReloadOnConsentSubmit={true}
        />
      )}
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingContextProvider;
