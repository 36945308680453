import { useTranslation } from "react-i18next";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  FlexBox,
} from "@vp/swan";
import { Placeholder } from "../../../../types/GroupsAndTeams";
import { SizeData } from "../../../../types/TeamDetailsConfig";

interface IExamplePlaceholdersTable {
  placeholders: Placeholder[];
  isSizedGood: boolean;
  sizes: SizeData[];
  quantityOptions: string[];
  minimumOrderQuantity: number;
}
export const ExamplePlaceHoldersTable: React.FC<IExamplePlaceholdersTable> = ({
  placeholders,
  isSizedGood,
  sizes,
  quantityOptions,
  minimumOrderQuantity,
}) => {
  const { t } = useTranslation("translation");

  return (
    <FlexBox className="teams-example-table">
      <Table>
        <TableHead>
          <TableRow>
            {placeholders.map((placeholder) => {
              return (
                <TableCell width="fit-content" key={placeholder.name}>
                  {placeholder.name}
                </TableCell>
              );
            })}
            {isSizedGood && (
              <TableCell>{t("groups-and-teams.size-label")}</TableCell>
            )}
            <TableCell>{t("groups-and-teams.quantity-label")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {placeholders.map((placeholder) => {
              // Remove numbers, white spaces, and dashes
              const placeholderName = placeholder.name
                .toLowerCase()
                .replace(/\s?-?\d+/, "");
              return (
                <TableCell key={`any ${placeholderName} 1`}>
                  <Typography fontSkin="body-small">
                    {placeholderName === "number"
                      ? "12"
                      : t(`upload.any-${placeholderName}-cell.value`)}
                  </Typography>
                </TableCell>
              );
            })}
            {isSizedGood && (
              <TableCell>
                <Typography fontSkin="body-small">{sizes[0].name}</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography fontSkin="body-small">
                {quantityOptions.length > 0
                  ? quantityOptions[0]
                  : minimumOrderQuantity}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            {placeholders.map((placeholder) => {
              // Remove numbers, white spaces, and dashes
              const placeholderName = placeholder.name
                .toLowerCase()
                .replace(/\s?-?\d+/, "");
              return (
                <TableCell key={`any ${placeholderName} 2`}>
                  <Typography fontSkin="body-small">
                    {placeholderName === "number"
                      ? "34"
                      : t(`upload.any-${placeholderName}-cell.value`)}
                  </Typography>
                </TableCell>
              );
            })}
            {isSizedGood && (
              <TableCell>
                <Typography fontSkin="body-small">
                  {sizes[sizes.length > 1 ? 1 : 0].name}
                </Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography fontSkin="body-small">
                {quantityOptions.length > 0
                  ? quantityOptions[quantityOptions.length > 1 ? 1 : 0]
                  : minimumOrderQuantity + 1}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </FlexBox>
  );
};
