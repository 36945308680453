import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlexBox, Link, Button } from "@vp/swan";
import AddToCartButton from "./AddToCartButton";
import BackToEditDesignLink from "./BackToEditDesignLink";
import { ReviewContext } from "../context/ReviewContext";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { useQuantity } from "../../../root/contexts/QuantityContext";
import { useTracking } from "../../../root/contexts/TrackingContext";
import { useBffData, useStartingAtPrice } from "../../../../hooks/queries";
import { TrackingLabel } from "../../../../types/TrackingConfiguration";

export const ReviewButtons: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const {
    setIsReviewPanelOpen,
    isUpdatingTeam,
    isValidGroupInput,
    isYsdDesign,
  } = useContext(GroupsAndTeamsContext);
  const { isApproved } = useContext(ReviewContext);
  const { teamsQuantity } = useQuantity();
  const { data: bffData } = useBffData();
  const { trackAddToCart } = useTracking();

  // Using starting at price for currency since it is more constant than the quote price
  // We do not expect currency to change within a session
  const { data: startingAtPrice } = useStartingAtPrice();

  const { work, mpvId, productKey, productVersion, productName } = bffData!;
  const { currency } = startingAtPrice!;

  const runTracking = () => {
    trackAddToCart(
      TrackingLabel.ADD_TO_CART,
      productKey,
      productVersion,
      work.merchandising.merchandisingSelections, // should this be the completed attributes or customer selected attributes
      mpvId,
      productName,
      teamsQuantity,
      currency,
    );
  };

  return (
    <>
      <FlexBox className="review-buttons-container">
        <AddToCartButton
          disabled={!isApproved || isUpdatingTeam || !isValidGroupInput}
          runTracking={() => runTracking()}
        />
        <BackToEditDesignLink
          linkText={t("edit-design")}
          renderAs="button"
          skin="secondary"
        />
      </FlexBox>
      {!isUpdatingTeam && (
        <Link
          mt={5}
          render={(p) => (
            <Button
              skin="link"
              className={p.className}
              onClick={() => setIsReviewPanelOpen(false)}
            >
              {p.children}
            </Button>
          )}
        >
          {isYsdDesign
            ? t("groups-and-teams.edit-team-details")
            : t("groups-and-teams.edit-group-details")}
        </Link>
      )}
    </>
  );
};

export default ReviewButtons;
