import React from "react";
import ConsentManager from "../consent-manager";
import PropTypes from "prop-types";
import { isConsentRequired } from "../consent-manager-builder/consent-required";
export { Mode } from "../constants";

const ConsentManagerContainer = (props) => {
  const container = "consent-manager-container";

  const { otherWriteKeys = [], hideBanner = false } = props;

  return (
    <div id={container}>
      <ConsentManager
        {...props}
        otherWriteKeys={otherWriteKeys}
        hideBanner={hideBanner}
        shouldRequireConsent={() => isConsentRequired(props.locale)}
        container={container}
      />
    </div>
  );
};

ConsentManagerContainer.propTypes = {
  writeKey: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  otherWriteKeys: PropTypes.arrayOf(PropTypes.string),
  cookieDomain: PropTypes.string,
  onError: PropTypes.func,
  defaultVariation: PropTypes.string,
  suppressReload: PropTypes.bool,
  mode: PropTypes.string,
  data: PropTypes.object,
  onAcceptAll: PropTypes.func,
  onSave: PropTypes.func,
  hideBanner: PropTypes.bool,
};

export default ConsentManagerContainer;
