import React, { useContext, useEffect, useState } from "react";
import { FlexBox, SkeletonText, SkeletonTextLine } from "@vp/swan";
import {
  IQuotePrice,
  PriceSummaryFromQuote,
} from "@vp/lat-react-component-library";
import { usePricingContext } from "../../../root/contexts/PricingContext";
import { useAppState } from "../../../root/contexts/AppContext";
import {
  useBffData,
  useProductIntermediary,
  useQuotePrice,
  useStartingAtPrice,
} from "../../../../hooks/queries";
import useTenantConfig from "../../../../hooks/useTenantConfig";
import useDebounce from "../../../../hooks/useDebounce";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { BaseGroupsAndTeamsMember } from "../../../../types/GroupsAndTeams";
import { useQuantity } from "../../../root/contexts/QuantityContext";

export const PricingSummary: React.FC<{}> = () => {
  const [isPriceChangeLoading, setIsPriceChangeLoading] = useState(false);
  const { culture } = useAppState();
  const { tenantConfig } = useTenantConfig();
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { minimumOrderQuantity } = bffData!;
  const { isFetched: quantityIsFetched } = useProductIntermediary();
  const { isPricingContextInitialized, pricingContextModule } =
    usePricingContext();
  const { validGroupsAndTeamsMembersForPricing } = useContext(
    GroupsAndTeamsContext,
  );
  const { totalQuantity } = useQuantity();
  const debouncedTotalQuantity = useDebounce(totalQuantity, 500);
  const debouncedGroupsAndTeamsMembersForPricing = useDebounce(
    validGroupsAndTeamsMembersForPricing,
    500,
  );
  const {
    data: productQuotePrice,
    isLoading: isLoadingQuotePrice,
    isFetched: quotePriceIsFetched,
  } = useQuotePrice(
    debouncedGroupsAndTeamsMembersForPricing as BaseGroupsAndTeamsMember[],
  );
  const {
    data: startingAtPrice,
    isLoading: isLoadingStartingAtPrice,
    isFetched: startingAtPriceIsFetched,
  } = useStartingAtPrice();

  const isLoading =
    !tenantConfig ||
    !productQuotePrice ||
    isLoadingStartingAtPrice ||
    !isPricingContextInitialized ||
    !bffDataIsFetched ||
    !bffData ||
    !quantityIsFetched ||
    !quotePriceIsFetched ||
    !startingAtPriceIsFetched;

  useEffect(() => {
    if (
      isLoadingQuotePrice ||
      debouncedGroupsAndTeamsMembersForPricing !==
        validGroupsAndTeamsMembersForPricing ||
      debouncedTotalQuantity !== totalQuantity
    ) {
      setIsPriceChangeLoading(true);
    } else {
      setIsPriceChangeLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingQuotePrice,
    debouncedGroupsAndTeamsMembersForPricing,
    debouncedTotalQuantity,
    totalQuantity,
  ]);

  return (
    <>
      <FlexBox className="pricing-summary" py={1} aria-live="polite">
        {!isLoading && !isPriceChangeLoading ? (
          <PriceSummaryFromQuote
            quotePrice={productQuotePrice}
            moqQuotePrice={startingAtPrice as IQuotePrice}
            quantity={totalQuantity}
            culture={culture}
            isVatInclusive={pricingContextModule.vatInclusive}
            merchantId={
              pricingContextModule.merchantId ?? tenantConfig.pricing.tenant
            }
            minimumOrderQuantity={minimumOrderQuantity}
          />
        ) : (
          <SkeletonText style={{ width: "200px" }}>
            <SkeletonTextLine fontSize="large"></SkeletonTextLine>
            <SkeletonTextLine></SkeletonTextLine>
          </SkeletonText>
        )}
      </FlexBox>
    </>
  );
};
