import React, { ReactNode, useEffect, useState } from "react";

import GroupsAndTeamsContextProvider from "../groupsAndTeams/context/GroupsAndTeamsContext";
import ReviewContextProvider from "./review/context/ReviewContext";
import GroupFormContextProvider from "../groupsAndTeams/configuration/context/GroupFormContext";
import { QuantityContextProvider } from "../root/contexts/QuantityContext";
import { ExcelUploadContextProvider } from "../root/contexts/ExcelUploadContext";
import { ZoomableImageABProvider } from "../root/contexts/Experiments/ZoomableImage";

import { TeamDetails } from "./configuration/TeamDetails";
import TeamDetailsHeader from "./configuration/header/TeamDetailsHeader";
import { TeamDetailsFooter } from "./configuration/footer/TeamDetailsFooter";
import { useTracking } from "../root/contexts/TrackingContext";
import { useBffData, useMerchantAvailability } from "../../hooks/queries";
import { TrackingLabel } from "../../types/TrackingConfiguration";

import "./teams.scss";

const GroupsAndTeams: React.FC<{}> = () => {
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { isFetched: inventoryIsFetched } = useMerchantAvailability();
  const [isProductViewedTracked, setIsProductViewedTracked] = useState(false);
  const { isTrackingReady, trackProductViewed } = useTracking();

  const isLoading = !bffDataIsFetched || !inventoryIsFetched;

  useEffect(() => {
    if (isProductViewedTracked) return;

    if (isTrackingReady && !isLoading) {
      const { mpvId, productName, productKey, productVersion } = bffData!;
      trackProductViewed(
        TrackingLabel.TEAM_DETAILS_PAGE,
        mpvId,
        productName,
        productKey,
        productVersion,
      );
      setIsProductViewedTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackingReady, isLoading]);

  return (
    <QuantityContextProvider>
      <GroupsAndTeamsContextProvider>
        <ExcelUploadContextProvider>
          <GroupFormContextProvider>
            <ReviewContextProvider>
              <ZoomableImageABProvider>
                <TeamDetailsHeader />
                <TeamDetails />
                <TeamDetailsFooter />
              </ZoomableImageABProvider>
            </ReviewContextProvider>
          </GroupFormContextProvider>
        </ExcelUploadContextProvider>
      </GroupsAndTeamsContextProvider>
    </QuantityContextProvider>
  );
};

const GroupsAndTeamsWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isFetched: bffDataIsFetched } = useBffData();
  return <>{bffDataIsFetched && <GroupsAndTeams />}</>;
};

export default GroupsAndTeamsWrapper;
