import BaseAxiosClient from "./BaseAxiosClient";
import { NewWork, Work } from "../types/Work";
import { AxiosResponse } from "axios";

export interface IWorkEntityClient {
  getWorks: () => Promise<Work[]>;
  getWork: (workId: string) => Promise<Work>;
  insertWork: (
    work: NewWork,
    isCareAgent: boolean,
    ownerId: string,
  ) => Promise<Work>;
  saveWork: (
    work: Work,
    isCareAgent: boolean,
    ownerId: string,
  ) => Promise<Work>;
}

class WorkEntityClient extends BaseAxiosClient implements IWorkEntityClient {
  constructor(authHeader: string) {
    super(process.env.REACT_APP_WES_ENDPOINT as string, authHeader);
  }

  async getWorks(): Promise<Work[]> {
    let response: AxiosResponse<Work[]>;
    try {
      response = await this.axios.get<Work[]>("", {
        params: {
          from: this.tenantConfig.requestor,
          tenant: this.tenantConfig.workEntityService.tenant,
        },
      });
    } catch (err) {
      console.error(`Unable to retrieve works.`);
      throw err;
    }

    return response.data;
  }

  async getWork(workId: string): Promise<Work> {
    if (!workId) throw Error("no work id");

    let response;
    try {
      response = await this.axios.get<Work>(`${workId}`, {
        params: {
          from: this.tenantConfig.requestor,
          tenant: this.tenantConfig.workEntityService.tenant,
        },
      });
    } catch (err) {
      console.error(`Unable to retrieve work with id ${workId}.`);
      throw err;
    }

    return response.data;
  }

  async insertWork(
    work: NewWork,
    isCareAgent: boolean,
    ownerId: string,
  ): Promise<Work> {
    try {
      const response = await this.axios.post<Work>(``, work, {
        params: {
          ownerId: isCareAgent ? ownerId : undefined, // The id of the customer who made the original design
          tenant: this.tenantConfig.workEntityService.tenant,
          from: this.tenantConfig.requestor,
        },
      });

      return response.data;
    } catch (err) {
      console.error(`Unable to save work in WES: ${err}`);
      throw err;
    }
  }

  async saveWork(
    work: Work,
    isCareAgent: boolean,
    ownerId: string,
  ): Promise<Work> {
    try {
      const response = await this.axios.post<Work>(
        `${work.workId}/update`,
        work,
        {
          params: {
            ownerId: isCareAgent ? ownerId : undefined, // The id of the customer who made the original design
            tenant: this.tenantConfig.workEntityService.tenant,
            from: this.tenantConfig.requestor,
          },
        },
      );

      return response.data;
    } catch (err) {
      console.error(`Unable to save work in WES: ${err}`);
      throw err;
    }
  }
}

const createWorkEntityClient = (authHeader: string): IWorkEntityClient => {
  /**
   * Create client factory allows for easier testing
   */
  return new WorkEntityClient(authHeader);
};

export default createWorkEntityClient;
