import { IQuotePrice } from "@vp/lat-react-component-library";
import { BaseGroupsAndTeamsMember } from "../types/GroupsAndTeams";
import { PricingItemsInput, PricingSelections } from "../types/WebsitePricing";

export const DEFAULT_CORRELATION_ID = "group1_item1";
export const DEFAULT_PRICING_ERROR_CODE = "PriceNotFound";

export const buildWebsitePricingQuoteRequest = (
  productKey: string,
  productVersion: number,
  market: string,
  merchantId: string,
  couponCode: string | undefined,
  customerGroups: string[],
  groupsAndTeamsMembersForPricing: BaseGroupsAndTeamsMember[],
  lowestPriceAttributes: Record<string, string>,
  minimumOrderQuantity?: number,
) => {
  return {
    excludeCartDiscounts: true,
    itemGroups: [
      {
        groupCorrelationId: "teamDetailsGroup1",
        items: buildItemsFromTeamMembers(
          productKey,
          productVersion,
          lowestPriceAttributes,
          groupsAndTeamsMembersForPricing,
          minimumOrderQuantity,
        ),
      },
    ],
    couponCode,
    market,
    merchantId,
    customerGroups,
    billingAddress: {
      countryCode: market,
    },
    shippingAddress: {
      countryCode: market,
    },
  };
};

export const buildItemsFromTeamMembers = (
  productKey: string,
  productVersion: number,
  lowestPriceAttributes: Record<string, string>,
  basePricingGroupsAndTeamsMembers: BaseGroupsAndTeamsMember[],
  minimumOrderQuantity?: number,
): PricingItemsInput[] => {
  if (basePricingGroupsAndTeamsMembers.length === 0) {
    return [
      {
        productKey,
        productVersion,
        correlationId: DEFAULT_CORRELATION_ID,
        quantity: minimumOrderQuantity ?? 1,
        selections: mapOptionsToPricingSelections(lowestPriceAttributes),
      },
    ];
  }
  const mappedItemsForPricing = basePricingGroupsAndTeamsMembers.map(
    (teamMember: BaseGroupsAndTeamsMember) => {
      return {
        productKey,
        productVersion,
        correlationId: teamMember.id,
        quantity: teamMember.qty,
        selections: buildAndCombinePricingSelections(
          teamMember.selectedOptions,
          lowestPriceAttributes,
        ),
      };
    },
  );

  return mappedItemsForPricing;
};

export const buildAndCombinePricingSelections = (
  selectedOptions: Record<string, string>,
  lowestPriceAttributes: Record<string, string>,
): PricingSelections[] => {
  let combinedSelections: Record<string, string> = {};
  Object.keys(lowestPriceAttributes).forEach((lowestPriceAttrKey: string) => {
    if (Object.keys(selectedOptions).includes(lowestPriceAttrKey)) {
      // Use the explicitly selected option if it exists, particularly important for size
      combinedSelections = {
        ...combinedSelections,
        [lowestPriceAttrKey]: selectedOptions[lowestPriceAttrKey],
      };
    } else {
      combinedSelections = {
        ...combinedSelections,
        [lowestPriceAttrKey]: lowestPriceAttributes[lowestPriceAttrKey],
      };
    }
  });
  return mapOptionsToPricingSelections(combinedSelections);
};

export const mapOptionsToPricingSelections = (
  options: Record<string, string>,
): PricingSelections[] => {
  const pricingSelections: PricingSelections[] = [];
  Object.keys(options).forEach((optionKey: string) => {
    pricingSelections.push({
      name: optionKey,
      value: options[optionKey],
    });
  });

  return pricingSelections;
};

export const getIsPriceInvalid = (quotePrice: IQuotePrice) => {
  // As shown in quote price request builder, we can assume there is only one itemGroup for the price
  if (
    quotePrice?.itemGroups?.[0]?.totalListPriceCents?.taxed &&
    quotePrice?.itemGroups?.[0]?.totalListPriceCents?.untaxed
  ) {
    // price is valid if list price is available
    return false;
  }
  return true;
};

export const getPriceStatusCode = (quotePrice: IQuotePrice) => {
  const uniqueErrorCodes = [] as string[];
  // As shown in quote price request builder, we can assume there is only one itemGroup for the price
  quotePrice?.itemGroups?.[0]?.items?.forEach((item) => {
    if (item.statusCode && !uniqueErrorCodes.includes(item.statusCode)) {
      uniqueErrorCodes.push(item.statusCode);
    }
  });

  return resolveToSingleErrorStatus(uniqueErrorCodes);
};

export const resolveToSingleErrorStatus = (statusCodes: string[]) => {
  if (!statusCodes || statusCodes?.length === 0 || statusCodes?.length > 1) {
    return DEFAULT_PRICING_ERROR_CODE;
  }

  return statusCodes[0];
};
