import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@vp/swan";
import { useAppState } from "../../../root/contexts/AppContext";
import { useIdentityContext } from "../../../root/contexts/IdentityContext";
import { useBffData } from "../../../../hooks/queries";
import { onEditDesignClick } from "../../../../utils/EditDesignHelpers";

const EditDesignLink: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const { isCareAgent, editSource } = useAppState();
  const { auth } = useIdentityContext();
  const { data: bffData } = useBffData();
  const { work } = bffData!;

  const [studioUrl, setStudioUrl] = useState<string>("");

  useEffect(() => {
    let hasResolvedStudioUrl = false;
    const getStudioUrl = async () => {
      const url = await onEditDesignClick(work, auth, isCareAgent, editSource);

      if (!hasResolvedStudioUrl) {
        setStudioUrl(url);
      }
    };

    if (auth && work) {
      getStudioUrl();
    }

    return () => {
      hasResolvedStudioUrl = true;
    };
  }, [auth, editSource, isCareAgent, work]);

  return (
    <Link href={studioUrl} mt="between-actions" fontSkin="body-standard">
      {t("edit-design")}
    </Link>
  );
};

export default EditDesignLink;
