import BaseAxiosClient from "./BaseAxiosClient";
import type {
  NewUdsDocument,
  PostDocumentResponse,
  UdsDocument,
} from "../types/UdsDocument";
import type { AxiosResponse } from "axios";

export interface IUdsClient {
  getDocumentByUrl(documentUrl: string): Promise<UdsDocument>;
  postDocument(document: NewUdsDocument): Promise<PostDocumentResponse>;
}

class UdsClient extends BaseAxiosClient implements IUdsClient {
  constructor(authHeader: string) {
    super(process.env.REACT_APP_UDS_ENDPOINT as string, authHeader);
  }

  async getDocumentByUrl(documentUrl: string): Promise<UdsDocument> {
    let res: AxiosResponse<UdsDocument>;
    try {
      res = await this.axios.get<UdsDocument>(documentUrl);
    } catch (err) {
      throw new Error(
        `Unable to retrieve document from UDS with url ${documentUrl}`,
      );
    }

    return res.data;
  }

  async postDocument(document: NewUdsDocument): Promise<PostDocumentResponse> {
    let res: AxiosResponse<PostDocumentResponse>;
    try {
      res = await this.axios.post<PostDocumentResponse>(
        "v3/documents",
        document,
      );
    } catch (err) {
      throw new Error(`Unable to save document to UDS`);
    }

    return res.data;
  }
}

const createUdsClient = (authHeader: string) => {
  return new UdsClient(authHeader);
};

export default createUdsClient;
