import i18next from "i18next";

async function init(locale, tenant, rawResources) {
  const { resources, formattedLocale } = getLocaleResources(
    locale,
    tenant,
    rawResources,
  );

  const tenantName = resources[formattedLocale].translation.tenant;

  await i18next.init({
    resources,
    fallbackLng: "en",
    interpolation: {
      defaultVariables: {
        tenant: tenantName,
      },
    },
  });

  await i18next.changeLanguage(formattedLocale);
}

function getLocaleResources(locale, tenant, rawResources) {
  const resources = {
    en: getDefaultResources(tenant),
  };

  let formattedLocale = locale;

  if (rawResources) {
    formattedLocale = rawResources.locale;
    resources[formattedLocale] = rawResources;
  } else {
    resources[locale] = {
      translation: {
        tenant,
      },
    };
  }

  return { resources, formattedLocale };
}

function getDefaultResources(tenant) {
  const translations = require("./default_en.json");
  translations.tenant = tenant;
  return {
    translation: translations,
    urls: {
      privacyAndCookiePolicy: "/privacy-and-cookie-policy",
    },
  };
}

function t(key, data) {
  return i18next.t(key, data);
}

function tUrl(urlKey) {
  return i18next.t(urlKey, { ns: "urls" });
}

function exists(key) {
  return i18next.exists(key);
}

export default { t, tUrl, init, exists };
