import React, { ReactNode, useEffect, useState } from "react";

import pricingContextModuleHelpers from "@vp/pricing-context-module/helpers";

interface PricingContextInterface {
  isInitialized: boolean;
  pricingContextModule: PricingContextModule;
}

interface PricingContextProviderProps {
  tenant: string;
  market: string;
  children: ReactNode;
}

interface PricingContextModule {
  version: number;
  couponCode?: string;
  merchantId: string;
  market: string;
  expiresAt: string;
  customerGroups: string[];
  developmentMode: boolean;
  shopperId: string;
  vatInclusive: boolean;
}

export const PricingContext = React.createContext(
  {} as PricingContextInterface,
);

export const PricingContextProvider: React.FC<PricingContextProviderProps> = ({
  tenant,
  market,
  children,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [pricingContextModule, setPricingContextModule] =
    useState<PricingContextModule>({} as PricingContextModule);

  const onInitialized = (pricingContext: PricingContextModule) => {
    setIsInitialized(true);
    setPricingContextModule(pricingContext);
  };

  useEffect(() => {
    pricingContextModuleHelpers.initializePricingContext(
      {
        isDevelopmentMode: process.env.NODE_ENV === "development",
        market: market,
        merchantId: tenant,
      },
      onInitialized,
    );
    pricingContextModuleHelpers.executeAfterPricingContextEvent(
      pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
      (pricingContext) => {
        setPricingContextModule(pricingContext as PricingContextModule);
        // updated pricing context is passed to this callback
        // do things after vat inclusivity change event
      },
      true,
    ); // pass true to this helper to keep the event listener active, passing nothing or false will remove the event listener after first callback execution.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PricingContext.Provider value={{ isInitialized, pricingContextModule }}>
      {children}
    </PricingContext.Provider>
  );
};

export default PricingContextProvider;
