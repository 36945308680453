import { useEffect, useState } from "react";
import { useBffData } from "../../hooks/queries";
import { TrackingConfiguration } from "../../types/TrackingConfiguration";
import { useTracking } from "./contexts/TrackingContext";

export default function withTracking(
  PageComponent: any,
  trackingData: TrackingConfiguration,
  isReviewPage?: boolean,
) {
  return () => {
    const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
    const [isPageLoadedTracked, setIsPageLoadedTracked] = useState(false);
    const [isTrackingInitialized, setIsTrackingInitialized] = useState(false);
    const { isTrackingReady, trackPage, setTrackingConfiguration } =
      useTracking();

    useEffect(() => {
      if (bffDataIsFetched && !isTrackingInitialized) {
        const isYsdDesign = !!bffData!.work.design.metadata?.ysdDesignData;
        const designType = isYsdDesign ? "Teamwear" : "Teams";
        const reviewSuffix = isReviewPage ? " Review" : "";
        const pageSection = `Configure - ${designType}${reviewSuffix}`;

        setTrackingConfiguration({
          ...trackingData,
          pageSection: pageSection,
          pageName: `${bffData!.productKey}:${pageSection}`,
        });
        setIsTrackingInitialized(true);
      }
      // eslint-disable-next-line
    }, [bffDataIsFetched, isTrackingInitialized]);

    useEffect(() => {
      if (isTrackingReady && isTrackingInitialized && !isPageLoadedTracked) {
        trackPage();
        setIsPageLoadedTracked(true);
      }
      // eslint-disable-next-line
    }, [isTrackingReady, isTrackingInitialized, isPageLoadedTracked]);

    return <PageComponent />;
  };
}
