import { Typography } from "@vp/swan";
import { useTranslation } from "react-i18next";

const MOQ_TOKEN = "{0}";

export const MoqFooterMessage = (props: { moq: number }) => {
  const { t } = useTranslation();

  const text = t("minimum-quantity-units").replace(
    MOQ_TOKEN,
    props.moq.toString(),
  );

  return (
    <Typography fontSkin="body-standard-bold" textColor="error">
      {text}
    </Typography>
  );
};
