import { useQuery } from "react-query";
import { createMerchantAvailabilityClient } from "../../clients";
import { getCountry } from "../../utils/LocalizationHelpers";
import { useBffData } from ".";
import type { InventoryData } from "../../types/Inventory";
import type { MerchandisingInventory } from "../../types/MerchantAvailability";
import type { SizeData } from "../../types/TeamDetailsConfig";
import { INFINITE_INVENTORY } from "../../commons/constants";
import { useIdentityContext } from "../../components/root/contexts/IdentityContext";
import { STALE_TIME_10_MINUTES } from "./constants";

const initializeInventoryState = async (
  workId: string,
  productKey: string,
  productVersion: number,
  sizes: SizeData[],
  isSizedGood: boolean,
  productInventory: MerchandisingInventory | undefined,
) => {
  try {
    if (!productKey || !productVersion) {
      console.error(
        `Unable to initialize inventory state (WorkId:${workId}, productKey:${productKey}, productVersion:${productVersion}, sizes:${JSON.stringify(sizes)})`,
      );
      return;
    }

    let enabledInventory: Record<string, InventoryData> = {};

    const productInventoryCombinations =
      productInventory?.combinationsInventories;

    let hasNoInventoryInfo = false;
    if (!productInventory) {
      console.warn("No inventory info available for current selected options");
      hasNoInventoryInfo = true;
    }

    if (isSizedGood) {
      sizes.forEach((size) => {
        const sizeAvailability: InventoryData = {
          sizeName: size.name,
          numAvailable: INFINITE_INVENTORY,
          lowStock: false,
        };

        const singleSizeInventory = productInventoryCombinations?.find(
          (inventory) =>
            inventory.attributes.find((attr) => attr.name === "Size")?.value ===
            size.key,
        );

        if (singleSizeInventory) {
          sizeAvailability.numAvailable = singleSizeInventory.inventory;
          sizeAvailability.lowStock =
            singleSizeInventory.inventoryLevel === "Low";
        }

        if (singleSizeInventory || hasNoInventoryInfo) {
          enabledInventory = {
            [size.key]: sizeAvailability,
            ...enabledInventory,
          };
        }
      });
    } else {
      enabledInventory = {
        hardGood: {
          numAvailable: productInventory?.inventory ?? INFINITE_INVENTORY,
          lowStock: productInventory?.inventoryLevel === "Low" || false,
        },
      };
    }
    return enabledInventory;
  } catch (err) {
    //TODO: implement error handling
  }
};

export default function useMerchantAvailability() {
  const country = getCountry();
  const { data: bffData } = useBffData();
  const { auth } = useIdentityContext();
  const {
    workId,
    productKey,
    productVersion,
    sizes,
    isSizedGood,
    lowestPriceAttributes,
    minimumOrderQuantity,
  } = bffData!;
  return useQuery<Record<string, InventoryData> | undefined>(
    ["merch-availability", productKey, productVersion, country, isSizedGood],
    async () => {
      const merchantAvailabilityClient = createMerchantAvailabilityClient(
        auth.getAuthorizationHeader(),
      );
      const { Size, ...selectedAttributes } = lowestPriceAttributes;
      const productInventory: MerchandisingInventory | undefined =
        await merchantAvailabilityClient.getMerchantAvailability(
          productKey,
          productVersion,
          country,
          minimumOrderQuantity,
          selectedAttributes,
          isSizedGood,
        );

      const inventory = initializeInventoryState(
        workId,
        productKey,
        productVersion,
        sizes,
        isSizedGood,
        productInventory,
      );
      return inventory;
    },
    {
      enabled: !!productKey && !!productVersion && !!country,
      staleTime: STALE_TIME_10_MINUTES,
    },
  );
}
