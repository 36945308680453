import React from "react";
import { Link } from "react-router-dom";

// this route is added to prevent App reloading after item
// being automatically added to cart from Studio 5
const NotFound: React.FC<{}> = () => {
  return (
    <>
      <h1>{"404 - Not Found!"}</h1>
      <Link to="/">{"Go Home"}</Link>
    </>
  );
};

export default NotFound;
