import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Column,
  Icon,
  Popover,
  PopoverLauncher,
  PopoverContent,
  Typography,
  ToggleSwitch,
  ToggleSwitchOnLabel,
  ToggleSwitchOffLabel,
  FlexBox,
} from "@vp/swan";
import l10n from "../../localization/localization";
import styles from "../../styles/cookie-message-style.scss";
import { Mode } from "../../constants";

export default class PreferenceCategory extends PureComponent {
  static propTypes = {
    category: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
  };

  render() {
    const { category, handleChange, mode } = this.props;

    const { contentPosition, togglePosition } = this.getPosition(mode);

    const getCategoryId = (category) => `${category.name}CategoryLabel`;

    const showPopover = l10n.exists(
      `preferenceDialogV5.categories.${category.name}.popover`,
    );
    return (
      <Fragment>
        <Row marginBottom="5">
          <Column verticallyCenterContent {...contentPosition}>
            <Row marginBottom="3">
              <Column span={12}>
                <FlexBox
                  justifyContent="left"
                  alignItems="center"
                  flexWrap="wrap"
                  flexDirection="row"
                >
                  <Typography
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="large"
                    id={getCategoryId(category)}
                    component={"span"}
                  >
                    {l10n.t(
                      `preferenceDialogV5.categories.${category.name}.header`,
                    )}
                  </Typography>
                  {showPopover && (
                    <Popover
                      position="right"
                      className={styles.categoryDetailsPopover}
                      paddingLeft={"3"}
                    >
                      <PopoverLauncher>
                        <Icon
                          skin="standard"
                          iconType="info"
                          className={styles.informationIcon}
                        />
                      </PopoverLauncher>
                      <PopoverContent
                        className={styles.categoryDetailsPopoverContent}
                      >
                        {l10n.t(
                          `preferenceDialogV5.categories.${category.name}.popover`,
                        )}
                      </PopoverContent>
                    </Popover>
                  )}
                </FlexBox>
              </Column>
            </Row>
            <Row>
              <Column span={12}>
                {l10n.t(
                  `preferenceDialogV5.categories.${category.name}.content`,
                )}
              </Column>
            </Row>
          </Column>
          <Column {...togglePosition} align="right">
            <ToggleSwitch
              name={category.name}
              className={styles.toggleSwitchDisabled}
              aria-labelledby={getCategoryId(category)}
              onRequestActivatedChange={
                !category.readonly
                  ? (activated) => handleChange(category.name, activated)
                  : () => {}
              }
              activated={category.enabled}
              disabled={category.readonly}
            >
              <ToggleSwitchOnLabel>
                {l10n.t("preferenceDialog.toggleOn")}
              </ToggleSwitchOnLabel>
              <ToggleSwitchOffLabel>
                {l10n.t("preferenceDialog.toggleOff")}
              </ToggleSwitchOffLabel>
            </ToggleSwitch>
          </Column>
        </Row>
      </Fragment>
    );
  }

  getPosition(mode) {
    if (mode === Mode.Embedded) {
      return {
        contentPosition: {
          span: 10,
          spanXs: 8,
        },
        togglePosition: {
          span: 2,
          spanXs: 4,
        },
      };
    }

    return {
      contentPosition: {
        offsetLg: 3,
        spanLg: 5,
        offset: 1,
        span: 9,
        spanXs: 9,
      },
      togglePosition: {
        span: 1,
        spanXs: 2,
      },
    };
  }
}
