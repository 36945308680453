import BaseAxiosClient from "./BaseAxiosClient";

export interface IPurcsClient {
  getPreviewUrls: (
    culture: string,
    productKey: string,
    productVersion: number,
    previewInstructionsUri: string,
    selectedOptions: Record<string, string>,
  ) => Promise<string[]>;
}

class PurcsClient extends BaseAxiosClient implements IPurcsClient {
  constructor() {
    super(process.env.REACT_APP_PURCS_URL as string);
  }

  async getPreviewUrls(
    culture: string,
    productKey: string,
    productVersion: number,
    previewInstructionsUri: string,
    selectedOptions: Record<string, string>,
  ): Promise<string[]> {
    try {
      const response = await this.axios.get(
        `${this.tenantConfig.purcs.tenant}/${culture}/product/${productKey}/allViews`,
        {
          params: {
            productVersion,
            previewInstructionsUri,
            optionSelections: JSON.stringify(selectedOptions),
            requestor: this.tenantConfig.requestor,
            purpose: "design.studio.review",
          },
        },
      );

      const allViews = response.data.views;
      // India products have the previews with view name as decoration location 1.
      // Need to check if decoration location 1 previews are still being returned as it was a problem previously.
      const previewUrls =
        culture.toLowerCase() !== "en-in"
          ? allViews
              .filter(
                (view: Record<string, any>) =>
                  !view.name.toLowerCase().includes("decoration location 1"),
              )
              .map((view: Record<string, any>) => view._links.image.href)
          : allViews.map((view: Record<string, any>) => view._links.image.href);

      return previewUrls;
    } catch (err) {
      console.error(
        `Cannot get previews for productKey ${productKey}, productVersion ${productVersion}, tenant ${this.tenantConfig.purcs.tenant}, locale ${culture}`,
      );
      throw err;
    }
  }
}

const createPurcsClient = () => {
  return new PurcsClient();
};

export default createPurcsClient;
