import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as reader from "@vp/ab-reader";
import impression from "@vp/ab-impression";
import { useBffData } from "../../../../hooks/queries";
import GroupingServiceClient from "../../../../clients/GroupingServiceClient";
import {
  Experiment,
  ActiveExperiments,
  ExperimentVariationKey,
  ExperimentVariations,
} from "../../../../types/Experiments";
import { Purpose } from "../../../../types/GroupingService";

// Experimentation Hub's implementation suggestion uses 1s (1000ms) as an acceptable timeout for reader initialization
const AB_READER_INITIALIZATION_TIMEOUT_MS = 1000;

// Use this variable to force AB variations. See the following Confluence documentation for how to populate this variable:
// https://vistaprint.atlassian.net/wiki/spaces/TAH/pages/3690499300#Forcing-AB-Test-Variation-Assignment-Locally
// NOTE: Calls to trackImpression will not send data to Statsig for variations that are forced.
const forcedVariations: Partial<Record<Experiment, string>> = {};

interface IAbTestContext {
  getVariation: (experimentKey: Experiment) => string | undefined;
  isExperimentActive: (experiment: Experiment) => boolean;
  isExperimentEnabled: (experiment: Experiment) => boolean;
  trackImpression: (experiment: Experiment) => Promise<void>;
}

export const AbTestContext = createContext<IAbTestContext | undefined>(
  undefined,
);

const AbTestProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { Provider } = AbTestContext;

  const { data: BffData } = useBffData();

  const [variationAssignments, setVariationAssignments] = useState<
    Partial<Record<Experiment, ExperimentVariationKey | undefined>> | undefined
  >(undefined);
  const [possibleVariations, setPossibleVariations] = useState<
    Partial<Record<Experiment, ExperimentVariations>>
  >({});

  useEffect(() => {
    const proccessGroupingServiceCalls = async () => {
      const { productKey } = BffData!;
      const groupingServiceClient = new GroupingServiceClient();

      const isInGroup: Partial<Record<Purpose, boolean | undefined>> = {};
      const alreadyCheckedPurposes: Partial<Record<Purpose, boolean>> = {};

      const productCheckedAgainstAllPurposes = ActiveExperiments.map(
        // eslint-disable-next-line array-callback-return
        (experiment) => {
          // Only process experiments that have Grouping Service purposes:
          const purposeKey = experiment.purposeToLimitBy;
          if (purposeKey && !alreadyCheckedPurposes[purposeKey]) {
            // To prevent unnecessary duplicate calls to the Grouping Service, keep track of what purposes we have already checked
            alreadyCheckedPurposes[purposeKey] = true;

            return groupingServiceClient
              .getHighestPriorityGroupWithPurpose(productKey, purposeKey)
              .then((firstPurposeGroup) => {
                isInGroup[purposeKey] = !!(
                  firstPurposeGroup && firstPurposeGroup.groupId
                );
              });
          }
        },
      );

      // Wait for all of the calls to Grouping Service to complete
      await Promise.all(productCheckedAgainstAllPurposes);
      return isInGroup;
    };

    // Since the AB Reader package wraps an HTTP client, we need to wait for initialization.
    const initializeAbReader = async () => {
      // Only continue if there's a valid productKey from the BFF
      if (BffData) {
        reader.initialize();
        reader.whenAvailable(async (available) => {
          if (!available) {
            console.error(
              "AB Reader failed to initialize. Experiment-based rendering is disabled.",
            );
            return;
          }

          // Grouping service calls need to be made before experiment parsing is complete
          const isInExperimentPurposeGroup =
            await proccessGroupingServiceCalls();

          if (!isInExperimentPurposeGroup) {
            console.error(
              "Grouping Service calls failed. Experiment-based rendering is disabled.",
            );
            return;
          }

          const newlyAssignedVariations: Partial<
            Record<Experiment, ExperimentVariationKey | undefined>
          > = {};
          const allPossibleVariations: Partial<
            Record<Experiment, ExperimentVariations>
          > = {};

          // Store all non-placeholder experiment values locally to prevent async calls later
          ActiveExperiments.forEach((experiment) => {
            const { experimentKey, purposeToLimitBy } = experiment;

            // If a purposeName has been provided, we need to make sure the given product is in the specified group before checking for variations. Otherwise, it's always eligible.
            let productIsEligibleForABTest = true;
            if (purposeToLimitBy) {
              productIsEligibleForABTest =
                !!isInExperimentPurposeGroup[purposeToLimitBy];
            }

            if (productIsEligibleForABTest) {
              allPossibleVariations[experiment.experimentKey] =
                experiment.variations;

              const variation = reader.getVariation(experimentKey);
              if (variation) {
                newlyAssignedVariations[experimentKey] =
                  variation as ExperimentVariationKey;
                return;
              }
            }

            // If ab-reader fails to get a variation OR the product isn't eligible for a variation, it should be marked as undefined to mirror how ab-reader responds with getVariation()
            newlyAssignedVariations[experimentKey] = undefined;
          });

          setPossibleVariations(allPossibleVariations);
          setVariationAssignments(newlyAssignedVariations);
        }, AB_READER_INITIALIZATION_TIMEOUT_MS);
      }
    };

    initializeAbReader();
  }, [BffData]);

  const getVariation = (experiment: Experiment) => {
    if (!variationAssignments) {
      console.warn("AB Reader is not ready yet. Returning undefined.");
    }

    return variationAssignments && variationAssignments[experiment];
  };

  /**
   * @returns boolean representing whether the specified experiment is active on Statsig and calls to the ab-reader (and grouping service, if applicable) were successful. (If they weren't, the value will be undefined)
   */
  const isExperimentActive = (experiment: Experiment) => {
    return !!(variationAssignments && !!variationAssignments[experiment]);
  };

  /**
   * @returns boolean representing whether the specified experiment's variation maps to 'Enabled'
   */
  const isExperimentEnabled = (experiment: Experiment) => {
    // Allow forced overried of variations for local testing
    if (forcedVariations[experiment])
      return (
        forcedVariations[experiment] === possibleVariations[experiment]?.Enabled
      );

    if (variationAssignments && variationAssignments[experiment]) {
      return (
        variationAssignments[experiment] ===
        possibleVariations[experiment]?.Enabled
      );
    }

    return false;
  };

  const trackImpression = async (experiment: Experiment) => {
    try {
      // Only track impressions if ab-reader & grouping service calls were successful, the variation values are stored, and the variation is not forced
      if (
        isExperimentActive(experiment) &&
        variationAssignments &&
        !forcedVariations[experiment]
      ) {
        await impression.fireImpression(
          experiment,
          variationAssignments[experiment]!,
        );
      }
    } catch (err) {
      console.error("Failed to fire AB impression");
    }
  };

  const contextValue = useMemo<any>(() => {
    if (variationAssignments) {
      return {
        getVariation,
        isExperimentActive,
        isExperimentEnabled,
        trackImpression,
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variationAssignments]);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default AbTestProvider;
