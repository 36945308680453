import React, { PureComponent } from "react";
import { Link, GridContainer, Row, Column, Typography } from "@vp/swan";
import l10n from "../../localization/localization";
import PreferenceCategory from "./preference-category";
import getUrl from "../get-url";
import { Mode } from "../../constants";

export default class Preference extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { advertising, functional, sessionReplay, mode } = this.props;

    const position = this.getPosition(mode);

    const categories = [
      {
        name: "necessary",
        enabled: true,
        readonly: true,
      },
      {
        name: "functional",
        enabled: functional,
        readonly: false,
      },
      {
        name: "sessionReplay",
        enabled: sessionReplay,
        readonly: false,
      },
      {
        name: "advertising",
        enabled: advertising,
        readonly: false,
      },
    ];

    return (
      <GridContainer marginBottom="7">
        <Row marginBottom={"5"}>
          <Column {...position} align="left">
            {l10n.t("preferenceDialogV5.content")}
          </Column>
        </Row>
        <Row marginBottom={"7"}>
          <Column {...position} align="left">
            <Typography>
              {this.getPolicyTextPart(0)}
              <Link href={this.getPolicyLink()}>
                {l10n.t("preferenceDialogV5.policyLinkText")}
              </Link>
              {this.getPolicyTextPart(1)}
            </Typography>
          </Column>
        </Row>
        {categories.map((category) => (
          <PreferenceCategory
            key={category.name}
            category={category}
            handleChange={this.handleChange}
            mode={mode}
          />
        ))}
      </GridContainer>
    );
  }

  getPosition(mode) {
    if (mode === Mode.Embedded) {
      return {
        span: 12,
      };
    }

    return {
      offsetLg: 3,
      spanLg: 6,
      offset: 1,
      span: 10,
      spanXs: 12,
    };
  }

  getPolicyLink = () => {
    return getUrl(l10n.tUrl("privacyAndCookiePolicy"));
  };

  getPolicyTextPart(part) {
    const policyText = l10n
      .t("preferenceDialogV5.policyText")
      .split("#policy-link#");
    if (policyText.length > part) {
      return policyText[part];
    }

    return "";
  }

  handleChange = (name, activated) => {
    const { onChange } = this.props;

    onChange(name, activated);
  };
}
