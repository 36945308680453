import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Typography,
  GridContainer,
  Row,
  Column,
  Link,
  FlexBox,
} from "@vp/swan";

import l10n from "../localization/localization";
import styles from "../styles/cookie-message-style.scss";
import getUrl from "./get-url";
import MultilineText from "./multiline-text";

export default function BannerControl(props) {
  return (
    <GridContainer
      className={`${styles.cookiePolicyControl} grid-container-proportional consent-manager-banner`}
      backgroundColor="standard"
    >
      <Row marginY="3" marginBottom="5">
        <Column span={12}>
          <Typography
            textAlign="left"
            fontSize="large"
            width="183px"
            fontWeight="bold"
          >
            {getBannerContentTitlePart(0)}
            <br />
            {getBannerContentTitlePart(1)}
          </Typography>
        </Column>
      </Row>
      <Row marginY="3">
        <Column span={12}>
          <Typography textAlign="left">
            {getBannerContentPart(0)}
            <Link href={getPolicyLink()}>
              {l10n.t("bannerV5.policyLinkText")}
            </Link>
            {getBannerContentPart(1)}
          </Typography>
        </Column>
      </Row>
      <Row>
        <Column
          span={12}
          verticallyCenterContent
          paddingTop="3"
          paddingBottom="3"
        >
          <FlexBox flexWrap={"wrap"}>
            <Button
              skin="primary"
              className={styles.textButton}
              title={l10n.t("bannerV5.accept")}
              aria-label={l10n.t("bannerV5.accept")}
              onClick={props.onAccept}
            >
              <MultilineText
                mobileText={l10n.t("bannerV5.acceptMobileText", {
                  returnObjects: true,
                })}
                text={l10n.t("bannerV5.acceptDesktopText", {
                  returnObjects: true,
                })}
              />
            </Button>
            <Link
              className={styles.settingsLink}
              skin="cta"
              title={l10n.t("bannerV5.settings")}
              aria-label={l10n.t("bannerV5.settings")}
              render={(p) => (
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gridColumnGap: "2px",
                  }}
                  skin="link"
                  className={p.className}
                  onClick={props.onChangePreferences}
                >
                  {p.children}
                </Button>
              )}
            >
              <Typography textAlign="left">
                <MultilineText
                  mobileText={l10n.t("bannerV5.settingsMobileText", {
                    returnObjects: true,
                  })}
                  text={l10n.t("bannerV5.settingsDesktopText", {
                    returnObjects: true,
                  })}
                />
              </Typography>
            </Link>
          </FlexBox>
        </Column>
      </Row>
    </GridContainer>
  );
}

function getPolicyLink() {
  return getUrl(l10n.tUrl("privacyAndCookiePolicy"));
}

function getBannerContentPart(part) {
  const policyText = l10n.t("bannerV5.content").split("#policy-link#");
  if (policyText.length > part) {
    return policyText[part];
  }

  return "";
}

function getBannerContentTitlePart(part) {
  const policyText = l10n.t("bannerV5.title").split("#break#");
  if (policyText.length > part) {
    return policyText[part];
  }

  return "";
}

BannerControl.displayName = "Banner";

BannerControl.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onChangePreferences: PropTypes.func.isRequired,
};
