/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TextInput,
  FormField,
  FormLabel,
  FormInputGroup,
  FormHelper,
  FlexBox,
} from "@vp/swan";
import { ErrorMessage } from "../ErrorMessage";
import { InventoryMessage } from "../InventoryMessage";
import type { FieldDefinition } from "../../../../../../types/GroupsAndTeams";
import { useAppState } from "../../../../../root/contexts/AppContext";
import {
  ERROR_TYPE,
  INFINITE_INVENTORY,
  NO_INPUT_SIZE,
} from "../../../../../../commons/constants";
import { GroupsAndTeamsContext } from "../../../../context/GroupsAndTeamsContext";
import {
  getSameSizeGroupsAndTeamsMembers,
  getRemainingStock,
  validateQuantityValue,
} from "../../../../../../utils/validationHelpers";
import { useExcelUpload } from "../../../../../root/contexts/ExcelUploadContext";
import { GroupFormContext } from "../../../context/GroupFormContext";

const QUANTITY_INPUT_LIMIT = INFINITE_INVENTORY;

export type QuantityTextInputFieldProps = {
  fieldDefinition: FieldDefinition;
  defaultValue?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onFocus?: () => void;
  onQuantityChange: (value: string, key: string, isValid: boolean) => void;
  triggerValidation: boolean;
  entryId: string;
  updateTeammateValidity: (key: string, validityData: boolean) => void;
  isSizedGood: boolean;
  sizeName?: string;
  totalStock: number;
  isLowStock: boolean;
  curSizeQuantity: number;
  showsFormLabel: boolean;
  handleSizeDropdownError: () => void;
};

export const QuantityTextInputField: React.FC<QuantityTextInputFieldProps> = ({
  fieldDefinition,
  defaultValue,
  disabled = false,
  autoFocus,
  onFocus,
  onQuantityChange,
  triggerValidation = false,
  entryId,
  updateTeammateValidity,
  isSizedGood,
  sizeName,
  totalStock,
  isLowStock,
  curSizeQuantity,
  showsFormLabel,
  handleSizeDropdownError,
}) => {
  const { debug } = useAppState();
  const { t } = useTranslation("translation");
  const { groupsAndTeamsMembers } = useContext(GroupsAndTeamsContext);
  const { memberFormsToValidate } = useContext(GroupFormContext);

  // This is used to prevent the flickering that happens during upload validation
  const { isFetching: uploadIsFetching } = useExcelUpload();

  const [value, setValue] = useState(defaultValue || "");
  const [errorType, setErrorType] = useState(ERROR_TYPE.NONE);
  const [availableStock, setAvailableStock] = useState(INFINITE_INVENTORY);

  const isQuantityValid = (quantity: string) => {
    const otherTeamsMembers = getSameSizeGroupsAndTeamsMembers(
      groupsAndTeamsMembers,
      entryId,
      sizeName,
    );
    const remainingStock = getRemainingStock(otherTeamsMembers, totalStock);
    return validateQuantityValue(
      [],
      fieldDefinition.validationPattern,
      quantity,
      Math.min(remainingStock, INFINITE_INVENTORY),
    );
  };

  const isQuantityAboveMaxInputValue = (quantity: string) => {
    return parseInt(quantity) > QUANTITY_INPUT_LIMIT;
  };

  const sizeValueIsInvalid =
    isSizedGood && (!sizeName || sizeName === NO_INPUT_SIZE);

  const selectErrorMessage = (
    relativeStock: number,
    bypassTrigger: boolean = false,
  ) => {
    const bypassedValidationTrigger = bypassTrigger || triggerValidation;
    if (relativeStock === 0) setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);
    if (bypassedValidationTrigger && sizeValueIsInvalid) {
      handleSizeDropdownError();
      return;
    }
    if (!sizeValueIsInvalid && isQuantityAboveMaxInputValue(value)) {
      setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);
    }
  };

  useEffect(() => {
    setAvailableStock(totalStock - curSizeQuantity);
  }, [curSizeQuantity, totalStock]);

  useEffect(() => {
    if (availableStock < 0) {
      if (!sizeValueIsInvalid) setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);
      updateTeammateValidity(fieldDefinition.key, false);
    } else {
      selectErrorMessage(availableStock);
      const isValidValue = isQuantityValid(value);
      if (isValidValue) setErrorType(ERROR_TYPE.NONE);
      updateTeammateValidity(fieldDefinition.key, isValidValue);
    }
  }, [availableStock]);

  useEffect(() => {
    if (triggerValidation) {
      selectErrorMessage(totalStock);
    }
  }, [triggerValidation, sizeName, value]);

  useEffect(() => {
    if (defaultValue) {
      const isInputValid = isQuantityValid(defaultValue);

      if (defaultValue !== value) setValue(defaultValue);
      if (totalStock === 0) setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);

      onQuantityChange(defaultValue, fieldDefinition.key, isInputValid);
    } else {
      // Do not set error on initial load if no value is given
      if (value !== "") setValue("");
      setErrorType(ERROR_TYPE.NONE);
      onQuantityChange("", fieldDefinition.key, false);
    }
  }, []);

  const handleInput = (input: string) => {
    if (sizeValueIsInvalid) handleSizeDropdownError();
    else if (!!input.match(new RegExp(fieldDefinition.validationPattern))) {
      const isOverMaxInput = isQuantityAboveMaxInputValue(input);
      const newInputValue = isOverMaxInput
        ? QUANTITY_INPUT_LIMIT.toString()
        : input;
      const isValueValid = isQuantityValid(newInputValue);

      setValue(newInputValue);
      onQuantityChange(newInputValue, fieldDefinition.key, isValueValid);
    } else if (input === "") {
      setValue(input);
      selectErrorMessage(totalStock, true);
      onQuantityChange(input, fieldDefinition.key, false);
    }
  };

  useEffect(() => {
    if (!value && !sizeValueIsInvalid && memberFormsToValidate.includes(entryId)) {
      if (totalStock === 0) setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);
      else setErrorType(ERROR_TYPE.QUANTITY_REQUIRED);
    }
  }, [memberFormsToValidate])

  const handleBlur = () => {
    if (!value) {
      if (sizeValueIsInvalid) handleSizeDropdownError();
      else if (totalStock === 0) setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);
      else setErrorType(ERROR_TYPE.QUANTITY_REQUIRED);
    }
  };

  let lowStockMessage = "";
  const maxInputTotalStock = Math.min(totalStock, QUANTITY_INPUT_LIMIT);
  if (isSizedGood && sizeName && totalStock !== undefined) {
    lowStockMessage = t("groups-and-teams.field-limited-sized-good-stock")
      .replace("{size}", sizeName)
      .replace("{total_stock}", maxInputTotalStock.toString());
  } else if (!isSizedGood && totalStock !== undefined) {
    lowStockMessage = t(
      "groups-and-teams.field-limited-hard-good-stock",
    ).replace("{total_stock}", maxInputTotalStock.toString());
  }

  return (
    <FormField
      data-testid="teams-details-form-panel-quantity-input"
      className={`teams-details-form-field teams-details-quantity-field${
        isSizedGood ? "-size" : "-hard"
      }`}
      marginRight={{ sm: groupsAndTeamsMembers.length > 1 ? 5 : 0 }}
    >
      <FormLabel visuallyHidden={!showsFormLabel}>
        {fieldDefinition.name}
      </FormLabel>
      <FormInputGroup>
        <TextInput
          autoFocus={autoFocus}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInput(event.target.value)
          }
          aria-required={"true"}
          name={fieldDefinition.key}
          disabled={disabled}
          onFocus={onFocus}
          inputMode={"numeric"}
          onBlur={handleBlur}
          id={entryId}
          autoComplete={"off"}
        />
        <FlexBox
          className="teams-details-quantity-warnings"
          flexDirection="column"
          alignItems="flex-end"
        >
          {!uploadIsFetching &&
            isLowStock &&
            errorType !== ERROR_TYPE.INSUFFICIENT_STOCK && (
              <FormHelper mt={2}>{lowStockMessage}</FormHelper>
            )}
          {!uploadIsFetching && errorType !== ERROR_TYPE.NONE && (
            <ErrorMessage type={errorType} lowStockMessage={lowStockMessage} />
          )}
          {!uploadIsFetching && debug && (
            <InventoryMessage availableStock={availableStock} />
          )}
        </FlexBox>
      </FormInputGroup>
    </FormField>
  );
};
