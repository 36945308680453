import qs from "qs";
import BaseAxiosClient from "./BaseAxiosClient";
import type { TeamDetailsConfig } from "../types/TeamDetailsConfig";

export interface IBffClient {
  getTeamDetailsConfig: (
    culture: string,
    productKey: string,
    selectedOptions: Record<string, string>,
    productVersion?: number,
  ) => Promise<TeamDetailsConfig>;
}

class BffClient extends BaseAxiosClient implements IBffClient {
  constructor(authHeader: string) {
    super(
      process.env.REACT_APP_TEAM_DETAILS_BFF_ENDPOINT as string,
      authHeader,
    );
  }

  async getTeamDetailsConfig(
    culture: string,
    productKey: string,
    selectedOptions: Record<string, string>,
    productVersion?: number,
  ): Promise<TeamDetailsConfig> {
    const formattedSelectedOptions = qs.stringify(
      { selectedOptions: selectedOptions },
      { encodeValuesOnly: true },
    );

    let bffUrl = `/teamDetails/${this.tenantConfig.bff.tenant}/${culture}/${productKey}/config`;
    if (formattedSelectedOptions) {
      bffUrl += `?${formattedSelectedOptions}`;
    }

    let response;
    try {
      response = await this.axios.get<TeamDetailsConfig>(bffUrl, {
        params: {
          requestor: this.tenantConfig.requestor,
          productVersion,
        },
        headers: {
          path: bffUrl,
        },
      });
    } catch (err) {
      console.error(
        `Unable to retrieve required quantity selection data from bff for ${productKey}`,
      );
      throw err;
    }

    return response.data;
  }
}

const createBffClient = (authHeader: string): IBffClient => {
  /**
   * Create client factory allows for easier testing
   */
  return new BffClient(authHeader);
};

export default createBffClient;
