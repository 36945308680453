export type TrackingConfiguration = {
  pageName: string;
  pageSection: string;
  pageStage: string;
  pageDept?: string;
};

export enum TrackingCategory {
  FLYOUT = "Fly-Out",
  GROUPS_AND_TEAMS = "Groups & Teams",
}

export enum TrackingLabel {
  UPLOAD = "Excel Upload Fly-Out",
  UPLOAD_CONFIRMATION = "Excel Upload Confirmation Fly-Out",
  UPLOAD_SUCCESS = "Success",
  UPLOAD_ERROR = "Error",
  ADD_TO_CART = "Product Added",
  TEAM_DETAILS_PAGE = "Team Details page view",
  TEAM_DETAILS_REVIEW_PAGE = "Team Details review page view",
}

export enum TrackingEventDetail {
  UPLOAD = "Groups & Teams Excel Upload",
  UPLOAD_SUCCESS_PREFIX = "Upload Excel_Success:",
  UPLOAD_ERROR_PREFIX = "Upload Excel_Error:",
}

export const BASE_TRACKING_CONFIG_ERROR_MESSAGE =
  "You must set the trackingConfiguration before calling {function} function.";
