import React, { ReactNode, useState } from "react";

interface AlertContextInterface {
  error: string;
  setError: (error: string) => void;
  warning: string;
  setWarning: (warning: string) => void;
  success: string;
  setSuccess: (success: string) => void;
  pricingError: string;
  setPricingError: (pricingError: string) => void;
  clearAlerts: () => void;
}

export const AlertContext = React.createContext({} as AlertContextInterface);

const AlertContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [success, setSuccess] = useState("");
  const [pricingError, setPricingError] = useState("");

  const clearAlerts = () => {
    setError("");
    setWarning("");
    setSuccess("");
    setPricingError("");
  };

  return (
    <AlertContext.Provider
      value={{
        error,
        setError,
        warning,
        setWarning,
        success,
        setSuccess,
        pricingError,
        setPricingError,
        clearAlerts,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
