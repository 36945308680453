import { waitTillAvailable, getVariation } from "@vp/ab-reader";

export const getExperimentVariation = async (experimentKey) => {
  const available = await waitTillAvailable(1000);

  if (available) {
    return getVariation(experimentKey);
  }

  return undefined;
};
