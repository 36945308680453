import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { IQuotePrice } from "@vp/lat-react-component-library";
import { getCountry } from "../../utils/LocalizationHelpers";
import { STALE_TIME_10_MINUTES } from "./constants";
import { usePricingContext } from "../../components/root/contexts/PricingContext";
import useTenantConfig from "../useTenantConfig";
import { useIdentityContext } from "../../components/root/contexts/IdentityContext";
import { BaseGroupsAndTeamsMember } from "../../types/GroupsAndTeams";
import useBffData from "./useBffData";
import { createWebsitePricingClient } from "../../clients";
import {
  getIsPriceInvalid,
  getPriceStatusCode,
} from "../../utils/WebsitePricingHelpers";
import { useAlertState } from "../../components/root/contexts/AlertContext";

export default function useQuotePrice(
  groupsAndTeamsMembersForPricing: BaseGroupsAndTeamsMember[],
) {
  const { t } = useTranslation("translation");
  const country = getCountry();
  const { data: bffData } = useBffData();
  const {
    productKey,
    productVersion,
    lowestPriceAttributes,
    minimumOrderQuantity,
  } = bffData!;
  const { pricingContextModule } = usePricingContext();
  const { tenantConfig } = useTenantConfig();
  const { auth } = useIdentityContext();
  const { setPricingError } = useAlertState();

  return useQuery<IQuotePrice | undefined>(
    [
      "quote-price",
      productKey,
      productVersion,
      country,
      lowestPriceAttributes,
      groupsAndTeamsMembersForPricing,
      minimumOrderQuantity,
    ],
    async () => {
      const websitePricingClient = createWebsitePricingClient(
        auth.getAuthorizationHeader(),
      );
      const quotePrice = await websitePricingClient.getPriceQuote(
        productKey,
        productVersion,
        pricingContextModule.market ?? country,
        pricingContextModule.merchantId ?? tenantConfig.pricing.tenant,
        pricingContextModule.couponCode,
        pricingContextModule.customerGroups,
        groupsAndTeamsMembersForPricing,
        lowestPriceAttributes,
        minimumOrderQuantity,
      );

      if (getIsPriceInvalid(quotePrice)) {
        const statusCode = getPriceStatusCode(quotePrice);
        setPricingError(t(`pricingErrorMessages.${statusCode}`));
      }

      return quotePrice;
    },
    {
      enabled:
        !!productKey &&
        !!productVersion &&
        !!country &&
        !!lowestPriceAttributes &&
        !!groupsAndTeamsMembersForPricing,
      staleTime: STALE_TIME_10_MINUTES,
    },
  );
}
