import { useContext } from "react";
import { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import { TrackingContext } from "./TrackingContext";
import { Quantity } from "../../../../types/Inventory";
import {
  TrackingCategory,
  BASE_TRACKING_CONFIG_ERROR_MESSAGE,
} from "../../../../types/TrackingConfiguration";

const useTracking = () => {
  const {
    isTrackingReady,
    getTrackingConfiguration,
    setTrackingConfiguration,
  } = useContext(TrackingContext);

  const trackEvent = (eventType: string, properties: object) => {
    if (isTrackingReady) {
      const tracking = (window as any).tracking;
      tracking.track(eventType, properties);
    } else {
      console.warn(
        `Attempted to track ${eventType} event, but the react-tracking library is unavailable.`,
      );
    }
  };

  const trackPage = (pageType?: string) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace("{function}", "trackPage"),
      );
    }
    // Properties follow Vistaprint IE nomenclature and categorization
    const properties = {
      pageName: trackingConfiguration.pageName,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageDept: trackingConfiguration.pageDept,
      pageType,
    };

    if (isTrackingReady) {
      const tracking = (window as any).tracking;
      tracking.page(properties);
    } else {
      console.warn(
        "Attempted to track page visited event, but the react-tracking library is unavailable.",
      );
    }
  };

  const trackAddToCart = (
    label: string,
    productKey: string,
    productVersion: number,
    selectedAttributes: Record<string, string>,
    mpvId: string,
    name: string,
    quantity: Quantity,
    currency: string,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackAddToCart",
        ),
      );
    }

    // TODO: Derive pricing data for the tracking event
    if (typeof quantity === "number") {
      const properties = {
        label,
        attributes: selectedAttributes,
        pageSection: trackingConfiguration.pageSection,
        pageStage: trackingConfiguration.pageStage,
        pageName: trackingConfiguration.pageName,
        pageDept: trackingConfiguration.pageDept,
        core_product_id: productKey,
        core_product_version: productVersion,
        product_id: mpvId,
        name,
        price: 0,
        list_price: 0,
        quantity: 1,
        sales_quantity: quantity,
        discount: 0,
        currency: currency,
      };

      trackEvent("Product Added", properties);
    } else {
      const sizeQuantities = quantity as DynamicSizedQuantities;
      for (let size in sizeQuantities) {
        const properties = {
          label,
          attributes: { ...selectedAttributes, ...{ Size: size } }, // merge the current size to the selected attributes object
          pageSection: trackingConfiguration.pageSection,
          pageStage: trackingConfiguration.pageStage,
          pageName: trackingConfiguration.pageName,
          pageDept: trackingConfiguration.pageDept,
          core_product_id: productKey,
          core_product_version: productVersion,
          product_id: mpvId,
          name,
          price: 0,
          list_price: 0,
          quantity: 1,
          sales_quantity: sizeQuantities[size], // see the sales quantity to the current size's quantity.
          discount: 0,
          currency: currency,
        };

        trackEvent("Product Added", properties);
      }
    }
  };

  const trackProductViewed = (
    label: string,
    mpvId: string,
    name: string,
    productKey: string,
    productVersion: number,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackProductViewed",
        ),
      );
    }
    const properties = {
      label,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageName: trackingConfiguration.pageName,
      pageDept: trackingConfiguration.pageDept,
      core_product_id: productKey,
      core_product_version: productVersion,
      product_id: mpvId,
      name,
    };

    trackEvent("Product Viewed", properties);
  };

  const trackProductRemoved = (
    label: string,
    mpvId: string,
    name: string,
    productKey: string,
    productVersion: number,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackProductRemoved",
        ),
      );
    }
    const properties = {
      label,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageName: trackingConfiguration.pageName,
      pageDept: trackingConfiguration.pageDept,
      core_product_id: productKey,
      core_product_version: productVersion,
      product_id: mpvId,
      name,
    };

    trackEvent("Product Removed", properties);
  };

  const trackFlyOutViewed = (
    label: string,
    mpvId: string,
    name: string,
    productKey: string,
    productVersion: number,
    eventDetail: string,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackFlyOutViewed",
        ),
      );
    }
    const properties = {
      category: TrackingCategory.FLYOUT,
      label,
      eventDetail,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageName: trackingConfiguration.pageName,
      pageDept: trackingConfiguration.pageDept,
      core_product_id: productKey,
      core_product_version: productVersion,
      product_id: mpvId,
      name,
    };

    trackEvent("Fly-Out Viewed", properties);
  };

  const trackFlyOutClosed = (
    label: string,
    mpvId: string,
    name: string,
    productKey: string,
    productVersion: number,
    eventDetail: string,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackFlyOutClosed",
        ),
      );
    }
    const properties = {
      category: TrackingCategory.FLYOUT,
      label,
      eventDetail,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageName: trackingConfiguration.pageName,
      pageDept: trackingConfiguration.pageDept,
      core_product_id: productKey,
      core_product_version: productVersion,
      product_id: mpvId,
      name,
    };

    trackEvent("Fly-Out Closed", properties);
  };

  const trackFlyOutClicked = (
    label: string,
    mpvId: string,
    name: string,
    productKey: string,
    productVersion: number,
    eventDetail: string,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackFlyOutClicked",
        ),
      );
    }
    const properties = {
      category: TrackingCategory.FLYOUT,
      label,
      eventDetail,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageName: trackingConfiguration.pageName,
      pageDept: trackingConfiguration.pageDept,
      core_product_id: productKey,
      core_product_version: productVersion,
      product_id: mpvId,
      name,
    };

    trackEvent("Fly-Out Clicked", properties);
  };

  const trackMessageDisplayed = (
    label: string,
    mpvId: string,
    name: string,
    productKey: string,
    productVersion: number,
    eventDetail?: string,
  ) => {
    const trackingConfiguration = getTrackingConfiguration();
    if (trackingConfiguration === undefined) {
      throw new Error(
        BASE_TRACKING_CONFIG_ERROR_MESSAGE.replace(
          "{function}",
          "trackMessageDisplayed",
        ),
      );
    }
    const properties = {
      category: TrackingCategory.GROUPS_AND_TEAMS,
      label,
      eventDetail,
      pageSection: trackingConfiguration.pageSection,
      pageStage: trackingConfiguration.pageStage,
      pageName: trackingConfiguration.pageName,
      pageDept: trackingConfiguration.pageDept,
      core_product_id: productKey,
      core_product_version: productVersion,
      product_id: mpvId,
      name,
    };

    trackEvent("Message Displayed", properties);
  };

  return {
    isTrackingReady,
    trackPage,
    trackAddToCart,
    trackProductViewed,
    trackProductRemoved,
    trackFlyOutViewed,
    trackFlyOutClosed,
    trackFlyOutClicked,
    trackMessageDisplayed,
    getTrackingConfiguration,
    setTrackingConfiguration,
  };
};

export default useTracking;
