import React, { useRef, useLayoutEffect, useContext } from "react";
import { BoundedContent, Divider, FlexBox, useScreenClass } from "@vp/swan";
import { ConnectedGroupsAndTeamsForm } from "./connectedGroupsAndTeamsForm";
import { GroupsAndTeamsContext } from "../context/GroupsAndTeamsContext";
import { TeamDetailsTitle } from "./title/TeamDetailsTitle";
import { GroupFormContext } from "./context/GroupFormContext";
import AddMemberButton from "./connectedGroupsAndTeamsForm/buttons/AddMemberButton";
import PreviewDesignsLink from "./connectedGroupsAndTeamsForm/buttons/PreviewDesignsLink";
import AddNonPersonalizedLink from "./buttons/AddNonPersonalizedLink";
import AlertCollection from "../../root/AlertCollection";
import { UploadButton } from "./connectedGroupsAndTeamsForm/buttons/UploadButton";
import { shouldAllowUploadFlow } from "../../../utils/uploadTeamMemberHelper";

export const TeamDetails: React.FC<{}> = () => {
  const formEntriesScrollRef = useRef<HTMLDivElement>(null);
  const {
    currentFocusEntryId,
    isYsdDesign,
    isNonPersonalizedAllowed,
    teamsPlaceholders,
    availableSizes,
  } = useContext(GroupsAndTeamsContext);
  const { areFormFieldsInitialized } = useContext(GroupFormContext);
  const isMobileView = useScreenClass() === "xs";
  const hasNonPersonalizedButton = isYsdDesign || isNonPersonalizedAllowed;

  // Styling variables
  const hasFewPlaceholders =
    teamsPlaceholders.length + (availableSizes.length > 1 ? 1 : 0) + 1 <= 2;
  const shouldRenderCTAAsColumn = isMobileView || hasFewPlaceholders;
  const shouldRenderUploadButton = shouldAllowUploadFlow();
  const leftSideJustification = shouldRenderCTAAsColumn
    ? "center"
    : "flex-start";

  const scrollToForm = (formId: string) => {
    const currentForm = document.getElementById(formId);
    if (currentForm) {
      currentForm.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    }
  };

  useLayoutEffect(() => {
    scrollToForm(currentFocusEntryId);
  }, [currentFocusEntryId]);

  return (
    <BoundedContent
      paddingTop={5}
      className="teams-details-container"
      data-testid="teams-selector"
      ref={formEntriesScrollRef}
    >
      <TeamDetailsTitle />
      <AlertCollection paddingX={isMobileView ? 6 : 0} />
      <ConnectedGroupsAndTeamsForm />
      {areFormFieldsInitialized && (
        <>
          <FlexBox
            className="team-details-form-ctas"
            alignItems="center"
            flexDirection={shouldRenderCTAAsColumn ? "column" : "row"}
            justifyContent={
              shouldRenderCTAAsColumn ? "flex-start" : "space-between"
            }
            mb={shouldRenderCTAAsColumn ? "between-subsections" : 7}
            paddingX={5}
            gap={shouldRenderCTAAsColumn ? 6 : 0}
          >
            <FlexBox
              flexDirection={shouldRenderCTAAsColumn ? "column" : "row"}
              alignItems="center"
              justifyContent={leftSideJustification}
              gap={shouldRenderCTAAsColumn ? 6 : 0}
              paddingLeft={{ xs: 0, sm: shouldRenderCTAAsColumn ? 0 : 7 }}
              marginLeft={{ xs: 0, sm: shouldRenderCTAAsColumn ? 0 : 2 }}
            >
              <AddMemberButton />
              {hasNonPersonalizedButton && <AddNonPersonalizedLink />}
            </FlexBox>
            <PreviewDesignsLink />
          </FlexBox>
          {shouldRenderUploadButton && (
            <>
              <Divider
                mb={shouldRenderCTAAsColumn ? "between-subsections" : 7}
              />
              <FlexBox justifyContent={leftSideJustification}>
                <UploadButton />
              </FlexBox>
            </>
          )}
        </>
      )}
    </BoundedContent>
  );
};
