import React from "react";
import { Typography, Spinner, Box } from "@vp/swan";
import { useTranslation } from "react-i18next";

export type ProcessingProps = {
  message?: string;
};

export const Processing: React.FC<ProcessingProps> = ({ message }) => {
  const { t } = useTranslation("translation");

  return (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        gridTemplateAreas: "progress",
        placeItems: "center stretch",
      }}
    >
      <Box textAlign="center">
        <Spinner accessibleText={t("loading-label")} showText />
        {message && (
          <Typography mt={7} fontSize="x2large">
            {message}
          </Typography>
        )}
      </Box>
    </div>
  );
};
