import { useQuery } from "react-query";
import {
  cleanQuantitiesForSizedGoods,
  getDeliveryDate,
} from "../../utils/DeliveryCalculatorHelpers";
import type { Quantity } from "../../types/Inventory";
import { useIdentityContext } from "../../components/root/contexts/IdentityContext";
import { STALE_TIME_1_HOUR } from "./constants";

export default function useDeliveryDate(
  quantity: Quantity,
  merchandisingSelections: Record<string, string>,
  productKey: string,
  productVersion: number,
  isSizedGood: boolean,
  country: string,
) {
  const { auth } = useIdentityContext();
  return useQuery<string | undefined>(
    [
      "delivery-date",
      quantity,
      merchandisingSelections,
      productKey,
      productVersion,
      isSizedGood,
      country,
    ],
    async () => {
      const updatedQuantity = cleanQuantitiesForSizedGoods(
        quantity,
        isSizedGood,
      );
      const deliveryDate = await getDeliveryDate(
        updatedQuantity,
        merchandisingSelections,
        productKey,
        productVersion,
        isSizedGood,
        country,
        auth.getAuthorizationHeader(),
      );
      return deliveryDate;
    },
    {
      enabled: !!productKey && !!productVersion && !!country && !!quantity,
      staleTime: STALE_TIME_1_HOUR,
    },
  );
}
