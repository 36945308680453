import React from "react";
import ReactImageMagnify from "easy-magnify";
import { useTranslation } from "react-i18next";
import { HintCallout } from "./HintCallout";

// Large image dimensions are copied from Cheetah Product Page's implementation of easy-magnify for consistency
const ZOOM_IMAGE_WIDTH = 1800;
const ZOOM_IMAGE_HEIGHT = 1800;

export interface IZoomableImage {
  imageUrl: string;
}

export const ZoomableImage: React.FC<IZoomableImage> = ({ imageUrl }) => {
  const { t } = useTranslation("translation");

  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          isFluidWidth: true,
          src: imageUrl,
        },
        largeImage: {
          src: imageUrl,
          width: ZOOM_IMAGE_WIDTH,
          height: ZOOM_IMAGE_HEIGHT,
        },
        isHintEnabled: false, // Represents whether the tooltip shows up on in the top-left corner of the image
        shouldHideHintAfterFirstActivation: false, // Represents whether the tooltip should hide after a customer interacts/hovers over the image
        hintComponent: HintCallout,
        hintTextMouse: t("preview-modal.zoom-instructions"),
        enlargedImagePosition: "over",
      }}
    />
  );
};
