export const isLocalHost = () => {
  return /localhost|127\.0\.0\.1|::1/.test(window.location.hostname);
};

export const isSandboxBackendDomain = () => {
  const hostname = window?.location?.hostname;
  const hostnameSegments = hostname.split(".");
  return (
    hostnameSegments.pop() === "com" &&
    hostnameSegments.pop() === "vpsvc" &&
    hostnameSegments.pop() === "apparelhub" &&
    hostnameSegments.pop() === "sandbox"
  );
};

export const isProdBackendDomain = () => {
  const hostname = window?.location?.hostname;
  const hostnameSegments = hostname.split(".");
  return (
    hostnameSegments.pop() === "com" &&
    hostnameSegments.pop() === "vpsvc" &&
    hostnameSegments.pop() === "apparelhub" &&
    hostnameSegments.pop() !== "sandbox"
  );
};
