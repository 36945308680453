import React from "react";
import { Header, BOOKEND_TYPES } from "@vp/esi-bookends";
import { Button, Box, Link, Img, H1 } from "@vp/swan";
import { useTranslation } from "react-i18next";

import error from "../../icons/error.svg";

import useTenantConfig from "../../hooks/useTenantConfig";
import { useTracking } from "../../components/root/contexts/TrackingContext";
import { useAppState } from "../../components/root/contexts/AppContext";
import { getPathFromCulture } from "../../utils/LocalizationHelpers";
import { ESIBookendsMetaTag } from "../../ESIBookendsMetaTag";

export const ErrorPage: React.FC<{}> = () => {
  const { culture } = useAppState();

  const { tenantConfig } = useTenantConfig();
  const { getTrackingConfiguration } = useTracking();
  const { t } = useTranslation("translation");

  const pathFromCulture = getPathFromCulture(culture);

  let trackingConfiguration = getTrackingConfiguration();
  trackingConfiguration.pageName = "Error Page";

  return !tenantConfig ? (
    <></>
  ) : (
    <>
      <ESIBookendsMetaTag headerType={BOOKEND_TYPES.FULL} />
      <Header
        locale={culture}
        tenant={tenantConfig.bookends.tenant}
        type={BOOKEND_TYPES.FULL}
        hideSearch={false}
        hideVat={false}
        trackingConfiguration={trackingConfiguration}
        useMiniCart={true}
      />

      <Box py={11} mx={3} align="center" data-testid="error-page-content">
        <Img
          src={error}
          alt={t("error-page.error-alt-label")}
          style={{ width: "100px", height: "100px" }}
        />
        <H1 fontSkin="title-section" mb={7}>
          {t("error-page.error-heading")}
        </H1>
        <Button
          skin="primary"
          render={(props) => (
            <Link href={`${pathFromCulture}/`} className={props.className}>
              {props.children}
            </Link>
          )}
        >
          {t("error-page.homepage-cta-text")}
        </Button>
      </Box>
    </>
  );
};
