import React from "react";
import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogFooter,
  Button,
} from "@vp/swan";
import l10n from "../../../localization/localization";
import styles from "../../../styles/cookie-message-style.scss";

const ConfirmationDialog = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={onCancel}>
      <ModalDialogContent
        className={styles.confirmationDialog}
        aria-label={l10n.t("preferenceDataSale.confirmation.title")}
      >
        <ModalDialogBody>
          {l10n.t("preferenceDataSale.confirmation.message")}
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button skin="primary" onClick={onConfirm}>
              {l10n.t("preferenceDataSale.confirmation.confirm")}
            </Button>
            <Button skin="secondary" onClick={onCancel}>
              {l10n.t("preferenceDataSale.confirmation.cancel")}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  );
};

export default ConfirmationDialog;
