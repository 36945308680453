import { SizeChartProps } from "@vp/lat-react-component-library";
import BaseAxiosClient from "./BaseAxiosClient";

export interface ISizeChartClient {
  getSizeChart: (locale: string, productKey: string) => Promise<SizeChartProps>;
}

class SizeChartClient extends BaseAxiosClient implements ISizeChartClient {
  constructor() {
    super(process.env.REACT_APP_SIZE_CHART_SERVICE as string);
  }

  async getSizeChart(
    locale: string,
    productKey: string,
  ): Promise<SizeChartProps> {
    let response;
    try {
      response = await this.axios.get(
        `/v1/sizechart/${this.tenantConfig.sizeChartService.tenant}/${locale}/coreproductid/${productKey}`,
        {
          params: {
            requestor: this.tenantConfig.requestor,
          },
        },
      );
    } catch (err) {
      console.error(
        `Unable to retrieve size chart for locale productKey ${productKey} in ${locale}.`,
      );
      throw err;
    }

    return response.data;
  }
}

const createSizeChartClient = (): ISizeChartClient => {
  return new SizeChartClient();
};

export default createSizeChartClient;
