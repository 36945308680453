import { useQuery } from "react-query";
import { IBffClient } from "../../clients/BffClient";
import { IWorkEntityClient } from "../../clients/WorkEntityClient";
import { createBffClient, createWorkEntityClient } from "../../clients";
import { useIdentityContext } from "../../components/root/contexts/IdentityContext";
import { parseQueryParameters } from "../../components/root/contexts/AppContext/Parameters/parseParameters";
import { getCulture } from "../../utils/LocalizationHelpers";
import type { Project } from "../../types/Project";
import type { Work } from "../../types/Work";
import type { TeamDetailsConfig } from "../../types/TeamDetailsConfig";
import { STALE_TIME_INFINITY } from "./constants";

const loadWork = async (
  workEntityClient: IWorkEntityClient,
  workId: string,
): Promise<Work> => {
  const work = await workEntityClient.getWork(workId);

  if (work) {
    if (!work.product.key) {
      // Initialize product object since some works might not have it populated
      work.product.key =
        work.resources?.productKey ?? work.design?.metadata?.productKey;
    }

    return work;
  }

  throw new Error("Unable to load work entity");
};

const loadBffData = async (
  bffClient: IBffClient,
  culture: string,
  productKey: string,
  selectedOptions: Record<string, string>,
  productVersion: undefined | number,
): Promise<TeamDetailsConfig> => {
  const bffData = await bffClient.getTeamDetailsConfig(
    culture,
    productKey,
    selectedOptions,
    productVersion,
  );

  if (!bffData) {
    throw new Error(
      `Unable to load BFF Data for (culture:${culture}, productKey:${productKey}, selectedOptions:${JSON.stringify(
        selectedOptions,
      )}, productVersion:${productVersion}).`,
    );
  }
  return bffData;
};

export const loadProject = async (
  culture: string,
  workId: string,
  authHeader: string,
): Promise<Project> => {
  const bffClient: IBffClient = createBffClient(authHeader);
  const workEntityClient: IWorkEntityClient =
    createWorkEntityClient(authHeader);

  const work = await loadWork(workEntityClient, workId);
  if (!work.product.key) {
    throw new Error(
      `Unable to load Team Details Page without a product key for the customer work.`,
    );
  }
  const bffData = await loadBffData(
    bffClient,
    culture,
    work.product.key,
    work.merchandising.merchandisingSelections,
    work.product.version ? parseInt(work.product.version, 10) : undefined,
  );

  return {
    workId: work.workId,
    work: work,
    productName: bffData.productName,
    productKey: work.product.key,
    productVersion: bffData.productVersion,
    mpvId: bffData.mpvId,
    isDeliveryCalculatorEnabled: bffData.isDeliveryCalculatorEnabled,
    isSizedGood: bffData.sizes.length > 0,
    sizes: bffData.sizes,
    lowestPriceAttributes: bffData.lowestPriceAttributes,
    minimumOrderQuantity: bffData.minimumOrderQuantity,
    nextStepUrl: bffData.nextStepUrl,
  };
};

export default function useBffData() {
  const { auth, isIdentityInitialized } = useIdentityContext();
  const culture = getCulture();
  const { workEntityId } = parseQueryParameters(
    window.location.search.substring(1),
  );

  return useQuery<Project | undefined>(
    ["bff-data", workEntityId],
    async () =>
      await loadProject(culture, workEntityId, auth.getAuthorizationHeader()),
    {
      enabled: !!workEntityId && isIdentityInitialized && !!auth,
      staleTime: STALE_TIME_INFINITY,
    },
  );
}
