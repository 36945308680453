import BaseAxiosClient from "./BaseAxiosClient";
import { Group } from "../types/GroupingService";

export default class GroupingServiceClient extends BaseAxiosClient {
  constructor() {
    super(process.env.REACT_APP_GROUPING_SERVICE_ENDPOINT as string);
  }

  async getHighestPriorityGroupWithPurpose(
    productKey: string,
    purposeName: string,
  ): Promise<Group | undefined> {
    try {
      const productGroups = await this.axios.get<Group[]>(
        `/product/${productKey}/purpose/name/${purposeName}/groups`,
        {
          params: { requestor: this.tenantConfig.requestor },
        },
      );

      const productGroup = productGroups.data[0];
      return productGroup;
    } catch (err: any) {
      console.warn(
        `Failed to get groups for product ${productKey} with purpose ${purposeName}, returning undefined`,
      );
      return undefined;
    }
  }
}
