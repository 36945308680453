import React, { useContext } from "react";
import { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { GroupsAndTeamsMember } from "../../../../types/GroupsAndTeams";
import { useQuantity } from "../../../root/contexts/QuantityContext";
import { GroupFormContext } from "../context/GroupFormContext";
import useBffData from "../../../../hooks/queries/useBffData";
import { ReviewContext } from "../../review/context/ReviewContext";
import { ConfirmationDialogModal } from "./ConfirmationDialogModal";
import { useAlertState } from "../../../root/contexts/AlertContext";

type DeleteTeammateModalWrapperProps = {
  isOpen: boolean;
  onCloseModal: any;
  memberToDelete: string;
  raiseErrorToCustomerOnFailure: boolean;
};

export const DeleteTeammateModalWrapper: React.FC<
  DeleteTeammateModalWrapperProps
> = ({
  isOpen,
  onCloseModal,
  memberToDelete,
  raiseErrorToCustomerOnFailure,
}) => {
  const { updateQuantity } = useQuantity();
  const {
    groupsAndTeamsMembers,
    updateGroupsAndTeamsMembers,
    saveGroupsAndTeamsConfig,
    setIsUpdatingTeam,
  } = useContext(GroupsAndTeamsContext);
  const { formValidity, removeEntry } = useContext(GroupFormContext);
  const { setIsApproved } = useContext(ReviewContext);
  const { clearAlerts } = useAlertState();
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { isSizedGood } = bffData!;

  const isLoading = !bffDataIsFetched;

  const deleteGroupsAndTeamsMember = () => {
    handleDeleteMember(memberToDelete);
    onCloseModal();
  };

  const handleDeleteMember = async (memberToDelete: string) => {
    setIsUpdatingTeam(true);
    setIsApproved(false);
    const updatedSizedQuantity = {} as DynamicSizedQuantities;
    let updatedTotalQuantity = 0;
    let areUpdatedGroupMembersAllValid = true;
    const updatedGroupMembers = [] as GroupsAndTeamsMember[];
    groupsAndTeamsMembers.forEach((groupMember: GroupsAndTeamsMember) => {
      if (groupMember.id !== memberToDelete) {
        updatedTotalQuantity += groupMember.qty;
        if (isSizedGood) {
          updatedSizedQuantity[groupMember.selectedOptions.Size] =
            updatedSizedQuantity[groupMember.selectedOptions.Size]
              ? updatedSizedQuantity[groupMember.selectedOptions.Size] +
                groupMember.qty
              : groupMember.qty;
        }

        if (!formValidity[groupMember.id]) {
          areUpdatedGroupMembersAllValid = false;
        }
        updatedGroupMembers.push(groupMember);
      }
    });

    // Only save the doc if there's at least one populated teammate left
    const populatedGroupMembers = updatedGroupMembers.filter(
      (member) => member.qty > 0,
    );

    try {
      if (populatedGroupMembers.length > 0 && areUpdatedGroupMembersAllValid) {
        clearAlerts();
        await saveGroupsAndTeamsConfig({
          groupsAndTeamsMembersToSave: populatedGroupMembers,
          showErrorToast: raiseErrorToCustomerOnFailure,
          throwErrorOnFail: raiseErrorToCustomerOnFailure,
          showDeleteMessaging: true,
        });
      }

      removeEntry(memberToDelete);
      updateQuantity(isSizedGood ? updatedSizedQuantity : updatedTotalQuantity);
      updateGroupsAndTeamsMembers(updatedGroupMembers);
    } catch (err) {
      // Do not update team member state if delete fails
    }

    setIsUpdatingTeam(false);
  };

  return (
    <ConfirmationDialogModal
      dialogType="DeleteTeammate"
      isLoading={isLoading}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onConfirmClick={() => deleteGroupsAndTeamsMember()}
      destructiveAction={true}
    />
  );
};
