import { useContext } from "react";

import { PricingContext } from "./PricingContext";

const usePricingContext = () => {
  const { isInitialized, pricingContextModule } = useContext(PricingContext);

  return {
    isPricingContextInitialized: isInitialized,
    pricingContextModule,
  };
};

export default usePricingContext;
