import React from "react";
import { useMediaPredicate } from "react-media-hook";
import PropTypes from "prop-types";
import ConsentManagerBuilder from "../consent-manager-builder";
import Container from "./container";
import { Mode } from "../constants";

ConsentManager.displayName = "ConsentManager";

ConsentManager.propTypes = {
  container: PropTypes.string.isRequired,
  writeKey: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  otherWriteKeys: PropTypes.arrayOf(PropTypes.string),
  shouldRequireConsent: PropTypes.func,
  hideBanner: PropTypes.bool,
  cookieDomain: PropTypes.string,
  onError: PropTypes.func,
  onAcceptAll: PropTypes.func,
  onSave: PropTypes.func,
  defaultVariation: PropTypes.string,
  suppressReload: PropTypes.bool,
  mode: PropTypes.string,
  data: PropTypes.object,
};

export default function ConsentManager(props) {
  const {
    container,
    writeKey,
    otherWriteKeys = [],
    shouldRequireConsent = () => true,
    hideBanner = false,
    cookieDomain,
    onError,
    onAcceptAll,
    onSave,
    locale,
    tenant,
    defaultVariation,
    suppressReload = false,
    data,
    mode = Mode.Dialog,
  } = props;

  const isXsScreen = useMediaPredicate("only screen and (max-width: 767px)");
  return (
    <ConsentManagerBuilder
      writeKey={writeKey}
      otherWriteKeys={otherWriteKeys}
      shouldRequireConsent={shouldRequireConsent}
      hideBanner={hideBanner}
      cookieDomain={cookieDomain}
      locale={locale}
      tenant={tenant}
      defaultVariation={defaultVariation}
      isXsScreen={isXsScreen}
      suppressReload={suppressReload}
      mode={mode ?? Mode.Dialog}
      data={data}
      onError={onError}
    >
      {({
        destinations,
        preferences,
        setPreferences,
        resetPreferences,
        saveConsent,
        showBanner,
        variation,
        saveDataSale,
        ccpa,
      }) => (
        <Container
          container={container}
          destinations={destinations}
          preferences={preferences}
          setPreferences={setPreferences}
          resetPreferences={resetPreferences}
          saveConsent={saveConsent}
          variation={variation}
          showBanner={showBanner}
          mode={mode ?? Mode.Dialog}
          ccpa={ccpa}
          saveDataSale={saveDataSale}
          onAcceptAll={onAcceptAll}
          onSave={onSave}
        />
      )}
    </ConsentManagerBuilder>
  );
}
