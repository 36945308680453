import BaseAxiosClient from "./BaseAxiosClient";
import type {
  MerchantAvailabilityResponse,
  MerchandisingInventory,
  ProductInventory,
} from "../types/MerchantAvailability";
import { hasLowStock } from "@vp/inventory-utilities";
import { stringify } from "qs";
import { getInventoryCount } from "../utils/MerchantAvailabilityHelpers";

const SIZE = "Size";
const SUBSTRATE_COLOR = "Substrate Color";

export interface IMerchantAvailabilityClient {
  getMerchantAvailability: (
    productKey: string,
    productVersion: number,
    country: string,
    minimumOrderQuantity: number,
    selectedAttributes: Record<string, string>,
    isSizedGood: boolean,
  ) => Promise<MerchandisingInventory | undefined>;
  getDeliveryDate: (
    productKey: string,
    productVersion: number,
    country: string,
    selectedAttributes: Record<string, string>,
    isSizedGood: boolean,
    merchantAvailabilityRequest: MerchantAvailabilityRequest,
  ) => Promise<string>;
}

export interface MerchantAvailabilityRequest {
  facetQuantities: MerchantAvailabilityFacet[];
}

interface MerchantAvailabilityFacet {
  value: string;
  quantity: number;
}

const assignInventoryLevel = (
  inventoryCount: number,
  minimumOrderQuantity: number,
) => {
  return hasLowStock(inventoryCount, minimumOrderQuantity) ? "Low" : "High";
};

class MerchantAvailabilityClient
  extends BaseAxiosClient
  implements IMerchantAvailabilityClient
{
  constructor(authHeader: string) {
    super(
      process.env.REACT_APP_MERCHANT_AVAILABILITY_ENDPOINT as string,
      authHeader,
    );
  }

  async getMerchantAvailability(
    productKey: string,
    productVersion: number,
    country: string,
    minimumOrderQuantity: number,
    selectedAttributes: Record<string, string>,
    isSizedGood: boolean,
  ): Promise<MerchandisingInventory | undefined> {
    try {
      if (!productKey || !productVersion) {
        throw Error("Missing product info");
      }

      const response = await this.axios.get<MerchantAvailabilityResponse>(
        `v3/product/${productKey}/versions/${productVersion}/availability`,
        {
          params: {
            context: {
              Merchant: this.tenantConfig.merchantAvailability.tenant,
              Country: country,
            },
            facetAttributeName: isSizedGood ? SIZE : undefined,
            attributeSelections: selectedAttributes,
          },
          paramsSerializer: (params) => stringify(params),
        },
      );

      const facetCombinationMap = response.data.facetedAvailabilityStates.map(
        (facet) => {
          const inventoryValue = getInventoryCount(facet.availabilityState);

          return {
            attributes: facet.attributeSelections,
            inventory: inventoryValue,
            inventoryLevel: assignInventoryLevel(
              inventoryValue,
              minimumOrderQuantity,
            ),
          } as ProductInventory;
        },
      );
      const totalInventory = getInventoryCount(response.data.availabilityState);

      return {
        combinationsInventories: facetCombinationMap,
        inventory: totalInventory,
        inventoryLevel: assignInventoryLevel(
          totalInventory,
          minimumOrderQuantity,
        ),
      };
    } catch (err) {
      console.error(
        `Cannot get product Inventory for productKey ${productKey}, productVersion ${productVersion}, tenant ${this.tenantConfig.merchantAvailability.tenant}, country ${country}`,
      );
      return undefined;
    }
  }

  async getDeliveryDate(
    productKey: string,
    productVersion: number,
    country: string,
    selectedAttributes: Record<string, string>,
    isSizedGood: boolean,
    merchantAvailabilityRequest: MerchantAvailabilityRequest,
  ): Promise<string> {
    try {
      const facetOptionName = isSizedGood ? SIZE : SUBSTRATE_COLOR;
      const { data } = await this.axios.post<{
        earliestDeliverableDate: string;
      }>(
        `v2/product/${productKey}/versions/${productVersion}/availability/earliestdeliverabledate/facets/${encodeURIComponent(facetOptionName)}`,
        merchantAvailabilityRequest,
        {
          params: {
            context: {
              Merchant: this.tenantConfig.merchantAvailability.tenant,
              Country: country,
            },
            attributeSelections: isSizedGood ? selectedAttributes : undefined,
          },
          paramsSerializer: (params) => stringify(params),
        },
      );

      return data.earliestDeliverableDate;
    } catch (e) {
      console.error(
        `Cannot get product delivery date for productKey ${productKey}, productVersion ${productVersion}, tenant ${this.tenantConfig.merchantAvailability.tenant}, country ${country}`,
      );
      throw e;
    }
  }
}

const createMerchantAvailabilityClient = (
  authHeader: string,
): IMerchantAvailabilityClient => {
  return new MerchantAvailabilityClient(authHeader);
};

export default createMerchantAvailabilityClient;
