import { createCustomSportswearClient } from "../../../../clients";
import { SavedMemberData } from "../../../../clients/CustomSportswearClient";
import { getUnmergedPlaceholderValues } from "../../../../utils/GroupsAndTeamsHelpers";
import type { GroupsAndTeamsMember } from "../../../../types/GroupsAndTeams";
import type { Work } from "../../../../types/Work";
import type { SaveStrategy } from "./SaveStrategy";

export default class YsdSaveStrategy implements SaveStrategy {
  async saveMembers(
    authHeader: string,
    work: Work,
    teamMembers: GroupsAndTeamsMember[],
  ) {
    if (!work.design.metadata?.ysdDesignData) {
      throw new Error("Unable to save team members without YSD design data.");
    }

    const ysdDesignId = JSON.parse(work.design.metadata.ysdDesignData).id;
    const groupMemberPlaceholderData = teamMembers.map(
      (member: GroupsAndTeamsMember) => {
        return {
          placeholderValues: getUnmergedPlaceholderValues(
            member.placeholderValues,
          ),
        };
      },
    );

    const customSportswearClient = createCustomSportswearClient(authHeader);
    const saveResponse = await customSportswearClient.saveMembers(
      ysdDesignId,
      groupMemberPlaceholderData,
    );

    const groupMemberDataWithDocuments = saveResponse.data.map(
      (member: SavedMemberData, i: number) => {
        const matchingGroupData = teamMembers[i];
        return {
          id: member.id,
          qty: matchingGroupData.qty,
          selectedOptions: matchingGroupData.selectedOptions,
          placeholderValues: matchingGroupData.placeholderValues,
          documentUrl: member.documentUrl,
          previewUrl: member.previewUrls[0],
          previewUrls: member.previewUrls,
          isNonPersonalized: teamMembers[i].isNonPersonalized,
          shouldShowWarningForEmptyFields: false,
        };
      },
    );

    return groupMemberDataWithDocuments;
  }
}
