import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  GridContainer,
  Row,
  Column,
  Typography,
  Divider,
  useScreenClass,
} from "@vp/swan";
import Previews from "./Previews";
import { GroupsAndTeamsMember } from "../../../types/GroupsAndTeams";
import { useBffData } from "../../../hooks/queries";
import { GroupsAndTeamsContext } from "../context/GroupsAndTeamsContext";
import EditDetailsButton from "./buttons/EditDetailsButton";
import EditDesignLink from "./buttons/EditDesignLink";

type PreviewBlockProps = {
  groupsAndTeamsMember: GroupsAndTeamsMember;
  groupsAndTeamsEntryNumber: number;
  totalTeamMembers: number;
  showEditDesignLink: boolean;
  isReviewPage: boolean;
};

const PreviewBlock: React.FC<PreviewBlockProps> = ({
  groupsAndTeamsMember,
  groupsAndTeamsEntryNumber,
  totalTeamMembers,
  showEditDesignLink,
  isReviewPage,
}) => {
  const { t } = useTranslation("translation");
  const { isYsdDesign } = useContext(GroupsAndTeamsContext);
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { isSizedGood } = bffData!;

  const isMobileView = useScreenClass() === "xs";

  const getTeamNumberWithTotal = (i: number): string => {
    const baseText = isYsdDesign
      ? t("groups-and-teams.teammate-number-with-total")
      : t("groups-and-teams.person-number-with-total");
    return baseText
      .replace("{i}", (i + 1).toString())
      .replace("{j}", totalTeamMembers.toString());
  };

  if (isMobileView) {
    return (
      <GridContainer
        className="preview-block"
        gutter="tight"
        mb={isReviewPage ? "between-subsections" : "auto"}
      >
        <Row pb={5}>
          <Previews
            groupMember={groupsAndTeamsMember}
            isReviewPage={isReviewPage}
          />
        </Row>
        <Row mb={3}>
          <Column span={12}>
            <Typography fontSkin="title-subsection" textAlign="left">
              {getTeamNumberWithTotal(groupsAndTeamsEntryNumber)}
            </Typography>
          </Column>
        </Row>
        {bffDataIsFetched && isSizedGood && (
          <Row mb={3}>
            <Column span={12}>
              <Typography fontSkin="body-standard">
                {t("groups-and-teams.size-label")}:{" "}
                {groupsAndTeamsMember.selectedOptions["Size"]}
              </Typography>
            </Column>
          </Row>
        )}
        <Row mb="to-actions">
          <Column span={12}>
            <Typography fontSkin="body-standard">
              {t("groups-and-teams.quantity-label")}: {groupsAndTeamsMember.qty}
            </Typography>
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <EditDetailsButton groupsAndTeamsMember={groupsAndTeamsMember} />
            {showEditDesignLink && <EditDesignLink />}
          </Column>
        </Row>
        {groupsAndTeamsEntryNumber !== totalTeamMembers - 1 && (
          <Divider my="between-subsections" />
        )}
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <Row className="preview-block">
        <Column
          span={2}
          spanSm={3}
          spanMd={3}
          pl={isReviewPage ? undefined : 0}
          pr={0}
        >
          <Row pb={4}>
            <Column span={12}>
              <Typography fontSkin="title-subsection" textAlign="left">
                {getTeamNumberWithTotal(groupsAndTeamsEntryNumber)}
              </Typography>
            </Column>
          </Row>
          {bffDataIsFetched && isSizedGood && (
            <Row pb={4}>
              <Column span={12}>
                <Typography fontSkin="body-standard">
                  {t("groups-and-teams.size-label")}:{" "}
                  {groupsAndTeamsMember.selectedOptions["Size"]}
                </Typography>
              </Column>
            </Row>
          )}
          <Row mb="to-actions">
            <Column span={12}>
              <Typography fontSkin="body-standard">
                {t("groups-and-teams.quantity-label")}:{" "}
                {groupsAndTeamsMember.qty}
              </Typography>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <EditDetailsButton groupsAndTeamsMember={groupsAndTeamsMember} />
            </Column>
          </Row>
          {showEditDesignLink && (
            <Row>
              <Column span={12}>
                <EditDesignLink />
              </Column>
            </Row>
          )}
        </Column>
        <Column span={10} spanSm={9} spanMd={9} pb={0}>
          <Previews
            groupMember={groupsAndTeamsMember}
            isReviewPage={isReviewPage}
          />
        </Column>
      </Row>
      {groupsAndTeamsEntryNumber !== totalTeamMembers - 1 && (
        <Divider my="between-subsections" />
      )}
    </GridContainer>
  );
};

export default PreviewBlock;
