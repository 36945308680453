import { createRoot } from "react-dom/client";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SiteContent } from "@vp/swan";
import "./i18n";
import "@vp/react-product-preview/dist/esm/index.css";
import App from "./components/root/App";
import * as serviceWorker from "./serviceWorker";
import { IdentityContextProvider } from "./components/root/contexts/IdentityContext";
import { TrackingContextProvider } from "./components/root/contexts/TrackingContext";
import { PricingContextProvider } from "./components/root/contexts/PricingContext";
import { AppContextProvider } from "./components/root/contexts/AppContext";
import { AlertContextProvider } from "./components/root/contexts/AlertContext";
import { AbTestContextProvider } from "./components/root/contexts/AbTestContext";
import { DevPage } from "./components/dev/DevPage";
import NotFound from "./components/root/NotFound";
import { getTenantConfig } from "./utils/tenantHelpers";
import { isProdBackendDomain } from "./utils/EnvironmentHelpers";
import {
  getCountry,
  getCulture,
  getPathFromCulture,
} from "./utils/LocalizationHelpers";
import { SwanConfiguration } from "./SwanConfiguration";

const tenantConfig = getTenantConfig();
const culture = getCulture();
const country = getCountry();
const pathFromCulture = getPathFromCulture(culture);

// temporarily disable retry in react query since axios handles it
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const TeamDetailsPage = (
  <IdentityContextProvider tenant={tenantConfig}>
    <Router>
      <Routes>
        {!isProdBackendDomain() && <Route path="/" element={<DevPage />} />}
        <Route
          path={`${pathFromCulture}/configure/team`}
          element={
            <SwanConfiguration>
              <SiteContent>
                <TrackingContextProvider>
                  <PricingContextProvider
                    tenant={tenantConfig.pricing.tenant}
                    market={country}
                  >
                    <QueryClientProvider client={queryClient}>
                      <AppContextProvider>
                        <AlertContextProvider>
                          <AbTestContextProvider>
                            <App />
                          </AbTestContextProvider>
                        </AlertContextProvider>
                      </AppContextProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                  </PricingContextProvider>
                </TrackingContextProvider>
              </SiteContent>
            </SwanConfiguration>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </IdentityContextProvider>
);

const root = createRoot(document.getElementById("team-details-page")!);
root.render(TeamDetailsPage);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
