import reader from "./ctc-reader";

const MAX_TREATMENTS_PROPAGATION_MS = 15 * 60 * 1000; // 15 minutes

export const inferDataSalePreferenceModification = (
  customPreferences,
  lastModified,
) => {
  let cookiePreferencesShouldBeModified = false;
  let profilePreferencesShouldBeModified = false;
  const isProfileDataSaleOptedOut = isDataSaleOptedOutInContext();
  const definedLastModified = lastModified ?? new Date(1970, 1, 1).getTime();

  if (!customPreferences) {
    const isGlobalPrivacyControlOptedOut = doNotSellEnabledInBrowser();
    if (isProfileDataSaleOptedOut || isGlobalPrivacyControlOptedOut) {
      customPreferences =
        customPreferencesWithDataSaleOptedOut(customPreferences);
      cookiePreferencesShouldBeModified = true;
    }
    if (!isProfileDataSaleOptedOut && isGlobalPrivacyControlOptedOut) {
      profilePreferencesShouldBeModified = true;
    }
  } else {
    const isCookieDataSaleOptedIn = customPreferences.dataSale === true;
    const areTreatmentsFresh =
      Date.now() - definedLastModified > MAX_TREATMENTS_PROPAGATION_MS;
    if (
      isCookieDataSaleOptedIn &&
      isProfileDataSaleOptedOut &&
      areTreatmentsFresh
    ) {
      customPreferences =
        customPreferencesWithDataSaleOptedOut(customPreferences);
      cookiePreferencesShouldBeModified = true;
    }
  }

  return {
    cookiePreferencesShouldBeModified,
    profilePreferencesShouldBeModified,
    version: 4,
    customPreferences,
    lastModified: Date.now(),
  };
};

const doNotSellEnabledInBrowser = () => {
  const control = navigator?.globalPrivacyControl;
  return (
    control === true || control === 1 || control === "1" || control === "true"
  );
};

const isDataSaleOptedOutInContext = () => {
  return reader.isTreatmentEnabled("data_sale_opted_out");
};

const customPreferencesWithDataSaleOptedOut = (customPreferences) => {
  return {
    ...(customPreferences ?? {}),
    dataSale: false,
  };
};
