import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import l10n from "../../localization/localization";
import Dialog from "../dialog";
import PreferenceButtons from "./preference-buttons";
import Preference from "./preference";
import { Mode } from "../../constants";

export default class PreferenceDialog extends PureComponent {
  static displayName = "PreferenceDialog";

  static propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onAcceptAll: PropTypes.func.isRequired,
    advertising: PropTypes.bool,
    functional: PropTypes.bool,
    sessionReplay: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.eventContainerRef = React.createRef();
  }

  render() {
    const {
      id,
      onCancel,
      advertising = null,
      functional = null,
      sessionReplay = null,
      onAcceptAll,
      onChange,
    } = this.props;

    const eventContainerRef = this.eventContainerRef;

    const buttonsHeaderPosition = {
      offsetLg: 3,
      offset: 1,
      span: 11,
      spanLg: 9,
    };

    const buttons = (
      <PreferenceButtons
        acceptAll={onAcceptAll}
        submitSelected={this.handleSubmitSelected}
        preferenceDialogKey="preferenceDialogV5"
        position={buttonsHeaderPosition}
      />
    );

    return (
      <Dialog
        id={id}
        isOpen={this.props.isOpen}
        title={l10n.t("preferenceDialogV5.title")}
        buttons={buttons}
        onClose={onCancel}
        headerPosition={buttonsHeaderPosition}
        eventContainerRef={eventContainerRef}
      >
        <Preference
          advertising={advertising}
          functional={functional}
          sessionReplay={sessionReplay}
          onChange={onChange}
          mode={Mode.Dialog}
        />
      </Dialog>
    );
  }

  handleSubmitSelected = (e) => {
    const { onSave, advertising, functional, sessionReplay } = this.props;

    e.preventDefault();

    // Safe guard against browsers that don't prevent the
    // submission of invalid forms (Safari < 10.1)
    if (advertising === null || functional === null || sessionReplay === null) {
      return;
    }

    onSave();
  };
}
