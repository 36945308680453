import React from "react";
import { useBffData } from "../../hooks/queries";
import { GroupsAndTeams } from "../groupsAndTeams";
import withTracking from "./withTracking";
import { Processing } from "../processing/Processing";
import { ErrorPage } from "../errorPage/ErrorPage";

const App: React.FC<{}> = () => {
  const { isFetched: bffDataIsFetched, isError: bffIsError } = useBffData();

  if (!bffDataIsFetched) {
    // ** show the processing message while app is loading **
    // This will initially be a quick loading message as
    // the application load bff data, but will become more useful
    // as we add more loading messages.
    // Example status updates:
    // - Reviewing your project
    // - Checking inventory
    // - adding to cart (in the case where customer specified quantity already)
    // - etc
    return <Processing />;
  }

  if (bffIsError) {
    return <ErrorPage />;
  }

  const TeamDetailsPage = withTracking(GroupsAndTeams, {
    pageStage: "Configure",
    pageSection: "Configure - Teams",
    pageName: "Configure - Teams",
    pageDept: "PPAG",
  });

  return (
    <div className="teams-app">
      <TeamDetailsPage />
    </div>
  );
};

export default App;
