import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Preference from "./preference";
import PreferenceButtons from "./preference-buttons";
import { Mode } from "../../constants";

export default class PreferenceEmbedded extends PureComponent {
  static propTypes = {
    preferences: PropTypes.object.isRequired,
    handleAcceptAll: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleCategoryChange: PropTypes.func.isRequired,
    isPrimarySkin: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      preferences,
      handleAcceptAll,
      handleSave,
      handleCategoryChange,
      isPrimarySkin,
    } = this.props;

    const buttonsHeaderPosition = {
      span: 12,
    };

    return (
      <>
        <Preference
          advertising={preferences.advertising}
          functional={preferences.functional}
          sessionReplay={preferences.sessionReplay}
          onChange={handleCategoryChange}
          mode={Mode.Embedded}
        />
        <PreferenceButtons
          acceptAll={handleAcceptAll}
          submitSelected={handleSave}
          preferenceDialogKey="preferenceDialogV5"
          position={buttonsHeaderPosition}
          isPrimarySkin={isPrimarySkin}
        />
      </>
    );
  }
}
