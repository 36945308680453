import React, { useContext } from "react";
import { PricingSummary } from "./PricingSummary";
import { DeliveryCalculator } from "./DeliveryCalculator";
import { GroupFormContext } from "../context/GroupFormContext";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { useQuantity } from "../../../root/contexts/QuantityContext";
import { useBffData } from "../../../../hooks/queries";
import { MoqFooterMessage } from "./MoqFooterMessage";

export const PriceAndDelivery: React.FC<{}> = () => {
  const { data: bffData, isLoading } = useBffData();
  const { areFormFieldsInitialized } = useContext(GroupFormContext);
  const { isValidGroupInput } = useContext(GroupsAndTeamsContext);
  const { teamsQuantity, totalQuantity, isAboveMOQ } = useQuantity();

  if (isLoading || !areFormFieldsInitialized) return <></>;

  if (totalQuantity === 0 || isAboveMOQ)
    return (
      <>
        <PricingSummary />
        {bffData?.isDeliveryCalculatorEnabled && isValidGroupInput && (
          <DeliveryCalculator
            iconColor="standard"
            teamsQuantity={teamsQuantity}
            productKey={bffData.productKey}
            productVersion={bffData.productVersion}
            isSizedGood={bffData.isSizedGood}
            merchandisingSelections={
              bffData.work.merchandising.merchandisingSelections
            }
          />
        )}
      </>
    );

  return <MoqFooterMessage moq={bffData!.minimumOrderQuantity} />;
};
