import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TextInput,
  FormField,
  FormLabel,
  FormInputGroup,
  FormHelper,
} from "@vp/swan";
import { ErrorMessage } from "./ErrorMessage";
import type { FieldDefinition } from "../../../../../types/GroupsAndTeams";
import { ERROR_TYPE } from "../../../../../commons/constants";
import { validatePlaceholderValue } from "../../../../../utils/validationHelpers";
import { useExcelUpload } from "../../../../root/contexts/ExcelUploadContext";

export type PlaceholderFieldProps = {
  entryId: string;
  fieldDefinition: FieldDefinition;
  defaultValue?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onFocus?: (target: string) => void;
  onPlaceholderChange: (value: string, key: string, isValid: boolean) => void;
  showsFormLabel: boolean;
  scrollId?: string;
  isNonPersonalized: boolean;
  shouldShowWarningForEmptyFields: boolean;
};

export const PlaceholderField: React.FC<PlaceholderFieldProps> = ({
  entryId,
  fieldDefinition,
  defaultValue,
  disabled = false,
  onPlaceholderChange,
  autoFocus,
  onFocus,
  showsFormLabel,
  scrollId,
  isNonPersonalized,
  shouldShowWarningForEmptyFields,
}) => {
  const { t } = useTranslation("translation");
  const { isFetching: uploadIsFetching, data } = useExcelUpload();

  const [value, setValue] = useState(defaultValue || "");
  const [errorType, setErrorType] = useState(ERROR_TYPE.NONE);
  const [showBlankUploadHelpText, setShowBlankUploadHelpText] = useState(false);

  const decorationAreas = fieldDefinition.decorationLocations
    ? fieldDefinition.decorationLocations.join(", ")
    : "";
  const inputMode =
    fieldDefinition.type === "Number" || fieldDefinition.type === "LegacyNumber"
      ? "numeric"
      : "text";
  const isDisabled = disabled || isNonPersonalized;
  let placeholder;

  if (isNonPersonalized)
    placeholder = t("groups-and-teams.field-non-personalized");
  else
    switch (fieldDefinition.type) {
      case "Name":
        placeholder = t("form-placeholder.name.value");
        break;
      case "Role":
        placeholder = t("form-placeholder.role");
        break;
      case "Number":
        placeholder = t("form-placeholder.number");
        break;
      case "Text":
        placeholder = t("form-placeholder.placeholder");
        break;
      default:
        placeholder = "";
    }

  useEffect(() => {
    let isInputValid = false;
    if (defaultValue) {
      isInputValid = validatePlaceholderValue(
        defaultValue,
        fieldDefinition.validationPattern,
      );
      !isInputValid && setErrorType(ERROR_TYPE.INVALID);
      if (defaultValue !== value) setValue(defaultValue);
      onPlaceholderChange(defaultValue, fieldDefinition.key, isInputValid);
    } else {
      // Do not set error on initial load if no value is given
      if (value !== "") setValue("");
      onPlaceholderChange("", fieldDefinition.key, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length > 0 && value === "" && shouldShowWarningForEmptyFields)
      setShowBlankUploadHelpText(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const isValueValid = validatePlaceholderValue(
      value,
      fieldDefinition.validationPattern,
    );

    if (isValueValid) setErrorType(ERROR_TYPE.NONE);
    else setErrorType(ERROR_TYPE.INVALID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleInput = (input: string) => {
    const inputIsValid = validatePlaceholderValue(
      input,
      fieldDefinition.validationPattern,
    );

    // Allow customers to use backspace invalid, uploaded values while preventing
    // future invalid input.
    if (inputIsValid || input.length < value.length) {
      setValue(input);
      onPlaceholderChange(input, fieldDefinition.key, inputIsValid);
    }
  };

  const onPlaceholderFocus = () => {
    setShowBlankUploadHelpText(false);
    if (onFocus) onFocus(entryId);
  };

  const formLabel =
    fieldDefinition.name + (decorationAreas ? ` (${decorationAreas})` : "");

  return (
    <FormField
      className="teams-details-form-field"
      marginRight={{ sm: "between-actions" }}
    >
      <FormLabel visuallyHidden={!showsFormLabel}>{formLabel}</FormLabel>
      <FormInputGroup>
        <TextInput
          id={scrollId}
          autoFocus={autoFocus}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInput(event.target.value)
          }
          aria-required={"true"}
          name={fieldDefinition.key}
          disabled={isDisabled}
          onFocus={onPlaceholderFocus}
          inputMode={inputMode}
          autoComplete={"off"}
          placeholder={placeholder}
          aria-disabled={isDisabled}
        />
        {!uploadIsFetching && showBlankUploadHelpText && (
          <FormHelper marginTop={2}>
            {t("groups-and-teams.upload-field-blank")}
          </FormHelper>
        )}
        {!uploadIsFetching && errorType !== ERROR_TYPE.NONE && (
          <ErrorMessage type={errorType} />
        )}
      </FormInputGroup>
    </FormField>
  );
};
