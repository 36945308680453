import cloneDeep from "../utils/object-copy-util";

export default async function fetchDestinations(writeKeys, data) {
  let fetchedDestinations;

  if (data && data.integrations) {
    fetchedDestinations = data.integrations;
  } else {
    fetchedDestinations = await Promise.all(
      writeKeys.map(fetchDestinationForWriteKey),
    );
  }

  let destinations = flatten(cloneDeep(fetchedDestinations));

  // Rename creationName to id to abstract the weird data model
  for (const destination of destinations) {
    destination.id = destination.creationName;
    delete destination.creationName;
  }

  // Remove the dummy Repeater destination
  destinations = destinations.filter((d) => d.id !== "Repeater");
  destinations = uniqBy(destinations, "id");
  destinations = sortBy(destinations, "id");

  return destinations;
}

function flatten(array) {
  return [].concat(...array);
}

function sortBy(array, field) {
  const compare = (key) => {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  };

  return array.concat().sort(compare(field));
}

function uniqBy(array, field) {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[field])) {
      map.set(item[field], true);
      result.push(item);
    }
  }
  return result;
}

async function fetchDestinationForWriteKey(writeKey) {
  const res = await fetch(
    `https://tracking.cdn.vpsvc.com/integrations/${writeKey}.json`,
  );

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`,
    );
  }

  return await res.json();
}
