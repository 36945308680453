import { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import React, { ReactNode, useEffect, useState } from "react";
import { useQuantity } from "../../../root/contexts/QuantityContext";
import { useBffData, useMerchantAvailability } from "../../../../hooks/queries";
import { HARD_GOOD_INVENTORY_KEY } from "../../../../commons/constants";

interface ReviewContextInterface {
  isApproved: boolean;
  setIsApproved: any;
}

export const ReviewContext = React.createContext({} as ReviewContextInterface);

const ReviewContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isApproved, setIsApproved] = useState(false);
  const { updateQuantity, setIsValidQuantity } = useQuantity();
  const { data: bffData } = useBffData();
  const { work, isSizedGood, minimumOrderQuantity } = bffData!;
  const { data: inventory, isFetched: inventoryIsFetched } =
    useMerchantAvailability();

  useEffect(() => {
    const updateSizedGoodQuantity = () => {
      let sizeQuantities: DynamicSizedQuantities = work.resources?.qty
        ? (JSON.parse(work?.resources?.qty) as DynamicSizedQuantities)
        : {};
      const sizeSum = Object.values(sizeQuantities).reduce((val, cur) => {
        return val + cur;
      }, 0);

      if (sizeSum < minimumOrderQuantity) {
        // below MOQ
        setIsValidQuantity(false);
        updateQuantity(sizeQuantities);
      } else {
        // need to confirm inventory
        const hasEnoughInventory = Object.keys(sizeQuantities).every(
          (sizeKey) => {
            const requestedQty = sizeQuantities[sizeKey];
            const quantityAvail = inventory![sizeKey]?.numAvailable ?? 0;
            return quantityAvail >= requestedQty;
          },
        );
        setIsValidQuantity(hasEnoughInventory);
        updateQuantity(sizeQuantities);
      }
    };

    const updateHardGoodQuantity = () => {
      const quantity: number = work.resources.qty
        ? parseInt(work.resources.qty) || 0
        : 0;
      if (quantity === 0) {
        updateQuantity(0);
        setIsValidQuantity(false);
      } else {
        const avail = inventory![HARD_GOOD_INVENTORY_KEY];
        setIsValidQuantity(quantity <= avail.numAvailable);
        updateQuantity(quantity);
      }
    };

    if (work && inventoryIsFetched && work.resources?.groupsAndTeamsMembers) {
      if (isSizedGood) {
        updateSizedGoodQuantity();
      } else {
        updateHardGoodQuantity();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work, inventory, inventoryIsFetched]);

  return (
    <ReviewContext.Provider
      value={{
        isApproved,
        setIsApproved,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

export default ReviewContextProvider;
