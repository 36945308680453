import React, { useContext } from "react";
import {
  Button,
  FlexBox,
  Icon,
  Link,
  Popover,
  PopoverContent,
  PopoverLauncher,
  useScreenClass,
} from "@vp/swan";
import { useTranslation } from "react-i18next";
import { GroupFormContext } from "../context/GroupFormContext";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { useAlertState } from "../../../root/contexts/AlertContext";

export const AddNonPersonalizedLink: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const { clearAlerts } = useAlertState();
  const isMobileView = useScreenClass() === "xs";
  const { setValidity, formValidity, setMemberFormsToValidate } =
    useContext(GroupFormContext);
  const {
    groupsAndTeamsMembers,
    updateGroupsAndTeamsMembers,
    getNonPersonalizedTeamMember,
    saveGroupsAndTeamsConfig,
  } = useContext(GroupsAndTeamsContext);

  const onClickAddNonPersonalized = async () => {
    const currentMember =
      groupsAndTeamsMembers[groupsAndTeamsMembers.length - 1];

    if (!formValidity[currentMember.id]) {
      setMemberFormsToValidate([currentMember.id]);
      // move the end of form into view to see the error message
      const errorForm = document.getElementById(currentMember.id);
      if (errorForm) {
        errorForm.focus({ preventScroll: true });
        errorForm.scrollIntoView({
          block: "end",
          inline: "end",
          behavior: "smooth",
        });
      }
      return;
    }

    clearAlerts();
    await saveGroupsAndTeamsConfig();

    const blankTeamMember = getNonPersonalizedTeamMember();
    const newTeamMembers = [...groupsAndTeamsMembers, blankTeamMember];
    setValidity(blankTeamMember.id, false);
    updateGroupsAndTeamsMembers(newTeamMembers);
    setMemberFormsToValidate([]);
  };

  return (
    <FlexBox
      alignItems="center"
      gap={3}
      pr={{ sm: 6 }}
      mx="between-actions"
      flexDirection="row"
      justifyContent="end"
    >
      <Link
        withIcon
        fontSkin="body-standard"
        className="team-details-add-non-personalized-link-button"
        render={(p) => (
          <Button
            skin="link"
            className={p.className}
            onClick={onClickAddNonPersonalized}
          >
            {p.children}
          </Button>
        )}
      >
        <Icon iconType="plus" />
        {t("groups-and-teams.add-non-personalized")}
      </Link>
      <Popover>
        <PopoverLauncher>
          <Link
            render={(p, ref) => (
              <Button
                ref={ref}
                skin="unstyled"
                className={p.className}
                {...p}
              />
            )}
          >
            <Icon iconType="info" />
          </Link>
        </PopoverLauncher>
        <PopoverContent hideArrow={isMobileView}>
          {t("groups-and-teams.add-non-personalized-tooltip")}
        </PopoverContent>
      </Popover>
    </FlexBox>
  );
};

export default AddNonPersonalizedLink;
