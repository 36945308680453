import l10n from "../localization/localization";
import preferencesModule from "./preferences";
import fetchDestinations from "./fetch-destinations";
import fetchResources from "./resources";
import conditionallyLoadAnalytics, { shouldReload } from "./analytics";
import { Component } from "react";
import PropTypes from "prop-types";
import { publishEvent } from "./events";
import { isCcpa } from "./ccpa";
import { enableOpenDialog } from "../global";
import {
  trackBannerViewed,
  trackInteractionTimed,
  trackDataSaleUpdate,
} from "./tracking";
import {
  initializeExperiment,
  fireImpression,
  getExperimentInfo,
} from "./consent-experiment";

const {
  evaluatePreferences,
  loadPreferences,
  savePreferences,
  removePreferences,
  mapCustomPreferences,
} = preferencesModule;

export default class ConsentManagerBuilder extends Component {
  static displayName = "ConsentManagerBuilder";

  static propTypes = {
    children: PropTypes.func.isRequired,
    onError: PropTypes.func,
    writeKey: PropTypes.string.isRequired,
    otherWriteKeys: PropTypes.arrayOf(PropTypes.string),
    shouldRequireConsent: PropTypes.func.isRequired,
    hideBanner: PropTypes.bool,
    cookieDomain: PropTypes.string,
    locale: PropTypes.string.isRequired,
    tenant: PropTypes.string.isRequired,
    defaultVariation: PropTypes.string,
    isXsScreen: PropTypes.bool.isRequired,
    suppressReload: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    data: PropTypes.object,
  };

  state = {
    isLoading: true,
    destinations: [],
    preferences: {},
    originalPreferences: undefined,
    isConsentRequired: true,
    hasConsent: false,
    variation: undefined,
    initialPreferences: {},
  };

  render() {
    const { children, tenant, locale, hideBanner = false } = this.props;
    const {
      isLoading,
      destinations,
      preferences,
      isConsentRequired,
      variation,
    } = this.state;

    if (isLoading) {
      return null;
    }

    const showBanner = this.shouldShowBanner(
      this.state.hasConsent,
      isConsentRequired,
      hideBanner,
    );

    return children({
      destinations,
      preferences,
      showBanner,
      variation,
      setPreferences: this.handleSetPreferences,
      resetPreferences: this.handleResetPreferences,
      saveConsent: this.handleSaveConsent,
      saveDataSale: (dataSale) => this.handleSaveDataSale(dataSale),
      ccpa: isCcpa(tenant, locale),
    });
  }

  async componentDidMount() {
    const { onError } = this.props;
    if (onError && typeof onError === "function") {
      try {
        await this.initialise();
      } catch (exception) {
        await onError(exception);
      }
    } else {
      this.initialise();
    }
  }

  initialise = async () => {
    const {
      writeKey,
      otherWriteKeys = [],
      shouldRequireConsent = () => true,
      hideBanner = false,
      locale,
      tenant,
      defaultVariation,
      cookieDomain,
      mode,
      data,
    } = this.props;

    initializeExperiment();

    const [isConsentRequired, destinations] = await Promise.all([
      shouldRequireConsent(),
      fetchDestinations([writeKey, ...otherWriteKeys], data),
    ]);

    const ccpa = isCcpa(tenant, locale);

    const resources =
      data && data.resources
        ? data.resources
        : await fetchResources(tenant, locale);

    const categoriesDefault = resources.categoriesDefault;

    let {
      hasConsent,
      destinationPreferences,
      customPreferences,
      version,
      initialPreferences,
    } = evaluatePreferences({
      isConsentRequired,
      destinations,
      cookieDomain,
      ccpa,
      categoriesDefault,
    });

    conditionallyLoadAnalytics({
      writeKey,
      destinations,
      destinationPreferences,
      mode,
      initialization: true,
    });

    const { isExperimentEnabled, variation } = await getExperimentInfo({
      defaultVariation,
      resources,
    });

    await l10n.init(locale, tenant, resources);

    if (isConsentRequired && version === 1 && hasConsent) {
      //override old cookie with default preferences
      hasConsent = false;
      customPreferences = undefined;

      removePreferences({ cookieDomain });
    }

    if (isConsentRequired && version === 2 && hasConsent) {
      // ask for new consent when we have old one and session replay feature was enabled
      hasConsent = false;
    }

    this.setState({
      isLoading: false,
      destinations,
      preferences: customPreferences || initialPreferences, // current preferences set in consent manager
      originalPreferences: customPreferences || initialPreferences, // original preferences used during consent manager init
      isConsentRequired,
      hasConsent,
      variation,
      initialPreferences,
    });

    if (isConsentRequired || ccpa) {
      enableOpenDialog();
    }

    publishEvent("consentManagerInitialized", {});

    if (this.shouldShowBanner(hasConsent, isConsentRequired, hideBanner)) {
      trackBannerViewed();
      trackInteractionTimed();

      if (isExperimentEnabled) {
        fireImpression(variation);
      }
    }
  };

  handleSetPreferences = (newPreferences) => {
    this.setState((prevState) => {
      const { destinations, preferences: existingPreferences } = prevState;
      const preferences = this.mergePreferences({
        destinations,
        newPreferences,
        existingPreferences,
      });
      return { preferences };
    });
  };

  handleResetPreferences = () => {
    const { initialPreferences } = this.state;
    const { customPreferences } = loadPreferences();

    let preferences = customPreferences || initialPreferences;

    this.setState({ preferences });
  };

  handleSaveConsent = ({ label, acceptAll }) => {
    this.setState((prevState) => {
      const { destinations, originalPreferences } = prevState;

      let preferences = prevState.preferences;

      return this.updatePreferences({
        destinations,
        preferences,
        acceptAll,
        label,
        originalPreferences,
        hasConsent: true,
      });
    });
  };

  handleSaveDataSale(dataSale) {
    trackDataSaleUpdate(dataSale);

    this.setState((prevState) => {
      const {
        destinations,
        preferences: existingPreferences,
        originalPreferences,
        hasConsent,
      } = prevState;

      let preferences = this.mergePreferences({
        destinations,
        newPreferences: { dataSale: dataSale },
        existingPreferences,
      });

      return this.updatePreferences({
        destinations,
        preferences,
        acceptAll: false,
        label: "Do Not Sell My Info Update",
        originalPreferences,
        hasConsent: true,
      });
    });
  }

  updatePreferences({
    destinations,
    preferences,
    acceptAll,
    label,
    originalPreferences,
    hasConsent,
  }) {
    const { writeKey, cookieDomain, suppressReload, mode } = this.props;
    const { initialPreferences } = this.state;

    let destinationPreferences;

    ({ destinationPreferences } = mapCustomPreferences({
      destinations,
      preferences: { ...initialPreferences, ...preferences },
    }));

    savePreferences({
      destinationPreferences,
      customPreferences: preferences,
      cookieDomain,
      version: this.getCookieVersion(),
      acceptAll,
      hasConsent,
      trackingProperties: {
        label,
        suppressReload,
        pageReloaded: shouldReload(
          suppressReload,
          originalPreferences,
          preferences,
          mode,
          false,
        ),
      },
    });

    conditionallyLoadAnalytics({
      writeKey,
      destinations,
      destinationPreferences,
      suppressReload,
      originalPreferences,
      newPreferences: preferences,
      mode,
      initialization: false,
    });

    return {
      destinationPreferences,
      preferences,
      hasConsent: hasConsent,
      originalPreferences: preferences,
    };
  }

  mergePreferences = ({
    destinations,
    existingPreferences,
    newPreferences,
  }) => {
    let preferences;

    if (typeof newPreferences === "boolean") {
      const destinationPreferences = {};
      for (const destination of destinations) {
        destinationPreferences[destination.id] = newPreferences;
      }
      preferences = destinationPreferences;
    } else if (newPreferences) {
      preferences = {
        ...existingPreferences,
        ...newPreferences,
      };
    } else {
      preferences = existingPreferences;
    }

    return preferences;
  };

  shouldShowBanner(hasConsent, isConsentRequired, hideBanner) {
    return isConsentRequired && !hasConsent && !hideBanner;
  }

  getCookieVersion = () => {
    return 4;
  };
}
