import { openDialog } from "./consent-manager/container";

let Glob = createGlob();
let trackingPreferences = {};

export function enableOpenDialog() {
  Glob.consentManager.openConsentManager = openDialog;
}

function createGlob() {
  let globalObject = {};

  if (typeof window !== "undefined") {
    globalObject = window;
  }

  globalObject.consentManager = globalObject.consentManager || {};
  globalObject.consentManager.getTrackingPreferences = getTrackingPreferences;
  return globalObject;
}

export function reset() {
  Glob = createGlob();
}

function setTrackingPreferences(trackingPreferencesInput) {
  trackingPreferences = trackingPreferencesInput;
}

function getTrackingPreferences() {
  return { ...trackingPreferences };
}

export default {
  setTrackingPreferences,
  getTrackingPreferences,
  reset,
  enableOpenDialog,
};
