import { useEffect, useState } from "react";

import { getTenantConfig } from "../utils/tenantHelpers";

const useTenantConfig = () => {
  const [tenantConfig, setTenantConfig] = useState<any>();

  useEffect(() => {
    const tenantData = getTenantConfig();
    setTenantConfig(tenantData);
  }, []);

  return { tenantConfig };
};

export default useTenantConfig;
