import React from "react";
import {
  Column,
  GridContainer,
  ResponsiveImage,
  ResponsiveImageContainer,
  Row,
} from "@vp/swan";
import type { GroupsAndTeamsMember } from "../../../types/GroupsAndTeams";
import { ZoomableImage } from "./zoom/ZoomableImage";
import { useShowZoomableImage } from "../../../hooks/useShowZoomableImage";

type PreviewsProps = {
  groupMember: GroupsAndTeamsMember;
  isReviewPage: boolean;
};

export const Previews: React.FC<PreviewsProps> = ({
  groupMember,
  isReviewPage,
}) => {
  const hasTwoOrFewerPreviews =
    groupMember.previewUrls && groupMember.previewUrls.length <= 2;
  const hasOnePreview =
    groupMember.previewUrls && groupMember.previewUrls.length === 1;

  const columnSpan = 3;
  const columnXsSpan = hasTwoOrFewerPreviews ? 12 : 6;
  const columnSmSpan = hasTwoOrFewerPreviews ? 11 : 6;
  const columnMdSpanPreviewPage = hasTwoOrFewerPreviews ? 6 : columnSpan;
  const columnLgSpanPreviewPage = hasTwoOrFewerPreviews ? 5 : columnSpan;
  const columnXlSpanPreviewPage = hasTwoOrFewerPreviews ? 4 : columnSpan;

  // Review page will have larger previews for screen sizes that include the "Review your design" section next to the previews
  const columnMdSpanReviewPage = hasOnePreview ? 9 : 6;
  const columnLgSpanReviewPage = hasOnePreview ? 8 : 6;
  const columnXlSpanReviewPage = hasOnePreview ? 7 : 6;

  const showZoomableImage = useShowZoomableImage();

  return (
    <>
      {groupMember.previewUrls && (
        <GridContainer>
          <Row className="preview-block-previews-wrapper">
            {groupMember.previewUrls.map((preview, i) => {
              return (
                <Column
                  key={i}
                  span={columnSpan}
                  spanXs={columnXsSpan}
                  spanSm={columnSmSpan}
                  spanMd={
                    isReviewPage
                      ? columnMdSpanReviewPage
                      : columnMdSpanPreviewPage
                  }
                  spanLg={
                    isReviewPage
                      ? columnLgSpanReviewPage
                      : columnLgSpanPreviewPage
                  }
                  spanXl={
                    isReviewPage
                      ? columnXlSpanReviewPage
                      : columnXlSpanPreviewPage
                  }
                >
                  <ResponsiveImageContainer aspectRatio={1}>
                    {showZoomableImage ? (
                      <ZoomableImage imageUrl={preview} />
                    ) : (
                      <ResponsiveImage src={preview} />
                    )}
                  </ResponsiveImageContainer>
                </Column>
              );
            })}
          </Row>
        </GridContainer>
      )}
    </>
  );
};
export default Previews;
