import { MerchantAvailabilityState } from "../types/MerchantAvailability";

// inventory value for fulfillable products if MAiSe does not return an inventory count
const INFINITE_STOCK_INVENTORY_VALUE = 4294967295;

export const getInventoryCount = (
  availabilityState: MerchantAvailabilityState,
) => {
  const isAvailable =
    availabilityState.statusStates.IsDeliverable &&
    availabilityState.statusStates.IsMerchandisable &&
    !availabilityState.statusStates.IsRetiredForMerchandising;
  const inventoryCountValue = isAvailable
    ? availabilityState.inventoryCount ?? INFINITE_STOCK_INVENTORY_VALUE
    : 0;
  return inventoryCountValue;
};
