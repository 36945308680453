import React, { ReactNode, useState } from "react";
import { createEntityCollectionClient } from "../../../../clients";
import { useIdentityContext } from "../IdentityContext";
import { removeUploadDataCaseSensitivity } from "../../../../utils/uploadTeamMemberHelper";

interface IExcelUploadContext {
  parseExcel: (file: File) => Promise<void>;
  data: Record<string, any>[];
  isFetching: boolean;
  uploadFailed: boolean;
}

export const ExcelUploadContext = React.createContext<IExcelUploadContext>(
  {} as IExcelUploadContext,
);

const ExcelUploadContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const { auth } = useIdentityContext();

  const parseExcel = async (file: File) => {
    try {
      const authorizationHeader = auth.getAuthorizationHeader();
      const entityCollectionClient =
        createEntityCollectionClient(authorizationHeader);

      setIsFetching(true);
      setUploadFailed(false);

      const response = await entityCollectionClient.parseFile(file);

      // Remove case-sensitivity by making all keys lower case
      const parsedResponseData = removeUploadDataCaseSensitivity(
        response.entities,
      );
      setData(parsedResponseData);

      setUploadFailed(parsedResponseData.length === 0);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      setUploadFailed(true);
    }
  };

  return (
    <ExcelUploadContext.Provider
      value={{ parseExcel, data, isFetching, uploadFailed }}
    >
      {children}
    </ExcelUploadContext.Provider>
  );
};

export default ExcelUploadContextProvider;
