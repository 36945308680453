import BaseAxiosClient from "./BaseAxiosClient";

export interface CreateDocumentsResult {
  documentUrl: string;
  previewInstructionsUrl: string;
}

export interface ITeamsDocumentGenerationClient {
  createDocuments(
    documentUrl: string,
    groupPlaceholderValues: { placeholderValues: Record<string, string> }[],
  ): Promise<CreateDocumentsResult[]>;
}

class TeamsDocumentGenerationClient
  extends BaseAxiosClient
  implements ITeamsDocumentGenerationClient
{
  constructor(authHeader: string) {
    super(
      process.env.REACT_APP_TEAMS_DOCUMENT_GENERATION_SERVICE_URL as string,
      authHeader,
    );
  }

  async createDocuments(
    documentUrl: string,
    groupPlaceholderValues: { placeholderValues: Record<string, string> }[],
  ): Promise<CreateDocumentsResult[]> {
    try {
      const res = await this.axios.post<CreateDocumentsResult[]>(
        "createDocuments",
        {
          documentUrl,
          teamMembers: groupPlaceholderValues,
        },
      );

      return res.data;
    } catch (err) {
      console.error("Teams document generation failed.");
      throw err;
    }
  }
}

const createTeamsDocumentGenerationClient = (authHeader: string) => {
  return new TeamsDocumentGenerationClient(authHeader);
};

export default createTeamsDocumentGenerationClient;
