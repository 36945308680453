import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import useAbTest from "../../AbTestContext/useAbTest";
import { useScreenClass } from "@vp/swan";

interface IZoomableABContext {
  isZoomEnabled: boolean;
}

const defaultContextState: IZoomableABContext = {
  isZoomEnabled: false,
};

export const ZoomableImageABContext =
  createContext<IZoomableABContext>(defaultContextState);

const ZoomableImageABProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const ABTest = useAbTest();
  const screenSize = useScreenClass();
  const [isZoomEnabled, setIsZoomEnabled] = useState(false);
  const isMobileOrTabletView = screenSize === "xs" || screenSize === "sm";

  // Update zoomable image boolean if experiment variation is assigned accordingly (and the device isn't mobile/tablet)
  useEffect(() => {
    if (!ABTest) return;
    if (!isMobileOrTabletView) {
      const { isExperimentEnabled, trackImpression } = ABTest;

      const experimentIsEnabled = isExperimentEnabled(
        "groups_and_teams_review_page_zoom",
      );
      trackImpression("groups_and_teams_review_page_zoom");

      setIsZoomEnabled(experimentIsEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ABTest]);

  const { Provider } = ZoomableImageABContext;
  return <Provider value={{ isZoomEnabled }}>{children}</Provider>;
};

export default ZoomableImageABProvider;
