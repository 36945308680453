import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, useScreenClass } from "@vp/swan";
import { GroupsAndTeamsMember } from "../../../../types/GroupsAndTeams";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";

type EditDetailsButtonProps = {
  groupsAndTeamsMember: GroupsAndTeamsMember;
};

const EditDetailsButton: React.FC<EditDetailsButtonProps> = ({
  groupsAndTeamsMember,
}) => {
  const { t } = useTranslation("translation");
  const {
    setIsPreviewModalOpen,
    isPreviewModalOpen,
    setIsReviewPanelOpen,
    isReviewPanelOpen,
  } = useContext(GroupsAndTeamsContext);

  const isMobileView = useScreenClass() === "xs";

  const onClickEditDetailsButton = () => {
    if (isPreviewModalOpen) setIsPreviewModalOpen(false);
    if (isReviewPanelOpen) setIsReviewPanelOpen(false);
    const selectedMember = document.getElementById(
      groupsAndTeamsMember.id + "-firstFormField",
    );
    if (selectedMember) {
      setTimeout(function () {
        selectedMember.focus({ preventScroll: true });
      }, 0);
      setTimeout(function () {
        isMobileView
          ? selectedMember.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "center",
            })
          : selectedMember.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
      }, 500);
    }
  };

  return (
    <Button
      width="standard"
      iconPosition="left"
      onClick={onClickEditDetailsButton}
      mr={{ xs: "between-actions", sm: "auto" }}
    >
      {t("edit-details")}
      <Icon alt={t("edit")} iconType="edit"></Icon>
    </Button>
  );
};

export default EditDetailsButton;
