import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Spinner, ModalDialog, Button, useScreenClass } from "@vp/swan";
import { useAppState } from "../../../root/contexts/AppContext";
import { useIdentityContext } from "../../../root/contexts/IdentityContext";
import { useBffData } from "../../../../hooks/queries";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { ReviewContext } from "../../review/context/ReviewContext";
import { GroupFormContext } from "../context/GroupFormContext";
import { ReviewPage } from "../../review";
import { generateGroupsAndTeamsDocuments } from "../../../../utils/documentGenerationHelpers";
import { scrollToFirstInvalidEntry } from "../../../../utils/validationHelpers";
import { useAlertState } from "../../../root/contexts/AlertContext";
import { useQuantity } from "../../../root/contexts/QuantityContext";

const ProceedToTeamReviewButton: React.FC<{ useFullWidth?: boolean }> = ({
  useFullWidth = false,
}) => {
  const { t } = useTranslation("translation");
  const screenClass = useScreenClass();
  const { culture } = useAppState();
  const { data: bffData } = useBffData();
  const { work, productKey, productVersion } = bffData!;
  const {
    isValidGroupInput,
    groupsAndTeamsMembers,
    updateGroupsAndTeamsMembers,
    isReviewPanelOpen,
    setIsReviewPanelOpen,
    isUpdatingTeam,
    setIsUpdatingTeam,
    isYsdDesign,
    saveGroupsAndTeamsConfig,
  } = useContext(GroupsAndTeamsContext);
  const { isAboveMOQ } = useQuantity();
  const { clearAlerts, setError } = useAlertState();
  const { formValidity, clearValidity, setValidity, setMemberFormsToValidate } =
    useContext(GroupFormContext);
  const { setIsApproved } = useContext(ReviewContext);
  const { auth } = useIdentityContext();
  const isXsScreen = screenClass === "xs";

  const showReviewPanel = () => {
    window.history.pushState(null, "show-review-panel");
    setIsReviewPanelOpen(true);
  };

  const dismissReviewPanel = () => {
    window.history.back();
    setIsReviewPanelOpen(false);
  };

  window.addEventListener("popstate", () => setIsReviewPanelOpen(false));

  async function onProceedToReviewClick() {
    try {
      if (!isValidGroupInput) {
        scrollToFirstInvalidEntry(
          groupsAndTeamsMembers,
          formValidity,
          setMemberFormsToValidate,
        );
        return;
      }

      if (!isAboveMOQ) {
        setError(t("alert-messages.moq"));
        return;
      }

      setIsUpdatingTeam(true);
      setIsApproved(false);
      clearAlerts();
      showReviewPanel();

      const groupMemberDataWithDocuments =
        await generateGroupsAndTeamsDocuments(
          work,
          groupsAndTeamsMembers,
          auth,
          isYsdDesign,
          productKey,
          productVersion,
          culture,
          clearValidity,
          setValidity,
          updateGroupsAndTeamsMembers,
        );
      await saveGroupsAndTeamsConfig({
        showErrorToast: true,
        throwErrorOnFail: true,
        groupsAndTeamsMembersToSave: groupMemberDataWithDocuments,
      });

      setIsUpdatingTeam(false);
    } catch (err) {
      dismissReviewPanel();
      setIsUpdatingTeam(false);
    }
  }

  return (
    <>
      <Button
        onClick={onProceedToReviewClick}
        skin="primary"
        width={useFullWidth ? "full-width" : "standard"}
        disabled={isUpdatingTeam}
      >
        {isUpdatingTeam && <Spinner accessibleText={t("loading-label")} />}
        {t("groups-and-teams.review-design")}
      </Button>
      {/* ModalDialog variant required to display "pinned" footer in mobile */}
      <ModalDialog
        takeOver={!isXsScreen}
        variant={isXsScreen ? "panel-right" : null}
        className="review-modal"
        isOpen={isReviewPanelOpen}
        onRequestDismiss={dismissReviewPanel}
        onlyRenderWhenOpen
      >
        <ReviewPage />
      </ModalDialog>
    </>
  );
};

export default ProceedToTeamReviewButton;
