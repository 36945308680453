import React, { useContext } from "react";
import { Button, Icon, Link, ModalDialog } from "@vp/swan";
import PreviewPage from "../../../preview/PreviewPage";
import { GroupsAndTeamsContext } from "../../../context/GroupsAndTeamsContext";
import { useTranslation } from "react-i18next";
import { generateGroupsAndTeamsDocuments } from "../../../../../utils/documentGenerationHelpers";
import { useBffData } from "../../../../../hooks/queries";
import { GroupFormContext } from "../../context/GroupFormContext";
import { useIdentityContext } from "../../../../root/contexts/IdentityContext";
import { useAppState } from "../../../../root/contexts/AppContext";
import { useAlertState } from "../../../../root/contexts/AlertContext";
import { scrollToFirstInvalidEntry } from "../../../../../utils/validationHelpers";

export const PreviewDesignsLink: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const { culture } = useAppState();
  const { auth } = useIdentityContext();
  const { setError, clearAlerts } = useAlertState();
  const { data: bffData } = useBffData();
  const { work, productKey, productVersion } = bffData!;

  const {
    isPreviewModalOpen,
    setIsPreviewModalOpen,
    setIsUpdatingTeam,
    groupsAndTeamsMembers,
    updateGroupsAndTeamsMembers,
    isYsdDesign,
    isValidGroupInput,
    saveGroupsAndTeamsConfig,
  } = useContext(GroupsAndTeamsContext);
  const { clearValidity, setValidity, formValidity, setMemberFormsToValidate } =
    useContext(GroupFormContext);

  const onClickPreviewDesignsButton = async () => {
    try {
      if (!isValidGroupInput) {
        scrollToFirstInvalidEntry(
          groupsAndTeamsMembers,
          formValidity,
          setMemberFormsToValidate,
        );
        return;
      }

      clearAlerts();
      setIsPreviewModalOpen(true);
      setIsUpdatingTeam(true);

      const groupMemberDataWithDocuments =
        await generateGroupsAndTeamsDocuments(
          work,
          groupsAndTeamsMembers,
          auth,
          isYsdDesign,
          productKey,
          productVersion,
          culture,
          clearValidity,
          setValidity,
          updateGroupsAndTeamsMembers,
        );
      await saveGroupsAndTeamsConfig({
        showErrorToast: true,
        throwErrorOnFail: true,
        groupsAndTeamsMembersToSave: groupMemberDataWithDocuments,
      });

      setIsUpdatingTeam(false);
    } catch (err) {
      const errorMessage = isYsdDesign
        ? t("alert-messages.unable-to-save-team")
        : t("alert-messages.unable-to-save-group-details");
      setError(errorMessage);
      setIsPreviewModalOpen(false);
      setIsUpdatingTeam(false);
    }
  };

  return (
    <>
      <Link
        withIcon
        fontSkin="body-standard"
        className="team-details-preview-link-button"
        mr={groupsAndTeamsMembers.length === 1 ? 2 : 0}
        render={(p) => (
          <Button
            skin="link"
            className={p.className}
            onClick={onClickPreviewDesignsButton}
          >
            {p.children}
          </Button>
        )}
      >
        <Icon iconType="passwordShow" mr={1} ml={0} aria-hidden="true" />
        {t("groups-and-teams.preview-designs")}
      </Link>
      <ModalDialog
        className="preview-modal"
        isOpen={isPreviewModalOpen}
        onRequestDismiss={() => setIsPreviewModalOpen(false)}
        takeOver
        onlyRenderWhenOpen
      >
        <PreviewPage />
      </ModalDialog>
    </>
  );
};

export default PreviewDesignsLink;
