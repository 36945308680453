import { trackUserInteraction } from "@vp/track-user-interaction";
import libraryInfo from "../libraryInfo";
export function trackBannerViewed() {
  onTrackingReady(() => sendBannerViewedEvent());
}

export function trackManageSettings() {
  onTrackingReady(() => sendManageSettingsEvent());
}

export function trackInteractionTimed() {
  trackUserInteraction({ eventName: "consent manager loaded" });
}

export function trackDataSaleUpdate(dataSale, globalPrivacyControl = false) {
  const getLabel = (label) =>
    globalPrivacyControl ? `${label} - Global Privacy Control` : label;
  const data = dataSale
    ? {
        event: "Data Sale Opt-in Captured",
        label: getLabel("DNS Restriction Off"),
      }
    : {
        event: "Data Sale Opt-out Captured",
        label: getLabel("DNS Restriction On"),
      };
  onTrackingReady(() =>
    window.tracking.track(data.event, {
      label: data.label,
      consentManagerVersion: libraryInfo.version,
    }),
  );
}

export function trackConsentCaptured(customPreferences, trackingProperties) {
  onTrackingReady(() =>
    sendConsentCapturedEvents(customPreferences, trackingProperties),
  );
}

export function identify(customPreferences) {
  onTrackingReady(() => sendIdentify(customPreferences));
}

function onTrackingReady(callback) {
  if (window && window.tracking && window.tracking.track) {
    callback();
  } else {
    window.addEventListener("trackingReady", () => callback());
  }
}

function sendBannerViewedEvent() {
  const properties = {
    category: "Cookie Consent",
    label: "Banner Viewed",
    nonInteraction: 1,
    consentManagerVersion: libraryInfo.version,
  };
  window.tracking.track("Cookie Consent Banner Viewed", properties);
}

function sendManageSettingsEvent() {
  const properties = {
    category: "Cookie Consent",
    label: "Manage settings",
    consentManagerVersion: libraryInfo.version,
  };
  window.tracking.track("Cookie Consent Clicked", properties);
}

function sendConsentCapturedEvents(customPreferences, trackingProperties) {
  sendIdentify(customPreferences);
  sendConsentAcceptedTrackEvent(customPreferences, trackingProperties);
}

function sendIdentify(customPreferences) {
  window.tracking.identify({
    customTrackingPreferences: customPreferences,
  });
}

function sendConsentAcceptedTrackEvent(customPreferences, trackingProperties) {
  const { label, suppressReload, pageReloaded } = trackingProperties;
  const properties = {
    category: "Cookie Consent",
    label,
    eventDetail: getEventDetail(customPreferences),
    preferences: {
      functional: customPreferences.functional,
      advertising: customPreferences.advertising,
      sessionReplay: customPreferences.sessionReplay,
      dataSale: customPreferences.dataSale,
    },
    suppressReload,
    pageReloaded,
    consentManagerVersion: libraryInfo.version,
  };
  window.tracking.track("Cookie Consent Clicked", properties);
  window.tracking.track("Segment Consent Preference Updated");
}

function getEventDetail({ functional, advertising, sessionReplay }) {
  let eventDetail = "Necessary";
  if (functional) {
    eventDetail += ":Functional";
  }
  if (advertising) {
    eventDetail += ":Advertising";
  }
  if (sessionReplay) {
    eventDetail += ":SessionReplay";
  }
  return eventDetail;
}
