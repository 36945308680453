import siteConfig from "../siteConfig.json";
import {
  isLocalHost,
  isSandboxBackendDomain,
  isProdBackendDomain,
} from "./EnvironmentHelpers";

const LOCALHOST_DEFAULT_TENANT = "vistaprint-prod"; // this specifies the tenant when running locally
const SANDBOX_DEFAULT_TENANT = "vistaprint-prod";
const PRODUCTION_DEFAULT_TENANT = "vistaprint-prod";

const getCurrentTenant = () => {
  if (isLocalHost()) {
    return LOCALHOST_DEFAULT_TENANT;
  }
  if (isSandboxBackendDomain()) {
    // using vistaprint-dev as default for localhost for non prod environment
    // This is important as our hightest priority/risk is with the integrated vistaprint tenant
    return SANDBOX_DEFAULT_TENANT;
  }
  if (isProdBackendDomain()) {
    return PRODUCTION_DEFAULT_TENANT;
  }

  const hostname = window?.location?.hostname;
  const hostnameSegments = hostname.split(".");
  if (hostnameSegments.length < 2) {
    throw new Error(`Cannot parse hostname '${hostname}'.`);
  }

  let environment = "prod";
  const subDomain = hostnameSegments[0];
  if (subDomain === "next") {
    environment = "dev";
  }

  const domain = hostnameSegments[1];
  if (domain === "vistaprint") {
    return `${domain}-${environment}`;
  } else {
    throw new Error(`Tenant ${domain}-${environment} not supported.`);
  }
};

export const getTenantConfig = (tenantOverride?: string) => {
  return (siteConfig as any).tenant[tenantOverride ?? getCurrentTenant()];
};

export const getSwanBaseUrl = (
  SWAN_BASE_URL_MAP: any,
  SWAN_BASE_URL_LIST: string[],
) => {
  const hostname = window?.location?.hostname;
  const hostnameSegments = hostname.split(".");
  if (hostnameSegments.length < 3) {
    return SWAN_BASE_URL_MAP.default.cdn;
  }

  const domain = hostnameSegments[1];
  const country = hostnameSegments[2];
  let swanUrl = SWAN_BASE_URL_MAP[domain][country];

  // handle brandstore
  if (domain === "brandstore" && hostnameSegments.length > 3) {
    swanUrl = SWAN_BASE_URL_MAP[domain][country][hostnameSegments[3]];
  }

  if (SWAN_BASE_URL_LIST.includes(swanUrl)) {
    return swanUrl;
  }

  return SWAN_BASE_URL_MAP.default.cdn;
};
