import { createWorkEntityClient } from "../clients";
import { Work } from "../types/Work";

export const onEditDesignClick = async (
  work: Work,
  auth: any,
  isCareAgent: boolean,
  editSource: string | undefined,
) => {
  const workEntityClient = createWorkEntityClient(
    auth.getAuthorizationHeader(),
  );
  work.resources.approvedByCustomer = "false";
  await workEntityClient.saveWork(work, isCareAgent, work.ownerId);

  let location = work.design.editUrl;
  if (isCareAgent) {
    location += `&careAgent=true&owner=${work.ownerId}`;
  }
  if (editSource) {
    location += `&editSource=${editSource}`;
  }
  return location;
};
