import React, { useState, useLayoutEffect } from "react";
import {
  Typography,
  BoundedContent,
  SiteContent,
  SiteMain,
  Box,
  GridContainer,
  Row,
  Column,
  Img,
  H1,
} from "@vp/swan";
import { Header, BOOKEND_TYPES } from "@vp/esi-bookends";

import { getTenantConfig } from "../../utils/tenantHelpers";
import { DevWorks } from "./DevWorks";
import { isProdBackendDomain } from "../../utils/EnvironmentHelpers";
import { getCulture } from "../../utils/LocalizationHelpers";
import { useTracking } from "../root/contexts/TrackingContext";
import developer from "../../icons/developer.svg";
import { TrackingConfiguration } from "../../types/TrackingConfiguration";
import { SwanConfiguration } from "../../SwanConfiguration";
import { ESIBookendsMetaTag } from "../../ESIBookendsMetaTag";

export const DevPage: React.FC<{}> = () => {
  const tenantConfig = getTenantConfig();
  const { isTrackingReady, getTrackingConfiguration } = useTracking();
  const [devTracking, setDevTracking] = useState<TrackingConfiguration>(
    {} as TrackingConfiguration,
  );

  useLayoutEffect(() => {
    if (isTrackingReady) {
      const tracking = getTrackingConfiguration();
      tracking.pageName = "Teams Page Dev Tools";
      setDevTracking(tracking);
    }
  }, [isTrackingReady, getTrackingConfiguration]);

  if (isProdBackendDomain()) {
    return null;
  }

  return (
    <SwanConfiguration isDevPage={true}>
      <SiteContent>
        <ESIBookendsMetaTag headerType={BOOKEND_TYPES.FULL} />
        <Header
          locale={getCulture()}
          tenant={tenantConfig.bookends.tenant}
          type={BOOKEND_TYPES.FULL}
          hideSearch={false}
          hideVat={false}
          trackingConfiguration={devTracking}
          useMiniCart={true}
        />
        <SiteMain>
          <BoundedContent>
            <Box backgroundColor="accent" py={6}>
              <GridContainer>
                <Row>
                  <Column span={3} verticallyCenterContent>
                    <Typography textAlign="center">
                      <Img
                        src={developer}
                        alt="Developer Tools Icon"
                        style={{ width: "10em", height: "5em" }}
                      />
                    </Typography>
                  </Column>
                  <Column span={9} verticallyCenterContent>
                    <H1 textAlign="center">Team Details Dev Tools</H1>
                    <Typography textAlign="center">
                      This page should only ever appear when running Team
                      Details on localhost.
                    </Typography>
                  </Column>
                </Row>
              </GridContainer>
            </Box>
            <Box backgroundColor="strong" mb={7}>
              <GridContainer>
                <Row>
                  <Column span={10} offset={1}>
                    <DevWorks />
                  </Column>
                </Row>
              </GridContainer>
            </Box>
          </BoundedContent>
        </SiteMain>
      </SiteContent>
    </SwanConfiguration>
  );
};
