import BaseAxiosClient from "./BaseAxiosClient";

export interface SavedMemberData {
  id: string;
  documentUrl: string;
  previewUrls: string[];
}

interface SaveMembersResponse {
  success: boolean;
  messages: string[];
  data: SavedMemberData[];
}

export interface ICustomSportswearClient {
  saveMembers(
    designId: string,
    groupPlaceholderValues: { placeholderValues: Record<string, string> }[],
  ): Promise<SaveMembersResponse>;
}

class CustomSportswearClient
  extends BaseAxiosClient
  implements ICustomSportswearClient
{
  constructor(authHeader: string) {
    super(process.env.REACT_APP_CUSTOM_SPORTSWEAR_CLIENT as string, authHeader);
  }

  async saveMembers(
    designId: string,
    groupPlaceholderValues: { placeholderValues: Record<string, string> }[],
  ): Promise<SaveMembersResponse> {
    try {
      const savedMembers = await this.axios.post<SaveMembersResponse>(
        `/Order/SaveMembers`,
        {
          designId: designId,
          values: groupPlaceholderValues,
        },
      );

      if (!savedMembers.data.success) {
        throw new Error(
          `Generating documents for designId: ${designId} returned success = false. Messages: ${JSON.stringify(savedMembers.data.messages)}`,
        );
      }

      return savedMembers.data;
    } catch (err) {
      console.error(
        `Generating documents for group failed, designId: ${designId}`,
      );
      throw err;
    }
  }
}

const createCustomSportswearClient = (authHeader: string) => {
  return new CustomSportswearClient(authHeader);
};

export default createCustomSportswearClient;
