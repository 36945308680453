import React, { useEffect, useState } from "react";
import {
  CollapsibleContent,
  BasicCollapsible,
  GridContainer,
  Row,
  Column,
  Link,
  FlexBox,
  Dropdown,
  DropdownOption,
  Popover,
  PopoverLauncher,
  PopoverContent,
  Box,
  Typography,
  ResponsiveImageContainer,
  ResponsiveImage,
  H2,
} from "@vp/swan";
import { PreviewModes, PurcsPreview } from "@vp/react-product-preview";
import { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import { isNumber } from "lodash";
import { useIdentityContext } from "../root/contexts/IdentityContext";
import { createWorkEntityClient } from "../../clients";
import useTenantConfig from "../../hooks/useTenantConfig";
import {
  getCountry,
  getCulture,
  getPathFromCulture,
} from "../../utils/LocalizationHelpers";
import type { Work } from "../../types/Work";
import type { Quantity } from "../../types/Inventory";

type WorkInfo = {
  work: Work;
  name: string;
  tenant?: string;
  country?: string;
  culture?: string;
  mpvId?: string;
  quantity?: Quantity;
  isTeams: boolean;
};

type quantityFilterModes = "Any" | "No" | "Has";

export const DevWorks: React.FC<{}> = () => {
  const { auth } = useIdentityContext();
  const { tenantConfig } = useTenantConfig();
  const [works, setWorks] = useState<WorkInfo[]>([]);
  const [filterWorkInfos, setFilteredWorkInfos] = useState<WorkInfo[]>([]);

  const [filterByCountry, setFilterByCountry] = useState(true);
  const [filterByQuantity, setFilterByQuantity] =
    useState<quantityFilterModes>("Any");
  const [selectedWorks, setSelectedWorks] = useState<string[]>([]);
  const culture = getCulture();
  const country = getCountry();
  const pathFromCulture = getPathFromCulture(culture);

  const isValidQuantity = (quantity?: Quantity) => {
    if (!quantity) {
      return false;
    }
    const hardGoodSize: number = quantity as number;
    if (hardGoodSize && hardGoodSize > 0) {
      return true;
    }

    const sizes = quantity as DynamicSizedQuantities;
    if (sizes) {
      if (Object.keys(sizes).length === 0) {
        return false;
      }
      if (
        Object.values(sizes).reduce((total, current) => total + current, 0) <= 0
      ) {
        return false;
      }

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (auth) {
      const workEntityClient = createWorkEntityClient(
        auth.getAuthorizationHeader(),
      );
      workEntityClient.getWorks().then((works) => {
        setWorks(works.map((work) => convertToWorkInfo(work)));
      });
    }
  }, [auth]);

  useEffect(() => {
    const workFilter = (workInfo: WorkInfo) => {
      return (
        (!filterByCountry || workInfo.country === country) &&
        (filterByQuantity === "Any" ||
          (isValidQuantity(workInfo.quantity)
            ? filterByQuantity === "Has"
            : filterByQuantity === "No")) &&
        workInfo.isTeams
      );
    };

    setFilteredWorkInfos(works.filter(workFilter));
  }, [filterByCountry, filterByQuantity, works, country]);

  const convertToWorkInfo = (work: Work): WorkInfo => {
    const workInfo: WorkInfo = {
      work: work,
      name: work.workName,
      isTeams: false,
    };

    if (
      !!work?.design?.metadata?.groupsAndTeamsData ||
      !!work?.design?.metadata?.hasTeamsPlaceholders
    ) {
      workInfo.isTeams = true;
    }

    if (work?.resources?.qty) {
      try {
        const parsedQty = JSON.parse(work.resources.qty);
        if (isNumber(parsedQty)) {
          workInfo.quantity = parsedQty as number;
        } else {
          workInfo.quantity = parsedQty as DynamicSizedQuantities;
        }
      } catch {}
    }

    //https://merchandising-product-service.cdn.vpsvc.com/api/v3/mpv/vistaprint/en-US/fruitOfTheLoomLadiesHeavyCottonHdVNeckTShirtNa
    const re =
      /https:\/\/[^/]+\/api\/v\d\/mpv\/(?<tenant>[^/]+)\/(?<culture>[^/]+)\/(?<mpvId>[^/]+)$/i;
    try {
      const match = work.merchandising.mpvUrl.match(re);
      if (match && match.groups) {
        workInfo.tenant = match.groups["tenant"];
        workInfo.culture = match.groups["culture"];
        workInfo.country = match.groups["culture"].split("-")[1];
        workInfo.mpvId = match.groups["mpvId"];
      }
    } catch (err) {}

    return workInfo;
  };

  const WorkSelectionChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    workId: string,
  ) => {
    e.preventDefault();
    const isSelected = selectedWorks.filter((id) => id === workId).length > 0;
    if (isSelected) {
      setSelectedWorks(selectedWorks.filter((id) => id !== workId));
    } else {
      setSelectedWorks([...selectedWorks, workId]);
    }
  };

  const WorkHeading: React.FC<{ workInfo: WorkInfo }> = ({ workInfo }) => {
    return (
      <>
        <div>
          <input
            type="checkbox"
            checked={selectedWorks.some((id) => id === workInfo.work.workId)}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              WorkSelectionChanged(e, workInfo.work.workId)
            }
          />
          {workInfo.name}
        </div>
        {!!workInfo.tenant && (
          <div>
            {workInfo.tenant} - {workInfo.culture} - {workInfo.mpvId} -{" "}
            {JSON.stringify(workInfo.quantity)}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <FlexBox justifyContent="space-between" paddingTop={6}>
        <H2> Your Works </H2>
        <FlexBox flexDirection="column">
          <label>
            <input
              type="checkbox"
              checked={filterByCountry}
              onChange={() => {
                setFilterByCountry(!filterByCountry);
              }}
            />{" "}
            Filter By Current Country
          </label>
          <label>
            <Dropdown
              value={filterByQuantity}
              onChange={(event: any) => setFilterByQuantity(event.target.value)}
            >
              <DropdownOption value="Any">Any</DropdownOption>
              <DropdownOption value="No">No</DropdownOption>
              <DropdownOption value="Has">Has</DropdownOption>
            </Dropdown>
            Quantity
            <Popover display="inline">
              <PopoverLauncher>
                <button
                  style={{
                    borderRadius: ".75em",
                    padding: "0",
                    width: "1.5em",
                    height: "1.5em",
                    backgroundColor: "darkblue",
                    color: "white",
                  }}
                >
                  ?
                </button>
              </PopoverLauncher>
              <PopoverContent>
                "Has" means we'd skip quantity selection
              </PopoverContent>
            </Popover>
          </label>
        </FlexBox>
      </FlexBox>
      {selectedWorks.length > 1 ? (
        <Box>
          <Link
            skin="cta"
            target="_blank"
            rel="noopener noreferrer"
            href={`${pathFromCulture}/configure/quantity?${selectedWorks.map((id) => `workId=${id}`).join("&")}`}
          >
            Open selected {selectedWorks.length} works in quantity mode...
          </Link>
        </Box>
      ) : (
        <Typography textColor="subtle">
          Check multiple work entities to open multiple works in quantity view.
        </Typography>
      )}
      {!filterWorkInfos ? (
        <p>No Work Here. You can try logging in above.</p>
      ) : (
        filterWorkInfos.map((workInfo: WorkInfo) => (
          <BasicCollapsible
            key={workInfo.work.workId}
            heading={<WorkHeading workInfo={workInfo} />}
          >
            <CollapsibleContent>
              <GridContainer>
                <Row>
                  <Column span={3}>
                    {workInfo.work.design.metadata?.ysdDesignData ? (
                      <ResponsiveImageContainer aspectRatio={1}>
                        <ResponsiveImage
                          src={workInfo.work.design.metadata.previewUrl}
                        />
                      </ResponsiveImageContainer>
                    ) : (
                      <PurcsPreview
                        tenantId={tenantConfig.purcs.tenant}
                        requestor={tenantConfig.requestor}
                        coreProductKey={workInfo.work.product.key}
                        productVersion={workInfo.work.product.version}
                        optionSelections={
                          workInfo.work.merchandising.merchandisingSelections
                        }
                        width={250}
                        previewInstructionsUri={workInfo.work.design.displayUrl}
                        previewMode={PreviewModes.Carousel}
                        locale={getCulture()}
                        mpvHost={`${process.env.REACT_APP_MPV_SERVICE_ENDPOINT}/${tenantConfig.mpv.tenant}`}
                      />
                    )}

                    <Link
                      skin="cta"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${pathFromCulture}/configure/team?workId=${workInfo.work.workId}`}
                    >
                      Team Details
                    </Link>
                  </Column>
                  <Column span={9}>
                    <pre>{JSON.stringify(workInfo.work, null, " ")}</pre>
                  </Column>
                </Row>
              </GridContainer>
            </CollapsibleContent>
          </BasicCollapsible>
        ))
      )}
    </>
  );
};
