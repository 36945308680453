import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import {
  getRootClassNames,
  ScreenClassProvider,
  SwanHead,
  SwanProvider,
  SWAN_BASE_URL_LIST,
  SWAN_BASE_URL_MAP,
  SWAN_STYLE_KEY_MAP,
  useBrowserClasses,
} from "@vp/swan";
import { latestPricingContextModuleUrl } from "@vp/pricing-context-module/cdn";
import { getSwanBaseUrl } from "./utils/tenantHelpers";
import { getCulture } from "./utils/LocalizationHelpers";

const styleSheetKeys = [
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.modalDialog,
  SWAN_STYLE_KEY_MAP.hidden,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.toggleSwitch,
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.visible,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.progressiveImage,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.alertBox,
  SWAN_STYLE_KEY_MAP.carousel,
  SWAN_STYLE_KEY_MAP.table,
  SWAN_STYLE_KEY_MAP.spinner,
  SWAN_STYLE_KEY_MAP.popover,
];

export type SwanConfigurationProps = {
  children: ReactNode;
  isDevPage?: boolean;
};

export const SwanConfiguration: React.FC<SwanConfigurationProps> = ({
  isDevPage = false,
  children,
}) => {
  const browser = useBrowserClasses();
  const rootClassName = getRootClassNames();
  const culture = getCulture();
  const swanUrl = getSwanBaseUrl(SWAN_BASE_URL_MAP, SWAN_BASE_URL_LIST);

  return (
    <SwanProvider swanBaseUrl={swanUrl}>
      {isDevPage ? (
        <Helmet
          htmlAttributes={{ class: browser }}
          bodyAttributes={{ class: rootClassName }}
        />
      ) : (
        <Helmet
          htmlAttributes={{ class: browser }}
          bodyAttributes={{ class: rootClassName }}
        >
          <html lang={culture} />
          <title>{"Vistaprint"}</title>
          <script defer src={latestPricingContextModuleUrl}></script>
        </Helmet>
      )}

      <SwanHead
        renderStyleContentAsChildren
        renderWith={Helmet}
        styleSheetKeys={styleSheetKeys}
      />
      <ScreenClassProvider>{children}</ScreenClassProvider>
    </SwanProvider>
  );
};
