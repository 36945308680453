import React from "react";
import PropTypes from "prop-types";

import { Button, Typography, GridContainer, Row, Column, Link } from "@vp/swan";

import l10n from "../localization/localization";
import styles from "../styles/cookie-message-style.scss";
import getUrl from "./get-url";
import MultilineText from "./multiline-text";

export default function BannerVariationV2(props) {
  const { onAcceptAll, onAcceptNecessary, onChangePreferences } = props;
  return (
    <GridContainer
      className={`${styles.cookiePolicyControl} grid-container-proportional consent-manager-banner`}
      backgroundColor="standard"
    >
      <Row marginTop="4" marginBottom="6">
        <Column span={6} spanXs={6}>
          <Typography
            textAlign="left"
            fontSize="large"
            width="183px"
            fontWeight="bold"
          >
            {getBannerContentTitlePart(0)}
            <br />
            {getBannerContentTitlePart(1)}
          </Typography>
        </Column>
        <Column
          span={6}
          spanXs={6}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          <Link
            textAlign="right"
            title={l10n.t("bannerV5.continueWithoutAccepting.text")}
            aria-label={l10n.t("bannerV5.continueWithoutAccepting.text")}
            render={(p) => (
              <Button
                skin="link"
                className={p.className}
                onClick={onAcceptNecessary}
              >
                {p.children}
              </Button>
            )}
          >
            <MultilineText
              mobileText={l10n.t(
                "bannerV5.continueWithoutAccepting.mobileText",
                {
                  returnObjects: true,
                },
              )}
              text={l10n.t("bannerV5.continueWithoutAccepting.desktopText", {
                returnObjects: true,
              })}
            />
          </Link>
        </Column>
      </Row>
      <Row marginY="3">
        <Column span={12}>
          <Typography textAlign="left">
            {getBannerContentPart(0)}
            <Link href={getPolicyLink()}>
              {l10n.t("bannerV5.policyLinkText")}
            </Link>
            {getBannerContentPart(1)}
          </Typography>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <Button
            width="full-width"
            skin="secondary"
            className={`${styles.textButton} ${styles.wrappedText}`}
            title={l10n.t("bannerV5.settings")}
            aria-label={l10n.t("bannerV5.settings")}
            onClick={onChangePreferences}
          >
            {l10n.t("bannerV5.settings")}
          </Button>
        </Column>
        <Column span={6}>
          <Button
            width="full-width"
            skin="primary"
            className={`${styles.acceptButton} ${styles.wrappedText}`}
            title={l10n.t("bannerV5.accept")}
            aria-label={l10n.t("bannerV5.accept")}
            onClick={onAcceptAll}
          >
            {l10n.t("bannerV5.accept")}
          </Button>
        </Column>
      </Row>
    </GridContainer>
  );
}

function getPolicyLink() {
  return getUrl(l10n.tUrl("privacyAndCookiePolicy"));
}

function getBannerContentPart(part) {
  const policyText = l10n.t("bannerV5.content").split("#policy-link#");
  if (policyText.length > part) {
    return policyText[part];
  }

  return "";
}

function getBannerContentTitlePart(part) {
  const policyText = l10n.t("bannerV5.title").split("#break#");
  if (policyText.length > part) {
    return policyText[part];
  }

  return "";
}

BannerVariationV2.displayName = "Banner";

BannerVariationV2.propTypes = {
  onAcceptAll: PropTypes.func.isRequired,
  onAcceptNecessary: PropTypes.func.isRequired,
  onChangePreferences: PropTypes.func.isRequired,
};
