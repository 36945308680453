import qs from "qs";

const CARE_AGENT_QUERY_NAME = "careAgent";
const WORK_ID_QUERY_NAME = "workId";
const EDIT_SOURCE_QUERY_NAME = "editSource";
const DEBUG_QUERY_NAME = "debug";

export type ParsedParameters = {
  workEntityId: string;
  isCareAgent: boolean;
  editSource?: string;
  debug: boolean;
};

export const parseQueryParameters = (query: string): ParsedParameters => {
  const parsedParameters: ParsedParameters = {
    workEntityId: "",
    isCareAgent: false,
    debug: false,
  };

  const parsedQuery = qs.parse(query);

  // parse the work id
  parsedParameters.workEntityId = parsedQuery[WORK_ID_QUERY_NAME] as string;

  // parse care agent parameter is true when: empty/missing, is "true" or "1"
  parsedParameters.isCareAgent = /(^$)|(^true$)|(^1$)/i.test(
    parsedQuery[CARE_AGENT_QUERY_NAME] as string,
  );

  parsedParameters.debug = /(^$)|(^true$)|(^1$)/i.test(
    parsedQuery[DEBUG_QUERY_NAME] as string,
  );

  // edit source should be populated if it exists
  if (parsedQuery[EDIT_SOURCE_QUERY_NAME] as string) {
    parsedParameters.editSource = parsedQuery[EDIT_SOURCE_QUERY_NAME] as string;
  }

  return parsedParameters;
};
