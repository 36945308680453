import React, { ReactNode, useEffect, useState } from "react";
import qs from "qs";
// @ts-ignore
import VistaprintAuth from "@vp/auth";
import {
  isLocalHost,
  isSandboxBackendDomain,
} from "../../../../utils/EnvironmentHelpers";
import { getCulture } from "../../../../utils/LocalizationHelpers";

interface IdentityContextInterface {
  auth: any;
  identity?: any;
  ownerId: string;
  isIdentityInitialized: boolean;
}

export const IdentityContext = React.createContext<IdentityContextInterface>({
  auth: {},
  isIdentityInitialized: false,
  ownerId: "",
});

type IdentityProviderProps = {
  tenant: any;
  children: ReactNode;
};

const DEFAULT_LOCALE = "en-IE";

const isCareAgent = (urlParams: any): boolean => {
  // If query params say false remove local storage value to have same state
  if (urlParams.careAgent === "false") {
    window.localStorage.removeItem("vp-care-agent");
  }

  return (
    urlParams.careAgent === "true" ||
    window.localStorage.getItem("vp-care-agent") === "true"
  );
};

const getOptions = (tenantConfig: any, urlParams: any): Record<string, any> => {
  const connection = isCareAgent(urlParams)
    ? "CimpressADFS"
    : tenantConfig.auth.connection;
  return {
    requireSession: true,
    ...(connection && { connection: connection }),
  };
};

function getDevelopmentMode(
  tenantConfig: any,
  urlParams: any,
): Record<string, any> | boolean {
  if (isCareAgent(urlParams)) {
    return {
      clientID: "tCZE1T00Yk6Uqs4jLO0HtpwLopz8BN7F",
      redirectUriOrigin: !isLocalHost()
        ? `${window.location.origin}/auth-callback/`
        : null,
    };
  }

  if (isLocalHost()) {
    return {
      // "local testing" auth0 client
      clientID:
        tenantConfig.auth.clientID || "mIhPqu9cP5zlrtfyY3ugtm9w2jKKKGae",
      redirectUriOrigin: `${window.location.origin}/auth-callback/`,
    };
  }

  if (isSandboxBackendDomain() && tenantConfig.auth.clientID) {
    return {
      clientID: tenantConfig.auth.clientID,
      redirectUriOrigin: window.location.href,
    };
  }

  return false;
}

const IdentityContextProvider: React.FC<IdentityProviderProps> = ({
  children,
  tenant,
}) => {
  const [auth, setAuth] = useState<any>(undefined);
  const [isIdentityInitialized, setIsIdentityInitialized] = useState(false);
  const [identity, setIdentity] = useState(undefined);

  const urlParams = qs.parse(window.location.search.substring(1));
  const ownerId = urlParams.owner as string;

  useEffect(() => {
    const authConfig = {
      culture: getCulture() || DEFAULT_LOCALE,
      options: getOptions(tenant, urlParams),
      developmentMode: getDevelopmentMode(tenant, urlParams),
    };

    VistaprintAuth.init(authConfig, () => {
      const auth = new VistaprintAuth.WebAuth();
      setAuth(auth);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!auth) return;

    auth.onUserIdentityUpdate((newIdentity: any) => {
      if (!newIdentity) return;

      // Force CARE agents to log in through Cimpress ADFS
      if (newIdentity && !newIdentity.isSignedIn && isCareAgent(urlParams)) {
        return auth && auth.signIn();
      }

      setIsIdentityInitialized(true);
      setIdentity(newIdentity);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <IdentityContext.Provider
      value={{ isIdentityInitialized, auth, identity, ownerId }}
    >
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityContextProvider;
